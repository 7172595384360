import Axios from 'axios';


var deviceStatusApiPath = '/device_status';
var latestSensorDateApiPath = '/latest_sensor_date';

export async function getDeviceStatus(setDeviceStatusCards, productName, setProduceLineNode, setProduceLineEdge) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    let deviceStatusApiPathWithProductName = process.env.REACT_APP_API_ADDRESS + deviceStatusApiPath;
    deviceStatusApiPathWithProductName += '/' + productName;

    await Axios.get(deviceStatusApiPathWithProductName, requestConfig).then((response) => {
        let produceLineNode = [];
        let produceLineEdge = [];
        let devices = [];
        let deviceIndex = 0;
        let responseData = response.data;
        for (let index=0; index<responseData.length; index++) {
            if (devices.includes(responseData[index]['device_name'])) {
                responseData[index]['node_id'] = deviceIndex - 1;
                responseData[index]['node_value'] = false;

                continue;
            }

            produceLineNode.push({
                id: deviceIndex,
                text: responseData[index]['device_name'],
            });

            if (deviceIndex > 0) {
                produceLineEdge.push({
                    id: ((deviceIndex-1) + '' + deviceIndex),
                    from: (deviceIndex-1),
                    to: deviceIndex,
                });
            }

            responseData[index]['node_id'] = deviceIndex;
            responseData[index]['node_value'] = false;
            devices.push(responseData[index]['device_name']);

            deviceIndex += 1;
        }
        console.log(responseData);

        setProduceLineNode(produceLineNode);
        setProduceLineEdge(produceLineEdge);
        setDeviceStatusCards(responseData);
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function getLatestSensorDate(setLatestSensorDate) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    let latestSensorApiUrl = process.env.REACT_APP_API_ADDRESS + latestSensorDateApiPath;
    latestSensorApiUrl += '/' + localStorage.getItem('auth_account');

    await Axios.get(latestSensorApiUrl, requestConfig).then((response) => {
        let responseData = {};
        if (response.data.gravity !== undefined) {
            responseData['gravity'] = response.data.gravity;
        }
        if (response.data.machining !== undefined) {
            responseData['machining'] = response.data.machining;
        }

        setLatestSensorDate(responseData);
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
}

const DeviceStatusMonitor = {
    getDeviceStatus,
    getLatestSensorDate,
};

export default DeviceStatusMonitor;
