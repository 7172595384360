import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  Row,
  Col,
  Progress,
} from "reactstrap";
import {
  Redirect,
} from 'react-router';
import { Canvas, Node, Edge, MarkerArrow } from 'reaflow';
import ReactCardFlip from 'react-card-flip';
import { getProduct } from 'ajax/Product';
import { verifiedLoginWithSetState } from 'ajax/LoginRequest';
import { getDeviceStatus, getLatestSensorDate } from 'ajax/DeviceStatusMonitor';
import { fromUnixTime,formatISO9075 } from "date-fns";


function Dashboard() {
  const [deviceStatusCards, setDeviceStatusCards] = useState([]);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [spinnerVisible, setSpinnerVisible] = useState('visible');
  const [productName, setProductName] = useState('');
  const [productTables, setProductTables] = useState([]);
  const [disableProductTables, setDisableProductTables] = useState(true);
  const [statusNumber, setStatusNumber] = useState(0);
  const deviceStatusRef = useRef([]);
  const [produceLineNode, setProduceLineNode] = useState([]);
  const [produceLineEdge, setProduceLineEdge] = useState([]);
  const [latestSensorDate, setLatestSensorDate] = useState({});
  const [updateBlink, setUpdateBlink] = useState('none');
  const [updateNoBlink, setUpdateNoBlink] = useState('');


  const handleProductListsChange = async (selectedProductName) => {
    setDeviceStatusCards([]);
    setProductName(selectedProductName);

    setSpinnerVisible('visible');
    await getDeviceStatus(setDeviceStatusCards, selectedProductName, setProduceLineNode, setProduceLineEdge);
    setSpinnerVisible('invisible');

    setStatusNumber(statusNumber + 1);
  };

  const handleCurrentNodeClick = (event, node) => {
    deviceStatusRef.current[Number(node.id)].scrollIntoView();
    let theIndex = Number(node.id);
    let theDeviceStatusCards = deviceStatusCards;
    for (let index=0; index<theDeviceStatusCards.length; index++) {
      if (Number(theDeviceStatusCards[index]['node_id']) === theIndex) {
        theDeviceStatusCards[index]['node_value'] = !theDeviceStatusCards[index]['node_value'];
      }
    }

    setDeviceStatusCards(theDeviceStatusCards);
    setStatusNumber(statusNumber + 1);
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      if (localStorage.getItem('auth_account') !== 'default') {
        return false;
      }
      await getLatestSensorDate(setLatestSensorDate);
      setUpdateBlink('');
      setUpdateNoBlink('none');

      setTimeout(() => {
        setUpdateBlink('none');
        setUpdateNoBlink('');
      }, 5000);
    }, 60000);

    if (statusNumber > 0) {
    } else {
      async function verifiedLogin() {
        await verifiedLoginWithSetState(setRedirectToLogin);
      }
      verifiedLogin();

      async function genProductTable() {
        let productTables = await getProduct(setProductTables);
        if (productTables.length > 0) {
          await getDeviceStatus(setDeviceStatusCards, productTables[0]['product_name'], setProduceLineNode, setProduceLineEdge);
          await getLatestSensorDate(setLatestSensorDate);
        }

        setSpinnerVisible('invisible');
      }

      genProductTable();

      setDisableProductTables(false);

      return () => clearInterval(interval);
    }
  }, [statusNumber]);

  if (redirectToLogin) {
    return <Redirect to='/admin/login'/>
  }

  return (
      <>
      <div className="content">
        <Row>
          {
            deviceStatusCards.length <= 0 ? <div className={ "spinner-border spinner-color " + spinnerVisible } role="status">
                    <span className="sr-only">Loading...</span>
                  </div> : ''
          }
          {
            (productTables.length <= 0 && spinnerVisible === 'invisible') ? <h4 className="text-danger">{ '查無產品，請先到『產品製程設定』進行設定！' }</h4> : <></>
          }
          {
              <Col style={{ display: ((spinnerVisible === 'visible' || productTables.length <= 0) ? 'none': '')}} md="12">
                <Card>
                  <CardHeader>
                    <Input disabled={ disableProductTables } className="w-100" name="product-lists" onChange={event => handleProductListsChange(event.target.value)} type="select" value={ productName } placeholder="請選擇產品">
                      {
                        productTables.map((item, itemIndex) => {
                          if (productName === '' && itemIndex === 0) {
                            setProductName(item['product_name']);
                          }

                          return <option key={'product-name-' + itemIndex}>{ item['product_name'] }</option>
                        })
                      }
                    </Input>
                    <CardTitle tag="h4"></CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Canvas
                        zoom={1.0}
                        style={{ fontSize: '20px', }}
                        nodes={ produceLineNode }
                        edges={ produceLineEdge }
                        direction="RIGHT"
                        maxHeight={200}
                        node={
                          <Node
                            className="node"
                            onClick={ (event, node) => handleCurrentNodeClick(event, node) }
                          />
                        }
                        arrow={ <MarkerArrow className="arrow" /> }
                        edge={ <Edge className="edge" /> }
                      />
                    </CardBody>
                </Card>
              </Col>
          }
          {
            deviceStatusCards.map((item, index) => {
              return <Col style={{ display: (spinnerVisible === 'visible' ? 'none' : '') }} key={ index } md="9" lg="6">
                <div ref={ (element) => (deviceStatusRef.current[index] = element) }>
                <ReactCardFlip flipSpeedBackToFront={1.0} flipSpeedFrontToBack={1.0} isFlipped={ item['node_value'] } flipDirection="horizontal">
              <Card className="card-stats">
                <CardBody style={{
                  padding: '20px 30px 0px',
                }}>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-success">
                        <i className="nc-icon nc-vector text-success" />
                      </div>
                      <div className="numbers text-center">
                        <span className={ "fas fa-lightbulb " + (item['alive'] ? 'text-success' : 'text-secondary') }></span>
                        <span className="fas fa-lightbulb text-secondary"></span>
                        <span className={ "fas fa-lightbulb " + (!item['alive'] ? 'text-danger' : 'text-secondary') }></span>
                      </div>
                    </Col>
                    <Col className="mb-1">
                      <div className="numbers text-left">
                        <p>
                          <span className="small">{ item['device_name'] }</span>
                          <span className="card-category"> { ' ' + item['device_number'] }</span>
                        </p>
                        <p style={{
                            fontSize: '20px',
                            color: '#364b5d',
                        }}>資料總數</p>
                        <p style={{
                            fontSize: '1.0em',
                            color: '#4a8cf1',
                          }}>{ new Intl.NumberFormat('zh-TW').format(item['received_data_counts']) }
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className="text-left" style={{
                        fontSize: '14px',
                        color: '#42c85d',
                      }}>
                        { new Intl.NumberFormat('zh-TW').format(item['alive_rate']) }%<span style={{
                          color: '#364b5d',
                        }}> 存活率</span>
                      </div>
                      <Progress value={ Number(item['alive_rate']) } />
                    </Col>
                    <Col md={12}>
                      <div className="stats" style={{
                        fontSize: '14px',
                        fontWeight: 500,
                        color: '#4a8cf1',
                      }}>
                        <span className="blink" style={{display: updateBlink}}>
                        {
                            ((latestSensorDate['gravity'] !== undefined && item['device_name'] === '重力鑄造' ) ? ('裝置最後擷取時間：' + formatISO9075(fromUnixTime(latestSensorDate['gravity']))) : null) ||
                            ((latestSensorDate['machining'] !== undefined && item['device_name'] === '機加工' ) ? ('裝置最後擷取時間：' + formatISO9075(fromUnixTime(latestSensorDate['machining']))) : null)
                        }
                        </span>
                        <span style={{display: updateNoBlink}}>
                        {
                            ((latestSensorDate['gravity'] !== undefined && item['device_name'] === '重力鑄造' ) ? ('裝置最後擷取時間：' + formatISO9075(fromUnixTime(latestSensorDate['gravity']))) : null) ||
                            ((latestSensorDate['machining'] !== undefined && item['device_name'] === '機加工' ) ? ('裝置最後擷取時間：' + formatISO9075(fromUnixTime(latestSensorDate['machining']))) : null)
                        }
                        </span>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats" style={{
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#86939e',
                  }}>
                    <i style={{
                      color: '#4a8cf1',
                    }} className="fas fa-check"/>開始啟動時間：{ item['start_date'].replace('T', ' ') }
                  </div>
                </CardFooter>
              </Card>
              <Card className="card-stats">
                <CardBody style={{
                  padding: '20px 30px 0px',
                }}>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-success">
                        <i className="nc-icon nc-vector text-success" />
                      </div>
                      <div className="numbers text-center">
                        <span className={ "fas fa-lightbulb " + (item['alive'] ? 'text-success' : 'text-secondary') }></span>
                        <span className="fas fa-lightbulb text-secondary"></span>
                        <span className={ "fas fa-lightbulb " + (!item['alive'] ? 'text-danger' : 'text-secondary') }></span>
                      </div>
                    </Col>
                    <Col className="mb-1">
                      <div className="numbers text-left">
                        <p>
                          <span className="small">{ item['device_name'] }</span>
                          <span className="card-category"> { ' ' + item['device_number'] }</span>
                        </p>
                        <p style={{
                            fontSize: '20px',
                            color: '#364b5d',
                        }}>資料總數</p>
                        <p style={{
                            fontSize: '1.0em',
                            color: '#4a8cf1',
                          }}>{ new Intl.NumberFormat('zh-TW').format(item['received_data_counts']) }
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className="text-left" style={{
                        fontSize: '14px',
                        color: '#42c85d',
                      }}>
                        { new Intl.NumberFormat('zh-TW').format(item['alive_rate']) }%<span style={{
                          color: '#364b5d',
                        }}> 存活率</span>
                      </div>
                      <Progress value={ Number(item['alive_rate']) } />
                    </Col>
                    <Col md={12}>
                      <div className="stats" style={{
                        fontSize: '14px',
                        fontWeight: 500,
                        color: '#4a8cf1',
                      }}>
                      <span className="blink" style={{display: updateBlink}}>
                        {
                            ((latestSensorDate['gravity'] !== undefined && item['device_name'] === '重力鑄造' ) ? ('裝置最後擷取時間：' + formatISO9075(fromUnixTime(latestSensorDate['gravity']))) : null) ||
                            ((latestSensorDate['machining'] !== undefined && item['device_name'] === '機加工' ) ? ('裝置最後擷取時間：' + formatISO9075(fromUnixTime(latestSensorDate['machining']))) : null)
                        }
                        </span>
                        <span style={{display: updateNoBlink}}>
                        {
                            ((latestSensorDate['gravity'] !== undefined && item['device_name'] === '重力鑄造' ) ? ('裝置最後擷取時間：' + formatISO9075(fromUnixTime(latestSensorDate['gravity']))) : null) ||
                            ((latestSensorDate['machining'] !== undefined && item['device_name'] === '機加工' ) ? ('裝置最後擷取時間：' + formatISO9075(fromUnixTime(latestSensorDate['machining']))) : null)
                        }
                      </span>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats" style={{
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#86939e',
                  }}>
                    <i style={{
                      color: '#4a8cf1',
                    }} className="fas fa-check"/>開始啟動時間：{ item['start_date'].replace('T', ' ') }
                  </div>
                </CardFooter>
              </Card>
              </ReactCardFlip>
              </div>
            </Col>
            })
          }
        </Row>
      </div>
      </>
  );
}

export default Dashboard;
