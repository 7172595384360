import React, { useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import SubMenu from "./SubMenu";

import logo from "iii_logo.svg";

var ps;

function Sidebar(props) {
  const sidebar = useRef();

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? ("active") : "";
  };
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });
  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="logo">
        <a
          href="/admin/dashboard"
          className="simple-text logo-mini"
        >
          <div className="logo-img">
            <img src={logo} alt="III logo" />
          </div>
        </a>
        <a
          href={ localStorage.getItem('auth_account') !== 'rithai' ? '/admin/product-carbon' : '/admin/produce-carbon'}
          className="simple-text logo-normal logo-title-text"
        >
          碳排管理工具
        </a>
      </div>
      <div className="sidebar-wrapper" ref={sidebar}>
      <Nav vertical className="list-unstyled pb-3">
        {
          localStorage.getItem('auth_account') !== 'rithai' ?
          <NavItem>
            <NavLink tag={Link} to={props.routes[12].layout + props.routes[12].path}>
              <i className={props.routes[12].icon} />
              { props.routes[12].name }
            </NavLink>
          </NavItem> : <></>
        }
        <SubMenu title="感測器裝置-碳排管理" activeroute={activeRoute} icon={props.routes[7].icon} items={[props.routes[13], props.routes[14]]} />
        {
          localStorage.getItem('auth_account') !== 'rithai' ?
          <NavItem>
            <NavLink tag={Link} to={props.routes[0].layout + props.routes[0].path}>
              <i className={props.routes[0].icon} />
              { props.routes[0].name }
            </NavLink>
          </NavItem> : <></>
        }
        <SubMenu title="產線-機台感測器裝置設定" activeroute={activeRoute} icon={props.routes[1].icon} items={[props.routes[3]]} />
        <SubMenu title="產線-產品設定" activeroute={activeRoute} icon={props.routes[2].icon} items={[props.routes[4], props.routes[5], props.routes[6]]} />
        {
          localStorage.getItem('auth_account') === 'default' ?
          <NavItem>
          <NavLink tag={Link} to={props.routes[11].layout + props.routes[11].path}>
            <i className={props.routes[11].icon} />
            { props.routes[11].name }
          </NavLink>
          </NavItem> : <></>
        }
        {
          localStorage.getItem('auth_account') !== 'default' ? <SubMenu title="產線-碳排計算" activeroute={activeRoute} icon={props.routes[8].icon} items={[props.routes[7], props.routes[8], props.routes[9]]} /> : <></>
        }
        {
          localStorage.getItem('auth_account') !== 'rithai' ?
          <NavItem>
            <NavLink tag={Link} to={props.routes[10].layout + props.routes[10].path}>
              <i className={props.routes[10].icon} />
              { props.routes[10].name }
            </NavLink>
          </NavItem> : <></>
        }
      </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
