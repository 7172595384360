import React, { useEffect, useState } from "react";
import {
  Redirect,
} from 'react-router';
import { Line } from 'react-chartjs-2';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Col,
  Row,
  Spinner,
  Input,
  Form,
  FormGroup,
  Button,
  Table,
} from "reactstrap";
import { DatePicker } from "reactstrap-date-picker";
import { getProduct, getProcessStationByProductName } from "ajax/Product";
import { getFutureProductCo2e } from "ajax/ProductCarbon";
import { verifiedLoginWithSetState } from 'ajax/LoginRequest';
import { parse, isValid, format, formatISO } from 'date-fns';
import differenceInBusinessDays from 'date-fns/differenceInBusinessDays';
import { zhTW } from 'date-fns/locale';


function ProduceTrending() {
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [productTables, setProductTables] = useState([]);
  const [productProduceLists, setProductProduceLists] = useState([]);
  const [disableProductTables, setDisableProductTables] = useState(false);
  const [productName, setProductName] = useState('');
  const [produceName, setProduceName] = useState('');
  const [loadingCalculatingSpinner, setLoadingCalculatingSpinner] = useState('none');
  const [predictedChartData, setPredictedChartData] = useState({});
  const [predictStartDateTime, setPredictStartDateTime] = useState({
    value: formatISO(new Date()),
    formattedValue: format(new Date(), 'yyyy/MM/dd'),
  });
  const [predictEndDateTime, setPredictEndDateTime] = useState({
    value: formatISO(new Date()),
    formattedValue: format(new Date(), 'yyyy/MM/dd'),
  });
  const [validatedMessage, setValidatedMessage] = useState('');
  const [predictedProduceNumberTable, setPredictedProduceNumberTable] = useState([]);
  const [originalPredictedStartDate, setOriginalPredictedStartDate] = useState('');
  const [originalPredictedEndDate, setOriginalPredictedEndDate] = useState('');


  const handleContentChange = (event) => {
    let thePredictedProduceNumberTable = predictedProduceNumberTable;
    let predictedIndex = event.target.dataset.itemindex;
    let modifiedProduceNumber = event.target.textContent;

    if (isNaN(Number(modifiedProduceNumber)) === false) {
      thePredictedProduceNumberTable[predictedIndex]['produce_numbers'] = Number(modifiedProduceNumber);
      setPredictedProduceNumberTable(thePredictedProduceNumberTable);
    } else {
      event.target.textContent = String(thePredictedProduceNumberTable[predictedIndex]['produce_numbers']);
    }
  };

  const handleProductListsChange = async (selectedValue) => {
    setProductName(selectedValue);

    setLoadingCalculatingSpinner('inline-block');
    setDisableProductTables(true);

    await getProcessStationByProductName(selectedValue, setProductProduceLists);

    setLoadingCalculatingSpinner('none');
    setDisableProductTables(false);
  };

  const handlePredictStartDateTimeChange = (value, formattedValue) => {
    setPredictStartDateTime({
      value: value,
      formattedValue: formattedValue,
    });
  };

  const handlePredictEndDateTimeChange = (value, formattedValue) => {
    setPredictEndDateTime({
      value: value,
      formattedValue: formattedValue,
    });
  };

  const calculatedDiffDays = (startDates, endDates) => {
    let diffDays = differenceInBusinessDays(
      new Date(Number(startDates[0]), Number(startDates[1])-1, Number(startDates[2])),
      new Date(Number(endDates[0]), Number(endDates[1])-1, Number(endDates[2])),
    );

    return diffDays;
  };

  const isValidDate = (year, month, day) => {
    const parsed = parse(`${year}/${month}/${day}`, 'yyyy/MM/dd', new Date(), { locale: zhTW });

    return isValid(parsed);
  }

  const doPredictFuturePredict = async () => {
    setValidatedMessage('');

    if (predictStartDateTime.formattedValue === null) {
      setValidatedMessage('未來起始日期格式錯誤！');
      setPredictStartDateTime({
        value: formatISO(new Date()),
        formattedValue: format(new Date(), 'yyyy/MM/dd')
      });

      return false;
    }

    if (predictEndDateTime.formattedValue === null) {
      setValidatedMessage('未來結束日期格式錯誤！');
      setPredictEndDateTime({
        value: formatISO(new Date()),
        formattedValue: format(new Date(), 'yyyy/MM/dd')
      });

      return false;
    }

    let startDateArray = predictStartDateTime.formattedValue.split('/');
    if (startDateArray.length !== 3) {
      setValidatedMessage('未來起始日期格式錯誤！');
      setPredictStartDateTime({
        value: formatISO(new Date()),
        formattedValue: format(new Date(), 'yyyy/MM/dd')
      });

      return false;
    }

    let endDateArray = predictEndDateTime.formattedValue.split('/');
    if (endDateArray.length !== 3) {
      setValidatedMessage('未來結束日期格式錯誤！');
      setPredictEndDateTime({
        value: formatISO(new Date()),
        formattedValue: format(new Date(), 'yyyy/MM/dd')
      });

      return false;
    }

    let validStartDate = isValidDate(startDateArray[0], startDateArray[1], startDateArray[2]);

    if (validStartDate === false) {
      setValidatedMessage('未來起始日期格式錯誤！');
      setPredictStartDateTime({
        value: formatISO(new Date()),
        formattedValue: format(new Date(), 'yyyy/MM/dd'),
      });

      return false;
    }

    let validEndDate = isValidDate(endDateArray[0], endDateArray[1], endDateArray[2]);

    if (validEndDate === false) {
      setValidatedMessage('未來結束日期格式錯誤！');
      setPredictEndDateTime({
        value: formatISO(new Date()),
        formattedValue: format(new Date(), 'yyyy/MM/dd'),
      });

      return false;
    }

    let startDates = [Number(startDateArray[0]), Number(startDateArray[1]), Number(startDateArray[2])];
    let endDates = [Number(endDateArray[0]), Number(endDateArray[1]), Number(endDateArray[2])];

    let nowDate = format(new Date(), 'yyyy/MM/dd').split('/');
    let nowDates = [nowDate[0], nowDate[1], nowDate[2]];
    let diffDays = calculatedDiffDays(startDates, nowDates);
    if (diffDays < 0) {
      setValidatedMessage('未來起始日期需要是今天以後！');
      setPredictStartDateTime({
        value: formatISO(new Date()),
        formattedValue: format(new Date(), 'yyyy/MM/dd'),
      });

      return false;
    }

    diffDays = calculatedDiffDays(endDates, nowDates);
    if (diffDays < 0) {
      setValidatedMessage('未來結束日期需要是今天以後！');
      setPredictEndDateTime({
        value: formatISO(new Date()),
        formattedValue: format(new Date(), 'yyyy/MM/dd'),
      });

      return false;
    }

    diffDays = calculatedDiffDays(endDates, startDates);
    if (diffDays <= 0) {
      setValidatedMessage('未來起始與結束日期範圍設定錯誤！');

      return false;
    }

    setLoadingCalculatingSpinner('inline-block');
    setDisableProductTables(true);

    let predictedStartDate = predictStartDateTime.formattedValue.replace(/\//g, '-');
    let predictedEndDate = predictEndDateTime.formattedValue.replace(/\//g, '-');

    let requestPayload = {
      predicted_start_date: predictedStartDate,
      predicted_end_date: predictedEndDate,
    };

    if (predictedProduceNumberTable.length > 0 &&
        originalPredictedStartDate === predictedStartDate &&
        originalPredictedEndDate === predictedEndDate) {
      requestPayload['produce_numbers'] = [];
      for (let index=0; index<predictedProduceNumberTable.length; index++) {
        requestPayload['produce_numbers'].push(predictedProduceNumberTable[index]['produce_numbers']);
      }

      await getFutureProductCo2e(requestPayload, setPredictedChartData, setValidatedMessage, null);
    } else {
      await getFutureProductCo2e(requestPayload, setPredictedChartData, setValidatedMessage, setPredictedProduceNumberTable);
      setOriginalPredictedStartDate(predictedStartDate);
      setOriginalPredictedEndDate(predictedEndDate);
    }

    setLoadingCalculatingSpinner('none');
    setDisableProductTables(false);
  };

  const handleProduceProductListsChange = async (selectedValue) => {
    setProduceName(selectedValue);

    setLoadingCalculatingSpinner('inline-block');
    setDisableProductTables(true);

    setPredictedChartData({});

    setLoadingCalculatingSpinner('none');
    setDisableProductTables(false);
  }

  useEffect(() => {
    async function verifiedLogin() {
      await verifiedLoginWithSetState(setRedirectToLogin);
    }

    verifiedLogin();

    async function genProductLists() {
      setLoadingCalculatingSpinner('inline-block');
      setDisableProductTables(true);

      let productLists = await getProduct(setProductTables);
      if (productLists.length > 0) {
        let theProductName = productLists[0]['product_name'];

        await getProcessStationByProductName(theProductName, setProductProduceLists);
      }

      setLoadingCalculatingSpinner('none');
      setDisableProductTables(false);
    }

    genProductLists();
  }, []);

  if (redirectToLogin) {
    return <Redirect to='/admin/login'/>
  }

  return (
    <>
      <div className="content">
      <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                { productTables.length <= 0 ? <h4 className="text-danger">{ '查無產品，請先到『產品製程設定』進行設定！' }</h4> : <></> }
                <p>
                  <Input style={{ display: (productTables.length > 0 ? '' : 'none') }} disabled={ disableProductTables } className="w-100" name="product-lists" onChange={event => handleProductListsChange(event.target.value)} type="select" value={ productName } placeholder="請選擇產品">
                    {
                      productTables.map((item, itemIndex) => {
                        if (productName === '' && itemIndex === 0) {
                          setProductName(item['product_name']);
                        }

                        return <option key={'product-name-' + itemIndex}>{ item['product_name'] }</option>
                      })
                    }
                </Input>
                </p>
                <p>
                  <Input style={{ display: (productProduceLists.length > 0 ? '' : 'none') }} disabled={ disableProductTables } className="w-100" name="product-lists" onChange={event => handleProduceProductListsChange(event.target.value)} type="select" value={ produceName } placeholder="請選擇該產品製程">
                    {
                      productProduceLists.map((item, itemIndex) => {
                        if (produceName === '' && itemIndex === 0) {
                          setProduceName(item['process_type']);
                        }

                        return <option key={'process-type-' + itemIndex}>{ item['process_type'] }</option>
                      })
                    }
                  </Input>
                </p>
                <Form>
                  <FormGroup style={{ display: (produceName === '機加工' ? '' : 'none')}}>
                    <DatePicker
                      minDate={ formatISO(new Date()) }
                      disabled={ disableProductTables }
                      name="predict-start-date"
                      onChange={(value, formattedValue) => handlePredictStartDateTimeChange(value, formattedValue)} dateFormat="YYYY/MM/DD" value={predictStartDateTime.value || ''}
                      placeholder="請選擇未來起始日期"
                    />
                  </FormGroup>
                  <FormGroup style={{ display: (produceName === '機加工' ? '' : 'none')}}>
                    <DatePicker
                      minDate={ formatISO(new Date()) }
                      disabled={ disableProductTables }
                      name="predict-end-date"
                      onChange={(value, formattedValue) => handlePredictEndDateTimeChange(value, formattedValue)} dateFormat="YYYY/MM/DD"
                      value={predictEndDateTime.value || ''}
                      placeholder="請選擇未來結束日期"
                    />
                  </FormGroup>
                  <FormGroup style={{ display: (produceName === '機加工' ? '' : 'none')}}>
                    <Button onClick={ doPredictFuturePredict } className="btn-icon-setting" disabled={ disableProductTables }>開始預測</Button>
                  </FormGroup>
                  <h4 className="text-danger">{ validatedMessage }</h4>
                </Form>
                <CardTitle tag="h4">
                  <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem', display: loadingCalculatingSpinner}} children={'false'} />
                </CardTitle>
              </CardHeader>
              <CardBody>
              <FormGroup>
                <div style={{display: ((Object.keys(predictedChartData).length > 0 && predictedChartData.datasets.length > 0) ? '' : 'none')}}>
                <Table>
                  { predictedProduceNumberTable.length > 0 ? <>
                        <thead className="text-info">
                          <tr>
                            <th className="th-customize">預計生產日期</th>
                            {
                              predictedProduceNumberTable.map((item, index) => {
                                return <React.Fragment key={ "produce-date" + index}>
                                          <th className="th-customize">{ item['predicted_date'] }</th>
                                        </React.Fragment>
                              })
                            }
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="td-customize">預計生產數量</td>
                            {
                              predictedProduceNumberTable.map((item, index) => {
                                return <td data-itemindex={ index } onInput={ (event) => { handleContentChange(event) } } contentEditable={ true } suppressContentEditableWarning={ true } className="td-customize" key={ 'produce-number' + index }>{ item['produce_numbers'] }</td>
                              })
                            }
                          </tr>
                        </tbody>
                    </> : <></>
                  }
                </Table>
                </div>
              </FormGroup>
              <div style={{display: ((Object.keys(predictedChartData).length > 0 && predictedChartData.datasets.length > 0) ? '' : 'none'), height: '40vh', position: 'relative', marginBottom: '1%', padding: '1%'}}>
                <Line data={ predictedChartData } options={
                  {
                    maintainAspectRatio: false,
                    plugins: {
                      title: {
                        display: true,
                        text: '未來製程碳排趨勢',
                      },
                    },
                    tooltips: {
                      callbacks: {
                        label: (tooltipItem) => {
                          return String(Math.round(Number(tooltipItem.value))) + ' (gCO2e); 生產數量：' + tooltipItem.dataset.produceNumberData[tooltipItem.dataIndex];
                        },
                      },
                    },
                    scales: {
                      yAxes: [{
                        scaleLabel: {
                          display: true,
                          labelString: '碳排放量(gCO2e)',
                        },
                        suggestedMin: 0,
                      }],
                    }
                  }
                } />
              </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ProduceTrending;
