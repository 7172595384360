import React, { useEffect, useState } from "react";
import {
  Redirect,
} from 'react-router';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Popover,
  PopoverHeader,
  PopoverBody,
  Spinner,
} from "reactstrap";
import Select, { createFilter } from 'react-select';
import makeAnimated from 'react-select/animated';
import { verifiedLoginWithSetState } from 'ajax/LoginRequest';
import {
  createErpData,
  getErpData,
  updateErpData,
  deleteErpData,
  createNonProcessData,
  getNonProcessData,
  updateNonProcessData,
  deleteNonProcessData,
  getProductNumberListsByProductName,
  getFactorQuery,
  getGwpForEmission,
  getEmissionLists,
  getFridgeFactorLists,
  getFridgeGwpLists,
} from "ajax/NonProcess.js";
import carbonCategories from 'assets/json/carbon_categories.json';
import carbonCategoriesIndex from 'assets/json/carbon_categories_index.json';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";


function NonProcessDashboard() {
  const animatedComponents  = makeAnimated();
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [processesLists, setProcessesLists] = useState([]);
  const [addErpProcessModal, setAddErpProcessModal] = useState(false);
  const [erpDataEditorModal, setErpDataEditorModal] = useState(false);
  const [deleteErpDataModal, setDeleteErpDataModal] = useState(false);
  const [updatedResMessage, setUpdatedResMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [componentSerialNumber, setComponentSerialNumber] = useState('');
  const [componentNumber, setComponentNumber] = useState('');
  const [componentName, setComponentName] = useState('');
  const [componentUnit, setComponentUnit] = useState('');
  const [CO2e, setCO2e] = useState('');
  const [CO2eUnit, setCO2eUnit] = useState('');
  const [erpId, setErpId] = useState('');
  const [processName, setProcessName] = useState('');
  const [erpDataTable, setErpDataTable] = useState([]);
  const [noProcessMessage, setNoProcessMessage] = useState('');

  const [nonProcessesDataTable, setNonProcessesDataTable] = useState([]);
  const [addNonProcessModal, setAddNonProcessModal] = useState(false);
  const [categoryNumber, setCategoryNumber] = useState('');
  const [categorySource, setCategorySource] = useState('');
  const [perCarbonValue, setPerCarbonValue] = useState('');
  const [nonProcessComment, setNonProcessComment] = useState('');
  const [nonProcessCO2eUnit, setNonProcessCO2eUnit] = useState('');

  const [nonProcessId, setNonProcessId] = useState('');
  const [deleteNonProcessDataModal, setDeleteNonProcessDataModal] = useState(false);
  const [nonProcessDataEditorModal, setNonProcessDataEditorModal] = useState(false);
  const [factorToggleModal, setFactorToggleModal] = useState(false);
  const [categoryDescriptionPopoverOpen, setCategoryDescriptionPopoverOpen] = useState({popoverOpen: false});
  const [carbonCategoryDescription, setCarbonCategoryDescription] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [buttonAddErpDisabled, setAddErpButtonDisabled] = useState(false);

  const [nonProcessType, setNonProcessType] = useState('');
  const [statusNumber, setStatusNumber] = useState(0);

  const [productNumberLists, setProductNumberLists] = useState([]);
  const [selectedProductNumberLists, setSelectedProductNumberLists] = useState([]);

  const [factorKeyword, setFactorKeyword] = useState('');
  const [factorLists, setFactorLists] = useState([]);
  const [factorOptionLists, setFactorOptionLists] = useState([{
    label: '請選擇係數',
    value: '請選擇係數',
    co2e_value: '',
    co2e_unit: '',
    source_factor: '',
  }]);
  const [currentFactorOption, setCurrentFactorOption] = useState({});
  const [loadingFactorDataSpinner, setLoadingFactorDataSpinner] = useState('none');

  const [loadingDataSpinner, setLoadingDataSpinner] = useState('none');

  const [stableMobileEmissionLists, setStableMobileEmissionLists] = useState(['請選擇來源種類', '固定源', '移動源']);
  const [emissionFactorToggleModal, setEmissionFactorToggleModal] = useState(false);
  const [sourceFactor, setSourceFactor] = useState('');
  const [emissionGwpOption, setEmissionGwpOption] = useState('emission');
  const [emissionOptionLists, setEmissionOptionLists] = useState([]);
  const [emissionGwpOptionLists, setEmissionGwpOptionLists] = useState({});
  const [stableMobile, setStableMobile] = useState('請選擇來源');
  const [selectedEmissionOption, setSelectedEmissionOption] = useState({});
  const [gwpEmissionARPLists, setGwpEmissionARPLists] = useState([]);
  const [selectedEmissionArpOption, setSelectedEmissionArpOption] = useState({});

  const [fridgeFactorLists, setFridgeFactorLists] = useState([]);
  const [fridgeGwpLists, setFridgeGwpLists] = useState([]);
  const [fridgeEmissionFactorLists, setFridgeEmissionFactorLists] = useState([]);
  const [activityData, setActivityData] = useState('');

  const [selectedFridgeFactorOption, setSelectedFridgeFactorOption] = useState({});
  const [selectedFridgeGwpOption, setSelectedFridgeGwpOption] = useState({});
  const [selectedFridgeEmissionFactorOption, setSelectedFridgeEmissionFactorOption] = useState({});
  const [fridgeEmissionGwpLists, setFridgeEmissionGwpLists] = useState([]);
  const [selectedSpecieFridgeGwpOption, setSelectedSpecieFridgeGwpOption] = useState({});

  const [emissionResult, setEmissionResult] = useState('');
  const [referenceCommentOneMessage, setReferenceCommentOneMessage] = useState('');
  const [referenceCommentTwoMessage, setReferenceCommentTwoMessage] = useState('');
  const [nonProcessCalculatedOptions, setNonProcessCalculatedOptions] = useState([{
    label: '請選擇算好的碳排放量',
    value: '請選擇算好的碳排放量',
  }]);
  const [selectedNonProcessCalculatedOptions, setSelectedNonProcessCalculatedOptions] = useState({
    label: '請選擇算好的碳排放量',
    value: '請選擇算好的碳排放量',
  });
  const [successAddCalculatedMessage, setSuccessAddCalculatedMessage] = useState('');

  const filterConfig = {
    ignoreCase: false,
    ignoreAccents: false,
    trim: false,
    matchFrom: 'any',
  };

  const handleEmissionCategoryChange = async (selectedValue) => {
    setEmissionGwpOption(selectedValue);
    setStableMobileEmissionLists([]);
    setEmissionOptionLists([]);
    setEmissionGwpOptionLists({});
    setGwpEmissionARPLists([]);
    setFridgeFactorLists([]);
    setFridgeGwpLists([]);
    setSelectedEmissionOption({});
    setSelectedEmissionArpOption({});
    setSelectedFridgeGwpOption({});
    setSelectedFridgeEmissionFactorOption({});
    setSelectedSpecieFridgeGwpOption({});
    setFridgeEmissionFactorLists([]);
    setFridgeEmissionGwpLists([]);
    setEmissionResult('');
    setReferenceCommentOneMessage('');
    setReferenceCommentTwoMessage('');
    setUpdatedResMessage('');

    if (selectedValue === 'emission') {
      setStableMobileEmissionLists(['請選擇來源種類', '固定源', '移動源']);
    } else {
      setLoadingFactorDataSpinner('inline-block');
      setErrorMessage('');
      setButtonDisabled(true);

      let theFridgeFactorLists = await getFridgeFactorLists();
      setFridgeFactorLists(theFridgeFactorLists);

      let theFridgeGwpLists = await getFridgeGwpLists();
      setFridgeGwpLists(theFridgeGwpLists);

      if (Object.keys(selectedFridgeFactorOption).length === 0) {
        setSelectedFridgeFactorOption(theFridgeFactorLists[0]);

        let maxEmissionPercent = theFridgeFactorLists[0].emission_max_percent;
        let minEmissionPercent = theFridgeFactorLists[0].emission_min_percent;
        let percentOptions = [
          {
            label: (String(minEmissionPercent) + '%'),
            value: (minEmissionPercent / 100),
          },
          {
            label: (String((minEmissionPercent + maxEmissionPercent) / 2) + '%'),
            value: ((minEmissionPercent + maxEmissionPercent) / 2 / 100),
          },
          {
            label: (String(maxEmissionPercent) + '%'),
            value: (maxEmissionPercent / 100),
          },
        ];

        setFridgeEmissionFactorLists(percentOptions);
        setSelectedFridgeEmissionFactorOption(percentOptions[0]);
      }

      if (Object.keys(selectedFridgeGwpOption).length === 0) {
        setSelectedFridgeGwpOption(theFridgeGwpLists[0]);

        let fridgeGwpOptions = [
          {
            label: 'IPCC第四次評估報告(2007)',
            value: theFridgeGwpLists[0].ar4gwp100,
          },
          {
            label: 'IPCC第五次評估報告(2013)',
            value: theFridgeGwpLists[0].ar5gwp100,
          },
          {
            label: 'IPCC第六次評估報告(2021)',
            value: theFridgeGwpLists[0].ar6gwp100,
          },
        ];

        setFridgeEmissionGwpLists(fridgeGwpOptions);
        setSelectedSpecieFridgeGwpOption(fridgeGwpOptions[0]);
      }

      setLoadingFactorDataSpinner('none');
      setErrorMessage('');
      setButtonDisabled(false);
    }
  };

  const handleStableMobileChange = async (selectedValue) => {
    setStableMobile(selectedValue);
    setLoadingFactorDataSpinner('inline-block');
    setErrorMessage('');
    setButtonDisabled(true);

    if (selectedValue !== '請選擇來源') {
      let emissionLists = await getEmissionLists(selectedValue);
      setEmissionOptionLists(emissionLists);
      setSelectedEmissionOption(emissionLists[0]);

      if (Object.keys(emissionGwpOptionLists).length === 0) {
        let gwpForEmissionOptionLists = await getGwpForEmission();
        setEmissionGwpOptionLists(gwpForEmissionOptionLists);

        let ar4Gases = {};
        let ar5Gases = {};
        let ar6Gases = {};
        let gasName = '';
        let gasArr = Object.keys(gwpForEmissionOptionLists);
        for (let gasIndex=0; gasIndex<gasArr.length; gasIndex++) {
          gasName = gasArr[gasIndex];
          ar4Gases[gasName] = gwpForEmissionOptionLists[gasName]['ar4gwp100'];
          ar5Gases[gasName] = gwpForEmissionOptionLists[gasName]['ar5gwp100'];
          ar6Gases[gasName] = gwpForEmissionOptionLists[gasName]['ar6gwp100'];
        }

        setGwpEmissionARPLists([
          {
            label: 'IPCC第四次報告(2007)',
            value: 'ar4gwp100',
            gases: ar4Gases,
          },
          {
            label: 'IPCC第五次報告(2013)',
            value: 'ar5gwp100',
            gases: ar5Gases,
          },
          {
            label: 'IPCC第六次報告(2021)',
            value: 'ar6gwp100',
            gases: ar6Gases,
          },
        ]);

        setSelectedEmissionArpOption({
          label: 'IPCC第四次報告(2007)',
          value: 'ar4gwp100',
          gases: ar4Gases,
        });
      }
    } else {
      setEmissionOptionLists([]);
      setEmissionGwpOptionLists({});
      setGwpEmissionARPLists([]);
    }

    setLoadingFactorDataSpinner('none');
    setErrorMessage('');
    setButtonDisabled(false);
  };

  const handleEmissionOptionChange = async (selectedValue) => {
    setSelectedEmissionOption(selectedValue);
  };

  const handleEmissionArpOptionChange = (selectedValue) => {
    setSelectedEmissionArpOption(selectedValue);
  };

  const handleFridgeFactorOptionChange = (selectedValue) => {
    setSelectedFridgeFactorOption(selectedValue);
    let maxEmissionPercent = selectedValue.emission_max_percent;
    let minEmissionPercent = selectedValue.emission_min_percent;
    let percentOptions = [
      {
        label: (String(minEmissionPercent) + '%'),
        value: (minEmissionPercent / 100),
      },
      {
        label: (String((minEmissionPercent + maxEmissionPercent) / 2) + '%'),
        value: ((minEmissionPercent + maxEmissionPercent) / 2 / 100),
      },
      {
        label: (String(maxEmissionPercent) + '%'),
        value: (maxEmissionPercent / 100),
      },
    ];

    setFridgeEmissionFactorLists(percentOptions);
    setSelectedFridgeEmissionFactorOption(percentOptions[0]);
  };

  const handleFridgeGwpOptionChange = (selectedValue) => {
    setSelectedFridgeGwpOption(selectedValue);
    let fridgeGwpOptions = [
      {
        label: 'IPCC第四次評估報告(2007)',
        value: selectedValue.ar4gwp100,
      },
      {
        label: 'IPCC第五次評估報告(2013)',
        value: selectedValue.ar5gwp100,
      },
      {
        label: 'IPCC第六次評估報告(2021)',
        value: selectedValue.ar6gwp100,
      },
    ];

    setFridgeEmissionGwpLists(fridgeGwpOptions);
    setSelectedSpecieFridgeGwpOption(fridgeGwpOptions[0]);
  };

  const handleFridgeEmissionFactorOptionChange = (selectedValue) => {
    setSelectedFridgeEmissionFactorOption(selectedValue);
  };

  const handleFridgeEmissionGwpOptionChange = (selectedValue) => {
    setSelectedSpecieFridgeGwpOption(selectedValue);
  };

  const handleNonProcessCalculatedOptionChange = (selectedValue) => {
    setSelectedNonProcessCalculatedOptions(selectedValue);
    setCategorySource(selectedValue.emission_name);
    setPerCarbonValue(selectedValue.emission_value);
    setNonProcessCO2eUnit(selectedValue.emission_unit);
    setSourceFactor(selectedValue.source_factor);
  };

  const handleAddComponent = async () => {
    await verifiedLoginWithSetState(setRedirectToLogin);
    setErrorMessage('');

    let validatedResult = validateAddComponent(componentSerialNumber, componentName, componentNumber, componentUnit, CO2e, sourceFactor);
    if (validatedResult !== true) {
      setErrorMessage(validatedResult);

      return false;
    }

    let theProductNumberArr = [];
    let theSelectedProductNumberLists = selectedProductNumberLists;
    if (theSelectedProductNumberLists.length === 0) {
      theProductNumberArr.push(String(productNumberLists[1]['value']));
    } else {
      for (let index=0; index<theSelectedProductNumberLists.length; index++) {
        theProductNumberArr.push(String(theSelectedProductNumberLists[index]['value']));
      }
    }

    let addComponentPayload = {
      'component_number': componentSerialNumber,
      'component_name': componentName,
      'component_amount': Number(componentNumber),
      'component_unit': componentUnit,
      'co2e_value': Number(CO2e),
      'co2e_unit': CO2eUnit,
      'process_name': processName,
      'product_number': theProductNumberArr,
      'source_factor': sourceFactor,
    };

    await createErpData(addComponentPayload, setUpdatedResMessage, setErrorMessage, setAddErpProcessModal, addErpProcessModal);
    await getErpData(setProcessesLists, setErpDataTable, processName, setNonProcessesDataTable);
  };

  const handleAddNonProcess = async () => {
    await verifiedLoginWithSetState(setRedirectToLogin);
    setErrorMessage('');

    let validatedResult = validateAddNonProcess(categorySource, perCarbonValue, sourceFactor);
    if (validatedResult !== true) {
      setErrorMessage(validatedResult);

      return false;
    }

    let theProductNumberArr = [];
    let theSelectedProductNumberLists = selectedProductNumberLists;
    if (theSelectedProductNumberLists.length === 0) {
      theProductNumberArr.push(String(productNumberLists[1]['value']));
    } else {
      for (let index=0; index<theSelectedProductNumberLists.length; index++) {
        theProductNumberArr.push(String(theSelectedProductNumberLists[index]['value']));
      }
    }

    let addNonProcessPayload = {
      'category_number': categoryNumber,
      'category_source': categorySource,
      'co2e_per_number': Number(perCarbonValue),
      'co2e_per_number_unit': (nonProcessCO2eUnit === '' ? 'gCO2e' : nonProcessCO2eUnit),
      'co2e_comment': (nonProcessComment === '' ? 'no_comment' : nonProcessComment),
      'process_name': processName,
      'non_process_type': (nonProcessType === '' ? '總量' : nonProcessType),
      'product_number': theProductNumberArr,
      'source_factor': sourceFactor,
    };

    await createNonProcessData(addNonProcessPayload, setUpdatedResMessage, setErrorMessage, setAddNonProcessModal, addNonProcessModal);
    await getNonProcessData(setNonProcessesDataTable, processName);
  };

  const validateAddComponent = (componentSerialNumber, componentName, componentNumber, componentUnit, CO2e, sourceFactor) => {
    if (componentSerialNumber === '') {
      return '請輸入零件編號！';
    }

    if (componentName === '') {
      return '請輸入零件名稱！';
    }

    if (componentNumber === '') {
      return '請輸入用料零件數！';
    }

    if (componentUnit === '') {
      return '請輸入零件單位！';
    }

    if (sourceFactor === '') {
      return '請輸入係數來源！';
    }

    if (isNaN(Number(componentNumber))) {
      return '用料零件數需要是數字！';
    }

    if (componentNumber === '') {
      return '請輸入用料零件數！';
    }

    if (componentNumber === '0' || componentNumber[0] === '-') {
      return '用料零件數需要大於0的數字！';
    }

    if (isNaN(Number(CO2e))) {
      return '碳排放係數需要是數字！';
    }

    if (CO2e === '') {
      return '請輸入碳排放係數！';
    }

    if (CO2e === '0' || CO2e[0] === '-') {
      return '碳排放係數需要大於0的數字！';
    }

    return true;
  };

  const validateAddNonProcess = (categorySource, perCarbonValue, sourceFactor) => {
    if (categorySource === '') {
      return '請輸入碳排來源名稱！';
    }

    if (sourceFactor === '') {
      return '請輸入係數來源！';
    }

    if (perCarbonValue === '') {
      return '請輸入每單元碳排量單位！';
    }

    if (isNaN(Number(perCarbonValue)) === true) {
      return '每單元碳排量單位需要是數字！';
    }

    if (perCarbonValue === '0' || perCarbonValue[0] === '-') {
      return '每單元碳排量單位需要是大於0的數字！';
    }

    return true;
  };

  const handleProcessNameChange = async (selectedValue) => {
    setLoadingDataSpinner('inline-block');

    setProcessName(selectedValue);
    let theProcessName = await getErpData(setProcessesLists, setErpDataTable, selectedValue, setNonProcessesDataTable);

    if (theProcessName === false) {
      setNoProcessMessage('查無產品，請先到『產品製程設定』進行設定！');
      return false;
    }

    let theProductNumberLists = await getProductNumberListsByProductName(theProcessName, setProductNumberLists);

    if (theProductNumberLists.length === 1) {
      setNoProcessMessage('查無該產品的產品製造編號！');
      setAddErpButtonDisabled(true);
    } else {
      setNoProcessMessage('');
      setAddErpButtonDisabled(false);
    }

    setLoadingDataSpinner('none');
  };

  const handleAddFactorOptions = (e) => {
    setUpdatedResMessage('');
    let chName = e.target.dataset.chname;
    let co2eUnit = e.target.dataset.co2eunit;
    let co2eValue = e.target.dataset.co2evalue;

    let findOption = false;
    for (let index=0; index<factorOptionLists.length; index++) {
      if (factorOptionLists[index]['label'] === chName && factorOptionLists[index]['co2e_unit'] === co2eUnit && factorOptionLists[index]['co2e_value'] === co2eValue) {
        findOption = true;
        break
      }
    }

    if (findOption === false) {
      factorOptionLists.push({
        label: chName,
        value: co2eValue,
        co2e_value: co2eValue,
        co2e_unit: co2eUnit,
        source_factor: '產品碳足跡網',
      });
    }

    setFactorOptionLists(factorOptionLists);
    setUpdatedResMessage('成功新增' + chName + '到選用清單！');
  };

  const factorToggle = () => {
    setFactorToggleModal(!factorToggleModal);
    setUpdatedResMessage('');
    setErrorMessage('');
    setFactorKeyword('');
    setFactorLists([]);
  };

  const emissionFactorToggle = () => {
    setEmissionFactorToggleModal(!emissionFactorToggleModal);
    setUpdatedResMessage('');
    setErrorMessage('');
    setFactorKeyword('');
    setFactorLists([]);
    setEmissionOptionLists([]);
    setEmissionGwpOptionLists({});
    setGwpEmissionARPLists([]);
    setFridgeFactorLists([]);
    setFridgeGwpLists([]);
    setActivityData('');
    setReferenceCommentOneMessage('');
    setReferenceCommentTwoMessage('');
    setEmissionResult('');
    setFridgeEmissionGwpLists([]);
    setFridgeEmissionFactorLists([]);
    setSelectedEmissionOption([]);
    setSelectedFridgeGwpOption([]);
    setEmissionGwpOption('emission');
    setStableMobile('請選擇來源');
    setSuccessAddCalculatedMessage('');
    setStableMobileEmissionLists(['請選擇來源種類', '固定源', '移動源']);
  };

  const handleEmissionFactorCalculating = () => {
    if (activityData === '') {
      setErrorMessage('請輸入活動數據！');

      return false;
    }

    let theSelectedEmissionOption = selectedEmissionOption;
    let theSelectedEmissionArpOption = selectedEmissionArpOption;

    let currentSpecies = selectedFridgeGwpOption.species;
    let currentFridgeEmissionFactor = selectedFridgeEmissionFactorOption.value;
    let currentFridgeGwpLabel = selectedSpecieFridgeGwpOption.label;
    let currentFridgeGwpValue = selectedSpecieFridgeGwpOption.value;

    let calculatingFormula = '';
    let precision = 4;
    if (Object.keys(theSelectedEmissionOption).length > 0) {
      calculatingFormula = '公式：(activity_data (公斤) * recommended_factor (註1) * co2_gwp_value (註2) + activity_data (公斤) * recommended_factor (註1) * n2o_gwp_value (註2) + activity_data (公斤) * recommended_factor (註1)) * ch4_gwp_value (註2)) / 1000';
      let emissionResult = 'emission_name的碳排放量：emission_value(TCO2e)';
      let emissionName = theSelectedEmissionOption.value;
      let theActivityData = Number(activityData);
      let recommendedFactor = Number(theSelectedEmissionOption.recommended_factor);
      let gwpName = theSelectedEmissionArpOption.label;
      let co2GwpValue = Number(theSelectedEmissionArpOption['gases']['CO2']);
      let ch4GwpValue = Number(theSelectedEmissionArpOption['gases']['CH4']);
      let n2oGwpValue = Number(theSelectedEmissionArpOption['gases']['N2O']);
      let emissionValue = (
          (
            theActivityData * recommendedFactor * co2GwpValue +
            theActivityData * recommendedFactor * ch4GwpValue +
            theActivityData * recommendedFactor * n2oGwpValue
          ) / 1000
        ).toFixed(precision);

      emissionResult = emissionResult.replace('emission_name', emissionName);
      emissionResult = emissionResult.replace('emission_value', emissionValue);
      calculatingFormula = calculatingFormula.replace('co2_gwp_value', co2GwpValue);
      calculatingFormula = calculatingFormula.replace('ch4_gwp_value', ch4GwpValue);
      calculatingFormula = calculatingFormula.replace('n2o_gwp_value', n2oGwpValue);
      calculatingFormula = calculatingFormula.replace(/activity_data/g, theActivityData);
      calculatingFormula = calculatingFormula.replace(/recommended_factor/g, recommendedFactor);

      setEmissionResult(emissionResult);
      setUpdatedResMessage(calculatingFormula);
      setReferenceCommentOneMessage('註1：溫室氣體排放管理係數表6.0.4');
      setReferenceCommentTwoMessage('註2：' + gwpName);
    } else {
      calculatingFormula = '公式：(activity_data (公斤) * emission_factor_percent (註1) * gwp_value (註2)) / 1000';
      let theActivityData = Number(activityData);
      let gwpName = currentFridgeGwpLabel;
      let emissionResult = 'emission_name的碳排放量：emission_value(TCO2e)';
      emissionResult = emissionResult.replace('emission_name', currentSpecies);

      let emissionValue = theActivityData * Number(currentFridgeEmissionFactor) * Number(currentFridgeGwpValue);

      emissionResult = emissionResult.replace('emission_value', emissionValue);

      calculatingFormula = calculatingFormula.replace('activity_data', activityData);
      calculatingFormula = calculatingFormula.replace('emission_factor_percent', currentFridgeEmissionFactor);
      calculatingFormula = calculatingFormula.replace('gwp_value', currentFridgeGwpValue);

      setEmissionResult(emissionResult);
      setUpdatedResMessage(calculatingFormula);
      setReferenceCommentOneMessage('註1：溫室氣體排放管理係數表6.0.4');
      setReferenceCommentTwoMessage('註2：' + gwpName);
    }
  };

  const handleAddCalculatedResult = () => {
    let sourceFactor = emissionResult + ';' + updatedResMessage + ';' + referenceCommentOneMessage + ';' + referenceCommentTwoMessage;
    let theNonProcessCalculatedOptions = nonProcessCalculatedOptions;
    let emissionName = emissionResult.split('的碳排放量')[0];
    let emissionValue = emissionResult.split('：')[1].replace('(TCO2e)', '');
    let nonProcessCalculatedOption = {
      emission_name: emissionName,
      emission_value: emissionValue,
      emission_unit: 'tCO2e',
      source_factor: sourceFactor,
      label: emissionName,
      value: emissionValue,
    };

    let duplicatedCount = 0;
    for (let index=0; index<theNonProcessCalculatedOptions.length; index++) {
      if (theNonProcessCalculatedOptions[index]['label'] === nonProcessCalculatedOption.label) {
        duplicatedCount += 1;
      }
    }

    if (duplicatedCount > 0) {
      nonProcessCalculatedOption.label = emissionName + '_' + String(duplicatedCount);
    }

    theNonProcessCalculatedOptions.push(nonProcessCalculatedOption);
    setNonProcessCalculatedOptions(theNonProcessCalculatedOptions);
    setSuccessAddCalculatedMessage('成功新增' + emissionName + '的計算碳排放量！');
  };

  const handleFactorQuery = async () => {
    if (factorKeyword === '') {
      setErrorMessage('請輸入關鍵字！');
      setFactorLists([]);

      return false;
    }

    setLoadingFactorDataSpinner('inline-block');
    setErrorMessage('');
    setButtonDisabled(true);

    let theFactorLists = await getFactorQuery(factorKeyword);
    if (theFactorLists.length === 0) {
      setErrorMessage('查無相關係數！');
    }

    setFactorLists(theFactorLists);
    setLoadingFactorDataSpinner('none');
    setButtonDisabled(false);
  };

  const toggle = () => {
    setComponentSerialNumber('');
    setComponentName('');
    setComponentNumber('');
    setComponentUnit('');
    setCO2e('');
    setCO2eUnit(null);
    setUpdatedResMessage('');
    setErrorMessage('');
    setSourceFactor('');
    setAddErpProcessModal(!addErpProcessModal);
    setSelectedProductNumberLists([]);
    setCurrentFactorOption({});
  };

  const nonProcessToggle = () => {
    setCategoryNumber('');
    setCategorySource('');
    setPerCarbonValue('');
    setNonProcessCO2eUnit('');
    setNonProcessComment('');
    setUpdatedResMessage('');
    setErrorMessage('');
    setSourceFactor('');
    setAddNonProcessModal(!addNonProcessModal);
    setNonProcessType('');
    setStatusNumber(statusNumber + 1);
    setSelectedNonProcessCalculatedOptions({
      label: '請選擇算好的碳排放量',
      value: '請選擇算好的碳排放量',
    });
  };

  const erpDataEditorToggle = (e) => {
    if (e.target.dataset.processname === undefined) {
      setErpDataEditorModal(!erpDataEditorModal);
      setUpdatedResMessage('');
      setErrorMessage('');
      setSourceFactor('');

      return false;
    }

    let theProductNumberLists = [];
    let theProductNumberArr = JSON.parse(e.target.dataset.productnumberlists);

    for (let index=0; index<theProductNumberArr.length; index++) {
      theProductNumberLists.push({
        label: theProductNumberArr[index],
        value: theProductNumberArr[index],
      });
    }
    setSelectedProductNumberLists(theProductNumberLists);

    setProcessName(e.target.dataset.processname);
    setComponentSerialNumber(e.target.dataset.erpnumber);
    setComponentName(e.target.dataset.erpname);
    setComponentNumber(e.target.dataset.erpamount);
    setComponentUnit(e.target.dataset.erpunit);
    setCO2e(e.target.dataset.erpco2e);
    setCO2eUnit(e.target.dataset.erpco2eunit);
    setErpId(e.target.dataset.erpid);
    setUpdatedResMessage('');
    setErrorMessage('');
    setSourceFactor(e.target.dataset.sourcefactor);
    setErpDataEditorModal(!erpDataEditorModal);
    setStatusNumber(statusNumber + 1);
  };

  const nonProcessDataEditorToggle = (e) => {
    if (e.target.dataset.processname === undefined) {
      setUpdatedResMessage('');
      setUpdatedResMessage('');
      setErrorMessage('');
      setSourceFactor('');
      setNonProcessDataEditorModal(!nonProcessDataEditorModal);

      return false;
    }

    setProcessName(e.target.dataset.processname);
    setCategoryNumber(e.target.dataset.categorynumber);
    setCategorySource(e.target.dataset.categorysource);
    setPerCarbonValue(e.target.dataset.co2epernumber);
    setNonProcessCO2eUnit(e.target.dataset.co2epernumberunit);
    setNonProcessComment(e.target.dataset.co2ecomment === 'no_comment' ? '' : e.target.dataset.co2ecomment);
    setUpdatedResMessage('');
    setUpdatedResMessage('');
    setErrorMessage('');
    setNonProcessId(e.target.dataset.nonprocessid);
    setNonProcessType(e.target.dataset.nonprocesstype);
    setSourceFactor(e.target.dataset.sourcefactor);
    setNonProcessDataEditorModal(!nonProcessDataEditorModal);
  };

  const deleteToggle = (e) => {
    if (e.target.dataset.erpid === undefined) {
      setErrorMessage('');
      setUpdatedResMessage('');
      setDeleteErpDataModal(!deleteErpDataModal);

      return false;
    }
    setErrorMessage('');
    setUpdatedResMessage('');
    setErpId(e.target.dataset.erpid);
    setDeleteErpDataModal(!deleteErpDataModal);
  };

  const deleteNonProcessToggle = (e) => {
    setDeleteNonProcessDataModal(!deleteNonProcessDataModal);
    if (e.target.dataset.nonprocessid === undefined) {
      return false;
    }

    setCategoryNumber('');
    setErrorMessage('');
    setUpdatedResMessage('');
    setNonProcessId(e.target.dataset.nonprocessid);
  };

  const categoryDescriptionToggle = () => {
    setCategoryDescriptionPopoverOpen({
      popoverOpen: !categoryDescriptionPopoverOpen.popoverOpen,
    });
    let categoryIndex = carbonCategoriesIndex[categoryNumber]
    let categoryName = categoryNumber;
    setCarbonCategoryDescription(carbonCategories[categoryIndex][categoryName]);
  };

  const handleUpdateErp = async () => {
    setErrorMessage('');
    setUpdatedResMessage('');
    await verifiedLoginWithSetState(setRedirectToLogin);

    let validatedResult = validateAddComponent(componentSerialNumber, componentName, componentNumber, componentUnit, CO2e, sourceFactor);
    if (validatedResult !== true) {
      setErrorMessage(validatedResult);

      return false;
    }

    let theProductNumberArr = [];
    let theSelectedProductNumberLists = selectedProductNumberLists;
    if (theSelectedProductNumberLists.length === 0) {
      theProductNumberArr.push(String(productNumberLists[1]['value']));
    } else {
      for (let index=0; index<theSelectedProductNumberLists.length; index++) {
        theProductNumberArr.push(String(theSelectedProductNumberLists[index]['value']));
      }
    }

    let updateErpPayload = {
      'new_component_number': componentSerialNumber,
      'new_component_name': componentName,
      'new_component_amount': Number(componentNumber),
      'new_component_unit': componentUnit,
      'new_co2e_value': Number(CO2e),
      'new_co2e_unit': CO2eUnit,
      'new_product_number': theProductNumberArr,
      'erp_id': Number(erpId),
      'new_source_factor': sourceFactor,
    };

    await updateErpData(updateErpPayload, setUpdatedResMessage, setErrorMessage, setErpDataEditorModal, erpDataEditorModal);
    await getErpData(setProcessesLists, setErpDataTable, processName, setNonProcessesDataTable);
  };

  const handleUpdateNonProcess = async () => {
    setErrorMessage('');
    setUpdatedResMessage('');
    await verifiedLoginWithSetState(setRedirectToLogin);

    let validatedResult = validateAddNonProcess(categorySource, perCarbonValue, sourceFactor);
    if (validatedResult !== true) {
      setErrorMessage(validatedResult);

      return false;
    }

    let theProductNumberArr = [];
    let theSelectedProductNumberLists = selectedProductNumberLists;
    if (theSelectedProductNumberLists.length === 0) {
      theProductNumberArr.push(String(productNumberLists[1]['value']));
    } else {
      for (let index=0; index<theSelectedProductNumberLists.length; index++) {
        theProductNumberArr.push(String(theSelectedProductNumberLists[index]['value']));
      }
    }

    let updateNonProcessPayload = {
      'new_category_number': categoryNumber,
      'new_category_source': categorySource,
      'new_co2e_per_number': Number(perCarbonValue),
      'new_co2e_per_number_unit': (nonProcessCO2eUnit === '' ? 'gCO2e' : nonProcessCO2eUnit),
      'new_co2e_comment': (nonProcessComment === '' ? 'no_comment' : nonProcessComment),
      'new_non_process_type': (nonProcessType === '' ? '總量' : nonProcessType),
      'non_process_id': Number(nonProcessId),
      'new_product_number': theProductNumberArr,
      'new_source_factor': sourceFactor,
    };

    await updateNonProcessData(updateNonProcessPayload, setUpdatedResMessage, setErrorMessage, setNonProcessDataEditorModal, nonProcessDataEditorModal);
    await getNonProcessData(setNonProcessesDataTable, processName);
  };

  const handleDeleteErpData = async () => {
    await verifiedLoginWithSetState(setRedirectToLogin);
    setUpdatedResMessage('');
    setErrorMessage('');

    await deleteErpData(erpId, setUpdatedResMessage, setErrorMessage, setDeleteErpDataModal, deleteErpDataModal);
    await getErpData(setProcessesLists, setErpDataTable, processName, setNonProcessesDataTable);
  };

  const handleDeleteNonProcessData = async () => {
    await verifiedLoginWithSetState(setRedirectToLogin);
    setUpdatedResMessage('');
    setErrorMessage('');

    await deleteNonProcessData(nonProcessId, setUpdatedResMessage, setErrorMessage, setDeleteNonProcessDataModal, deleteNonProcessDataModal);
    await getNonProcessData(setNonProcessesDataTable, processName);
  };

  const handleCategoryNumberAndDescriptionChange = (selectedValue) => {
    let categoryIndex = carbonCategoriesIndex[selectedValue];
    setCategoryNumber(selectedValue);
    setCarbonCategoryDescription(carbonCategories[categoryIndex][selectedValue]);
  };

  const handleFactorOptionsListsChange = (selectedLists) => {
    if (selectedLists.label === '請選擇係數') {
      setComponentName('');
      setCO2e('');
      setCO2eUnit('');
      setSourceFactor('');
      setCurrentFactorOption({});

      return false;
    }

    let co2eUnit = selectedLists.co2e_unit;
    if (co2eUnit[0] === 'k') {
      co2eUnit = 'KgCO2e';
    }
    if (co2eUnit[0] === 't') {
      co2eUnit = 'TCO2e';
    }

    setComponentName(selectedLists.label);
    setCO2e(Number(selectedLists.value));
    setCO2eUnit(selectedLists.co2e_unit);
    setSourceFactor(selectedLists.source_factor);
    setCurrentFactorOption({
      label: selectedLists.label,
      value: selectedLists.value,
    });
  };

  const handleProductNumberListsChange = (selectedLists) => {
    for (let index=0 ;index<selectedLists.length; index++) {
      if (selectedLists[index]['label'] === '全選') {
        setSelectedProductNumberLists(productNumberLists.slice(1));
        return false;
      }
    }

    setSelectedProductNumberLists(selectedLists);
  };

  useEffect(() => {
    if (statusNumber > 0) {
    } else {
      setLoadingDataSpinner('inline-block');

      async function verifiedLogin() {
        await verifiedLoginWithSetState(setRedirectToLogin);
      }

      verifiedLogin();

      async function genProcessesListsNonProcessErpDataTables() {
        let theProcessName = await getErpData(setProcessesLists, setErpDataTable, '', setNonProcessesDataTable);
        if (theProcessName === false) {
          setNoProcessMessage('查無產品，請先到『產品製程設定』進行設定！');
          return false;
        }

        let theProductNumberLists = await getProductNumberListsByProductName(theProcessName, setProductNumberLists);

        if (theProductNumberLists.length === 1) {
          setNoProcessMessage('查無該產品的產品製造編號！');
          setAddErpButtonDisabled(true);
        }

        setLoadingDataSpinner('none');
      }

      genProcessesListsNonProcessErpDataTables();
    }
  }, [statusNumber]);

  if (redirectToLogin) {
    return <Redirect to='/admin/login'/>
  }

  return (
    <>
      <div className="content">
            {
              loadingDataSpinner === 'inline-block' ? <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem', display: 'inline-block'}} children={'false'} /> :
              <Row>
              <Col md="12">
              <Card>
              <CardHeader>
                { (processName !== undefined && processName.length === 0 ) ? <h4 className="text-danger">{ noProcessMessage }</h4> : <></> }
                {
                  processesLists.length > 0 ?
                    <Input className="w-100" name="processes" onChange={event => handleProcessNameChange(event.target.value)} type="select" value={ processName } placeholder="請選擇產品名稱">
                      {
                        processesLists.map((item, itemIndex) => {
                          if (buttonDisabled === true) {
                            setButtonDisabled(false);
                          }
                          if (processName === '' && itemIndex === 0) {
                            setProcessName(item['process_name']);
                          }
                          if (!CO2eUnit) {
                            setCO2eUnit('KgCO2e');
                          }

                          return <option key={'processes' + itemIndex}>{ item['process_name'] }</option>
                        })
                      }
                    </Input> : <></>
                }
                <CardTitle tag="h4">原料碳排填入{' '}
                  <Button className="btn-icon-setting" disabled={ buttonDisabled || buttonAddErpDisabled } onClick={toggle}><FontAwesomeIcon icon={ faPlus }/>新增設定</Button>{' '}
                  <Button className="btn-icon-setting" disabled={ buttonDisabled || buttonAddErpDisabled } onClick={ factorToggle }><FontAwesomeIcon icon={ faPlus }/>係數庫查詢設定</Button>{' '}
                  <span style={{
                    display: (noProcessMessage !== '查無該產品的產品製造編號！') ? 'none' : '',
                  }} className="text-danger">{ noProcessMessage }</span>
                </CardTitle>
                <Modal keyboard={ false } backdrop={ "static" } key="addErpProcessModal" isOpen={addErpProcessModal} toggle={toggle}>
                  <ModalHeader className="modal-title" toggle={toggle}>{ '新增' } <span className="modal-text">{ processName }</span>{ ' 產品原料' }</ModalHeader>
                    <ModalBody>
                      <Form onSubmit={ (event) => { event.preventDefault(); } }>
                        <FormGroup>
                          <Label className="modal-form-label" for="component-serial-number">請輸入零件編號</Label>
                          <Input name="component-serial-number" onChange={event => setComponentSerialNumber(event.target.value)} type="text" value={componentSerialNumber || ''} placeholder="請輸入零件編號" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="component-name">請輸入零件名稱</Label>
                          <Input name="component-name" onChange={event => setComponentName(event.target.value)} type="text" value={componentName || ''} placeholder="請輸入零件名稱" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="component-number">請輸入用料零件數</Label>
                          <Input name="component-number" onChange={event => setComponentNumber(event.target.value)} type="text" value={componentNumber || ''} placeholder="請輸入用料零件數" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="component-unit">請輸入零件單位</Label>
                          <Input name="component-unit" onChange={event => setComponentUnit(event.target.value)} type="text" value={componentUnit || ''} placeholder="請輸入用料零件數" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="component-co2e">請輸入碳排放係數</Label>
                          <Input name="component-co2e" onChange={event => setCO2e(event.target.value)} type="text" value={CO2e || ''} placeholder="請輸入碳排放係數" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="co2e-unit">請選擇碳排放係數單位</Label>
                          <Input name="co2e-unit" onChange={event => setCO2eUnit(event.target.value)} type="select" value={ CO2eUnit || 'KgCO2e' } placeholder="請選擇碳排放係數單位">
                            <option key="kgCO2e">KgCO2e</option>
                            <option key="TCO2e">TCO2e</option>
                          </Input>
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="product-number">請選擇產品製造批號</Label>
                          <Select
                            onChange={event => handleProductNumberListsChange(event)}
                            closeMenuOnSelect={true}
                            filterOption={ createFilter(filterConfig) }
                            isMulti
                            components={animatedComponents}
                            value={
                              (selectedProductNumberLists.length === 0 && productNumberLists.length > 1) ? {label: productNumberLists[1]['label'], value: productNumberLists[1]['value']} : selectedProductNumberLists
                            }
                            options={productNumberLists}
                            placeholder="請選擇產品製造批號"
                            noOptionsMessage={() => '沒有產品製造批號可以選擇！'}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="source-factor">請輸入係數來源</Label>
                          <Input name="source-factor" onChange={ event => setSourceFactor(event.target.value) } type="text" value={sourceFactor || ''} placeholder="請輸入係數來源"/>
                        </FormGroup>
                        <FormGroup style={{ display: (factorOptionLists.length > 1 ? '' : 'none') } }>
                          <Label className="modal-form-label" for="carbon-factor-option">請選擇係數</Label>
                          <Select
                            onChange={event => handleFactorOptionsListsChange(event)}
                            closeMenuOnSelect={true}
                            filterOption={ createFilter(filterConfig) }
                            components={animatedComponents}
                            value={ (Object.keys(currentFactorOption).length === 0) ? factorOptionLists[0] : currentFactorOption }
                            options={ factorOptionLists }
                            placeholder="請選擇係數"
                            noOptionsMessage={() => '沒有係數可以選擇！'}
                          />
                        </FormGroup>
                        <FormGroup>
                          <h4 className="text-info">{ updatedResMessage }</h4>
                          <h5 className="text-danger">{ errorMessage }</h5>
                        </FormGroup>
                      </Form>
                    </ModalBody>
                    <ModalFooter className="modal-footer-center">
                      <Button className="btn-setting" onClick={ handleAddComponent }>新增</Button>{' '}
                      <Button className="btn-cancel" onClick={ toggle }>取消</Button>
                    </ModalFooter>
                </Modal>
                <Modal keyboard={ false } backdrop={ "static" } size="xl" key="factorToggleModal" isOpen={factorToggleModal} toggle={ factorToggle }>
                  <ModalHeader className="modal-title" toggle={ factorToggle }>{ '係數庫查詢與設定 ' }
                    {
                      updatedResMessage.length > 0 ? (<span className="text-info">{ '(' + updatedResMessage + ') ' }</span>) : ''
                    }
                    <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem', display: loadingFactorDataSpinner}} children={'false'} />
                  </ModalHeader>
                    <ModalBody>
                      <Form onSubmit={ (event) => { event.preventDefault(); } }>
                        <FormGroup>
                          <Label className="modal-form-label" for="factor-keyword">請輸入關鍵字</Label>
                          <Input name="factor-keyword" onChange={event => setFactorKeyword(event.target.value)} type="text" value={ factorKeyword || ''} placeholder="請輸入係數關鍵字" />
                        </FormGroup>
                        <FormGroup>
                          <h5 className="text-danger">{ errorMessage }</h5>
                        </FormGroup>
                      </Form>
                      <Table>
                        {
                          factorLists.length > 0 ? <>
                          <thead className="text-info">
                      <tr>
                        <th className="th-customize">係數名稱</th>
                        <th className="th-customize">係數單位</th>
                        <th className="th-customize">係數值</th>
                        <th className="th-customize">盤查時間</th>
                        <th className="th-customize">生命週期</th>
                        <th className="th-customize">盤查國家</th>
                        <th className="th-customize">加入清單</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      factorLists.map((item, index) => {
                        return <tr key={ item['ch_name'] + index }>
                          <td className="td-customize">{ item['ch_name'] }</td>
                          <td className="td-customize">{ item['co2e_unit'] }</td>
                          <td className="td-customize">{ item['co2e_value'] }</td>
                          <td className="td-customize">{ item['checked_date_range'] }</td>
                          <td className="td-customize">{ item['produce_area_name'] }</td>
                          <td className="td-customize">{ item['lifecycle_boundary'] }</td>
                          <td className="td-customize">
                          <Button
                            onClick={ handleAddFactorOptions }
                            id={ item['ch_name'] + index + 'button' }
                            className="btn-setting"
                            data-chname={ item['ch_name'] }
                            data-co2eunit={ item['co2e_unit'] }
                            data-co2evalue={ item['co2e_value'] }
                          >
                          加入
                          </Button>
                        </td>
                        </tr>
                      })
                    }
                    </tbody></> : <></>
                  }
                  </Table>
                    </ModalBody>
                    <ModalFooter className="modal-footer-center">
                      <Button disabled={ buttonDisabled } className="btn-setting" onClick={ handleFactorQuery }>查詢</Button>{' '}
                      <Button disabled={ buttonDisabled } className="btn-cancel" onClick={ factorToggle }>取消</Button>
                    </ModalFooter>
                </Modal>
                <Modal keyboard={ false } backdrop={ "static" } size="xl" key="emissionFactorToggleModal" isOpen={emissionFactorToggleModal} toggle={ emissionFactorToggle }>
                  <ModalHeader className="modal-title" toggle={ emissionFactorToggle }>{ '係數庫查詢 ' } <span className="text-info">{ successAddCalculatedMessage }</span>
                    <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem', display: loadingFactorDataSpinner}} children={'false'} />
                  </ModalHeader>
                    <ModalBody>
                      <Form>
                        <Label className="modal-form-label" for="emission-category">請選擇查詢種類</Label>
                        <Input name="emission-category" onChange={event => handleEmissionCategoryChange(event.target.value)} type="select" value={ emissionGwpOption || '' }>
                          {
                            [
                              {label: '排放來源', value: 'emission'},
                              {label: '冷凍/冷藏/冷媒逸散', value: 'refrigerant'},
                            ].map((item, index) => {
                              return <option label={ item.label } key={ 'emission-category-' + index }>{ item.value }</option>
                            })
                          }
                        </Input>
                        {
                          stableMobileEmissionLists.length > 0 ? <>
                          <Label className="modal-form-label" for="stable-mobile">請選擇固定源或移動源</Label>
                          <Input name="stable-mobile" onChange={event => handleStableMobileChange(event.target.value)} type="select" value={ stableMobile || '' }>
                          {
                            stableMobileEmissionLists.map((item, index) => {
                              return <option label={ item } key={ 'stable-mobile-' + index }>{ item }</option>
                            })
                          }
                          </Input></> : <></>
                        }
                        {
                          emissionOptionLists.length > 0 ? <FormGroup>
                          <Label className="modal-form-label" for="emission-lists">請選擇排放來源名稱</Label>
                          <Select
                            onChange={event => handleEmissionOptionChange(event)}
                            closeMenuOnSelect={true}
                            filterOption={ createFilter(filterConfig) }
                            components={animatedComponents}
                            value={selectedEmissionOption}
                            options={emissionOptionLists}
                            placeholder="請選擇排放來源名稱"
                            noOptionsMessage={() => '沒有排放來源可以選擇！'}
                          />
                        </FormGroup> : <></>
                        }
                        {
                          gwpEmissionARPLists.length > 0 ? <FormGroup>
                            <Label className="modal-form-label" for="emission-arp-lists">請選擇ARP溫室氣體潛势報告</Label>
                            <Select
                              onChange={event => handleEmissionArpOptionChange(event)}
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              value={selectedEmissionArpOption}
                              options={gwpEmissionARPLists}
                              placeholder="請選擇ARP溫室氣體潛勢"
                              noOptionsMessage={() => '沒有ARP溫室氣體潛勢可以選擇！'}
                            />
                          </FormGroup> : <></>
                        }
                        {
                          fridgeFactorLists.length > 0 ? <FormGroup>
                            <Label className="modal-form-label" for="fridge-factor-lists">請選擇設備名稱</Label>
                            <Select
                              onChange={event => handleFridgeFactorOptionChange(event)}
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              value={selectedFridgeFactorOption}
                              options={fridgeFactorLists}
                              placeholder="請選擇設備名稱"
                              noOptionsMessage={() => '沒有設備名稱可以選擇！'}
                            />
                          </FormGroup> : <></>
                        }
                        {
                          fridgeEmissionFactorLists.length > 0 ? <FormGroup>
                            <Label className="modal-form-label" for="fridge-emission-factor-lists">請選擇設備排放因子(%)</Label>
                            <Select
                              onChange={event => handleFridgeEmissionFactorOptionChange(event)}
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              value={selectedFridgeEmissionFactorOption}
                              options={fridgeEmissionFactorLists}
                              placeholder="請選擇設備排放因子"
                              noOptionsMessage={() => '沒有設備排放因子可以選擇！'}
                            />
                          </FormGroup> : <></>
                        }
                        {
                          fridgeGwpLists.length > 0 ? <FormGroup>
                            <Label className="modal-form-label" for="fridge-gwp-lists">請選擇冷媒化學式</Label>
                            <Select
                              onChange={event => handleFridgeGwpOptionChange(event)}
                              closeMenuOnSelect={true}
                              filterOption={ createFilter(filterConfig) }
                              components={animatedComponents}
                              value={selectedFridgeGwpOption}
                              options={fridgeGwpLists}
                              placeholder="請選擇冷媒化學式"
                              noOptionsMessage={() => '沒有冷媒化學式可以選擇！'}
                            />
                          </FormGroup> : <></>
                        }
                        {
                          fridgeEmissionGwpLists.length > 0 ? <FormGroup>
                            <Label className="modal-form-label" for="fridge-emission-gwp-lists">請選擇該冷媒化學式之GWP值</Label>
                            <Select
                              onChange={event => handleFridgeEmissionGwpOptionChange(event)}
                              closeMenuOnSelect={true}
                              filterOption={ createFilter(filterConfig) }
                              components={animatedComponents}
                              value={selectedSpecieFridgeGwpOption}
                              options={fridgeEmissionGwpLists}
                              placeholder="請選擇冷媒化學式"
                              noOptionsMessage={() => '沒有冷媒化學式可以選擇！'}
                            />
                          </FormGroup> : <></>
                        }
                        {
                          (Object.keys(selectedEmissionOption).length > 0 || Object.keys(selectedFridgeGwpOption).length > 0) ? <FormGroup>
                            <Label className="modal-form-label" for="activity-data">請輸入活動數據(填充量)(公斤)</Label>
                            <Input name="activity-data" onChange={event => setActivityData(event.target.value)} type="text" value={ activityData || '' } />
                            </FormGroup> : <></>
                        }
                        <FormGroup>
                          <h5 className="text-danger">{ errorMessage }</h5>
                          <h6 className="text-info">{ emissionResult }</h6>
                          <h6 className="text-info">{ updatedResMessage }</h6>
                          <h6 className="text-info">{ referenceCommentOneMessage }</h6>
                          <h6 className="text-info">{ referenceCommentTwoMessage }</h6>
                        </FormGroup>
                      </Form>
                    </ModalBody>
                    <ModalFooter className="modal-footer-center">
                      <Button disabled={ buttonDisabled } className="btn-setting" onClick={ handleEmissionFactorCalculating }>計算</Button>{' '}
                      <Button disabled={ emissionResult === '' ? true : false } className="btn-setting-add" onClick={ handleAddCalculatedResult }>加入</Button>{' '}
                      <Button disabled={ buttonDisabled } className="btn-cancel" onClick={ emissionFactorToggle }>取消</Button>
                    </ModalFooter>
                </Modal>
              </CardHeader>
              <CardBody>
                <Table>
                  { erpDataTable.length > 0 ? <><thead className="text-info">
                      <tr>
                        <th className="th-customize">零件編號</th>
                        <th className="th-customize">零件名稱</th>
                        <th className="th-customize">用料零件數</th>
                        <th className="th-customize">零件單位</th>
                        <th className="th-customize">碳排放係數</th>
                        <th className="th-customize">碳排放係數單位</th>
                        <th className="th-customize">編輯</th>
                        <th className="th-customize">刪除</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      erpDataTable.map((item, index) => {
                        return <tr key={ item['process_name'] + index }>
                          <td className="td-customize">{ item['erp_number'] }</td>
                          <td className="td-customize">
                            <span style={{ fontWeight: (item['erp_name'].length > 7 ? 'bold' : '') }} id={ 'erp_data' + index } data-erpname={ item['erp_name'] } href="#" target='_blank' rel="noreferrer" >{ item['erp_name'].slice(0, 7) }{ item['erp_name'].length > 7 ? '...' : '' }</span>
                            {
                              item['erp_name'].length > 7 ?
                                <ReactTooltip
                                  anchorSelect={ '#erp_data' + index }
                                  place='right'
                                  variant='dark'
                                  float='false'
                                  content={ item['erp_name'] }
                                /> : null
                            }
                          </td>
                          <td className="td-customize">{ item['erp_amount'] }</td>
                          <td className="td-customize">{ item['erp_unit'] }</td>
                          <td className="td-customize">{ item['erp_co2e'] }</td>
                          <td className="td-customize">{ item['erp_co2e_unit'] }</td>
                          <td className="td-customize">
                          <Button
                            id={ item['process_name'] + index + 'button' }
                            className="btn-setting"
                            onClick={ erpDataEditorToggle }
                            data-processname={ item['process_name'] }
                            data-erpnumber={ item['erp_number'] }
                            data-erpname={ item['erp_name'] }
                            data-erpamount={ item['erp_amount'] }
                            data-erpunit={ item['erp_unit'] }
                            data-erpco2e={ item['erp_co2e'] }
                            data-erpco2eunit={ item['erp_co2e_unit'] }
                            data-erpid={ item['erp_id'] }
                            data-sourcefactor={ item['source_factor'] }
                            data-productnumberlists={ JSON.stringify(item['product_number']) }
                          >
                          編輯
                          </Button>
                        </td>
                        <td className="td-customize">
                          <Button
                            id={ item['process_name'] + index + 'deleted_button' }
                            className="btn-delete"
                            onClick={ deleteToggle }
                            data-erpid={ item['erp_id'] }
                          >
                          <FontAwesomeIcon icon={ faTrashCan } />{ ' ' }
                          刪除
                          </Button>
                        </td>
                        </tr>
                      })
                    }
                    </tbody></> : <></>
                  }
                  <Modal keyboard={ false } backdrop={ "static" } key="erpDataEditorModal" isOpen={ erpDataEditorModal } toggle={ erpDataEditorToggle } className="">
                  <ModalHeader className="modal-title" toggle={ erpDataEditorToggle }>{ '修改' } <span className="modal-text">{ processName }</span> { ' 產品原料' }</ModalHeader>
                    <ModalBody>
                      <Form onSubmit={ (event) => { event.preventDefault(); } }>
                        <FormGroup>
                          <Label className="modal-form-label" for="component-serial-number">請輸入零件編號</Label>
                          <Input name="component-serial-number" onChange={event => setComponentSerialNumber(event.target.value)} type="text" value={componentSerialNumber || ''} placeholder="請輸入零件編號" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="component-name">請輸入零件名稱</Label>
                          <Input name="component-name" onChange={event => setComponentName(event.target.value)} type="text" value={componentName || ''} placeholder="請輸入零件名稱" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="component-number">請輸入用料零件數</Label>
                          <Input name="component-number" onChange={event => setComponentNumber(event.target.value)} type="text" value={componentNumber || ''} placeholder="請輸入用料零件數" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="component-unit">請輸入零件單位</Label>
                          <Input name="component-unit" onChange={event => setComponentUnit(event.target.value)} type="text" value={componentUnit || ''} placeholder="請輸入用料零件數" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="component-co2e">請輸入碳排放係數</Label>
                          <Input name="component-co2e" onChange={event => setCO2e(event.target.value)} type="text" value={CO2e || ''} placeholder="請輸入碳排放係數" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="co2e-unit">請選擇碳排放係數單位</Label>
                          <Input name="co2e-unit" onChange={event => setCO2eUnit(event.target.value)} type="select" value={ CO2eUnit || 'KgCO2e' } placeholder="請選擇碳排放係數單位">
                            <option key="kgCO2e">KgCO2e</option>
                            <option key="TCO2e">TCO2e</option>
                          </Input>
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="product-number">請選擇產品製造批號</Label>
                          <Select
                            onChange={event => handleProductNumberListsChange(event)}
                            closeMenuOnSelect={true}
                            filterOption={ createFilter(filterConfig) }
                            isMulti
                            components={animatedComponents}
                            value={
                              (selectedProductNumberLists.length === 0 && productNumberLists.length > 1) ? {label: productNumberLists[1]['label'], value: productNumberLists[1]['value']} : selectedProductNumberLists
                            }
                            options={ productNumberLists }
                            placeholder="請選擇產品製造批號"
                            noOptionsMessage={() => '沒有產品製造批號可以選擇！'}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="source-factor">請輸入係數來源</Label>
                          <Input name="source-factor" onChange={ event => setSourceFactor(event.target.value) } type="text" value={sourceFactor || ''} placeholder="請輸入係數來源"/>
                        </FormGroup>
                        <FormGroup style={{ display: (factorOptionLists.length > 1 ? '' : 'none') } }>
                          <Label className="modal-form-label" for="carbon-factor-option">請選擇係數</Label>
                          <Select
                            onChange={event => handleFactorOptionsListsChange(event)}
                            closeMenuOnSelect={true}
                            filterOption={ createFilter(filterConfig) }
                            components={animatedComponents}
                            value={ (Object.keys(currentFactorOption).length === 0) ? factorOptionLists[0] : currentFactorOption }
                            options={ factorOptionLists }
                            placeholder="請選擇係數"
                            noOptionsMessage={() => '沒有係數可以選擇！'}
                          />
                        </FormGroup>
                        <FormGroup>
                          <h4 className="text-info">{ updatedResMessage }</h4>
                          <h5 className="text-danger">{ errorMessage }</h5>
                        </FormGroup>
                      </Form>
                    </ModalBody>
                    <ModalFooter className="modal-footer-center">
                      <Button className="btn-setting" onClick={ handleUpdateErp }>送出</Button>{' '}
                      <Button className="btn-delete" onClick={ erpDataEditorToggle }>取消</Button>
                    </ModalFooter>
                  </Modal>
                  <Modal key="delProductModal" isOpen={deleteErpDataModal} toggle={deleteToggle} className="">
                    <ModalHeader className="modal-title" toggle={deleteToggle}>刪除</ModalHeader>
                      <ModalBody>
                        <Form>
                          <FormGroup>
                            <h5 className="text-danger">確定刪除此產品原料？</h5>
                          </FormGroup>
                          <FormGroup>
                            <h4 className="text-info">{ updatedResMessage }</h4>
                            <h4 className="text-danger">{ errorMessage }</h4>
                          </FormGroup>
                        </Form>
                      </ModalBody>
                      <ModalFooter className="modal-footer-center">
                        <Button className="btn-setting" onClick={ handleDeleteErpData }>確定</Button>{' '}
                        <Button className="btn-cancel" onClick={ deleteToggle }>取消</Button>
                      </ModalFooter>
                  </Modal>
                  </Table>
              </CardBody>
            </Card>
          </Col>
          <Col md="12">
            <Card>
                <CardHeader>
                  <CardTitle tag="h4">其他碳排填入{' '}
                    <Button className="btn-icon-setting" disabled={ buttonDisabled || buttonAddErpDisabled } onClick={nonProcessToggle}><FontAwesomeIcon icon={ faPlus }/>新增設定</Button>{' '}
                    <Button className="btn-icon-setting" disabled={ buttonDisabled || buttonAddErpDisabled } onClick={ emissionFactorToggle }><FontAwesomeIcon icon={ faPlus }/>係數庫查詢</Button>{' '}
                    <span style={{
                      display: (noProcessMessage !== '查無該產品的產品製造編號！') ? 'none' : '',
                    }}  className="text-danger">{ noProcessMessage }</span>
                  </CardTitle>
                  <Modal keyboard={ false } backdrop={ "static" } key="addNonProcessModal" isOpen={addNonProcessModal} toggle={nonProcessToggle} className="">
                  <ModalHeader className="modal-title" toggle={nonProcessToggle}>{ '新增' } <span className="modal-text">{ processName }</span> { '之其他碳排' }</ModalHeader>
                    <ModalBody>
                      <Form>
                          <Label className="modal-form-label" for="category-number">請選擇碳排類別</Label>
                          <Input name="category-number" onChange={event => handleCategoryNumberAndDescriptionChange(event.target.value)} type="select" value={categoryNumber || ''} placeholder="請選擇碳排類別">
                            {
                              carbonCategories.map((item, index) => {
                                if (categoryNumber === '' && index === 0) {
                                  let categoryName = '原料取得';
                                  setCategoryNumber(categoryName);
                                  setCarbonCategoryDescription(carbonCategories[index][categoryName]);
                                }

                                return <option key={'category-number-' + (index + 1)}>{ Object.keys(item)[0] }</option>
                              })
                            }
                          </Input>
                        <FormGroup>
                          <Label className="modal-form-label" for="category-source">請輸入碳排來源名稱</Label>
                          <Input name="category-source" onChange={ event => setCategorySource(event.target.value) } type="text" value={categorySource || ''} placeholder="請輸入碳排來源名稱"/>
                        </FormGroup>
                          <Label className="modal-form-label" for="non-process-type">請選擇碳排量種類</Label>
                          <Input type="select" name="non-process-type" onChange={ event => setNonProcessType(event.target.value) } value={nonProcessType || ''}>
                            {
                              ['總量'].map((item, index) => {
                                if (index === 0 && nonProcessType === '') {
                                  setNonProcessType(item);
                                }
                                return <option key={ 'non-process-type-' + index }>{ item }</option>
                              })
                            }
                          </Input>
                        <FormGroup>
                          <Label className="modal-form-label" for="per-carbon-value">請輸入{ nonProcessType === '總量' ? nonProcessType : '每單元' }碳排量</Label>
                          <Input name="per-carbon-value" onChange={ event => setPerCarbonValue(event.target.value) } type="text" value={perCarbonValue || ''} placeholder="請輸入總量碳排量"/>
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="per-carbon-value-unit">請選擇{ nonProcessType === '總量' ? nonProcessType : '每單元' }碳排量單位</Label>
                          <Input name="per-carbon-value-unit" onChange={ event => setNonProcessCO2eUnit(event.target.value) } type="select" value={nonProcessCO2eUnit || ''} placeholder="請選擇每單元碳排量單位">
                          {
                            ['gCO2e', 'kgCO2e', 'tCO2e'].map((item, key) => {
                              return <option key={'non-process-unit-' + key}>{ item }</option>
                            })
                          }
                          </Input>
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="product-number">請選擇產品製造批號</Label>
                          <Select
                            onChange={event => handleProductNumberListsChange(event)}
                            closeMenuOnSelect={true}
                            filterOption={ createFilter(filterConfig) }
                            isMulti
                            components={animatedComponents}
                            value={
                              (selectedProductNumberLists.length === 0 && productNumberLists.length > 1) ? {label: productNumberLists[1]['label'], value: productNumberLists[1]['value']} : selectedProductNumberLists
                            }
                            options={ productNumberLists }
                            placeholder="請選擇產品製造批號"
                            noOptionsMessage={() => '沒有產品製造批號可以選擇！'}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="source-factor">請輸入係數來源</Label>
                          <Input name="source-factor" onChange={ event => setSourceFactor(event.target.value) } type="text" value={sourceFactor || ''} placeholder="請輸入係數來源"/>
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="non-process-comment">備註<span className="text-danger">(如：可以填入ISO-14064或ISO-14067的證書編號)</span></Label>
                          <Input name="non-process-comment" onChange={ event => setNonProcessComment(event.target.value) } type="text" value={nonProcessComment || ''} placeholder="請輸入備註"/>
                        </FormGroup>
                        {
                          nonProcessCalculatedOptions.length > 1 ? <FormGroup>
                            <Label className="modal-form-label" for="non-process-emission-option">請選擇算好的碳排放量</Label>
                            <Select
                              onChange={event => handleNonProcessCalculatedOptionChange(event)}
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              value={ selectedNonProcessCalculatedOptions }
                              options={ nonProcessCalculatedOptions }
                              placeholder="請選擇算好的碳排放量"
                              noOptionsMessage={() => '沒有產品製造批號可以選擇！'}
                            />
                          </FormGroup> : <></>
                        }
                        <FormGroup>
                          <h4 className="text-info">{ updatedResMessage }</h4>
                          <h5 className="text-danger">{ errorMessage }</h5>
                        </FormGroup>
                      </Form>
                    </ModalBody>
                    <ModalFooter className="modal-footer-center">
                      <Button hidden={ true } id="CarbonCategoryDescriptionPopover" className="btn-setting-add" onClick={ categoryDescriptionToggle }>碳排類別說明</Button>{' '}
                      <Popover placement="top" isOpen={ categoryDescriptionPopoverOpen.popoverOpen } target="CarbonCategoryDescriptionPopover" toggle={ categoryDescriptionToggle }>
                        <PopoverHeader>{ categoryNumber }</PopoverHeader>
                        <PopoverBody>{ carbonCategoryDescription }</PopoverBody>
                      </Popover>
                      <Button className="btn-setting" onClick={ handleAddNonProcess }>新增</Button>{' '}
                      <Button className="btn-cancel" onClick={ nonProcessToggle }>取消</Button>
                    </ModalFooter>
                  </Modal>
                </CardHeader>
                <CardBody>
                <Table>
                  { nonProcessesDataTable.length > 0 ? <><thead className="text-info">
                      <tr>
                        <th className="th-customize">碳排類別</th>
                        <th className="th-customize">碳排來源名稱</th>
                        <th className="th-customize">碳排量</th>
                        <th className="th-customize">碳排量單位</th>
                        <th className="th-customize">碳排量種類</th>
                        <th className="th-customize">備註</th>
                        <th className="th-customize">編輯</th>
                        <th className="th-customize">刪除</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      nonProcessesDataTable.map((item, index) => {
                        return <tr key={ item['process_name'] + index }>
                          <td className="td-customize">{ item['category_number'] }</td>
                          <td className="td-customize">
                            <span style={{ fontWeight: (item['category_source'].length > 7 ? 'bold' : '') }} id={ 'non_process_data' + index } data-categorysource={ item['category_source'] } href="#" target='_blank' rel="noreferrer" >{ item['category_source'].slice(0, 7) }{ item['category_source'].length > 7 ? '...': '' }</span>
                            {
                              item['category_source'].length > 7 ?
                                <ReactTooltip
                                  anchorSelect={ '#non_process_data' + index }
                                  place='right'
                                  variant='dark'
                                  float='false'
                                  content={ item['category_source'] }
                                /> : null
                            }
                          </td>
                          <td className="td-customize">{ item['co2e_per_number'] }</td>
                          <td className="td-customize">{ item['co2e_per_number_unit'] }</td>
                          <td className="td-customize">{ item['non_process_type'] }</td>
                          <td className="td-customize">{ item['co2e_comment'] === 'no_comment' ? '無備註' : item['co2e_comment'] }</td>
                          <td className="td-customize">
                          <Button
                            id={ item['process_name'] + index + 'button' }
                            className="btn-setting"
                            onClick={ nonProcessDataEditorToggle }
                            data-processname={ item['process_name'] }
                            data-categorynumber={ item['category_number'] }
                            data-categorysource={ item['category_source'] }
                            data-co2epernumber={ item['co2e_per_number'] }
                            data-co2epernumberunit={ item['co2e_per_number_unit'] }
                            data-co2ecomment={ item['co2e_comment'] }
                            data-nonprocessid={ item['non_process_id'] }
                            data-nonprocesstype={ item['non_process_type'] }
                            data-sourcefactor={ item['source_factor'] }
                          >
                          編輯
                          </Button>
                        </td>
                        <td className="td-customize">
                          <Button
                            id={ item['process_name'] + index + 'deleted_button' }
                            className="btn-delete"
                            onClick={ deleteNonProcessToggle }
                            data-nonprocessid={ item['non_process_id'] }
                          >
                          <FontAwesomeIcon icon={ faTrashCan } />{ ' ' }
                          刪除
                          </Button>
                        </td>
                        </tr>
                      })
                    }
                    </tbody></> : <></>
                  }
                  <Modal keyboard={ false } backdrop={ "static" } key="nonProcessDataEditorModal" isOpen={ nonProcessDataEditorModal } toggle={ nonProcessDataEditorToggle } className="">
                  <ModalHeader className="modal-title" toggle={ nonProcessDataEditorToggle }>{ '修改' } <span className="modal-text">{ processName }</span> { '其他碳排填入' }</ModalHeader>
                    <ModalBody>
                    <Form>
                          <Label className="modal-form-label" for="category-number">請選擇碳排類別</Label>
                          <Input name="category-number" onChange={event => setCategoryNumber(event.target.value)} type="select" value={categoryNumber || ''} placeholder="請選擇碳排類別">
                            {
                              carbonCategories.map((item, index) => {
                                if (categoryNumber === '' && index === 0) {
                                  let categoryName = '原料取得';
                                  setCategoryNumber(categoryName);
                                  setCarbonCategoryDescription(carbonCategories[index][categoryName]);
                                }

                                return <option key={'category-number-' + (index + 1)}>{ Object.keys(item)[0] }</option>
                              })
                            }
                          </Input>
                        <FormGroup>
                          <Label className="modal-form-label" for="category-source">請輸入碳排來源名稱</Label>
                          <Input name="category-source" onChange={ event => setCategorySource(event.target.value) } type="text" value={categorySource || ''} placeholder="請輸入碳排來源名稱"/>
                        </FormGroup>
                          <Label className="modal-form-label" for="non-process-type">請選擇碳排量種類</Label>
                          <Input type="select" name="non-process-type" onChange={ event => setNonProcessType(event.target.value) } value={nonProcessType || ''}>
                            {
                              ['總量'].map((item, index) => {
                                if (index === 0 && nonProcessType === '') {
                                  setNonProcessType(item);
                                }
                                return <option key={ 'non-process-type-' + index }>{ item }</option>
                              })
                            }
                          </Input>
                        <FormGroup>
                          <Label className="modal-form-label" for="per-carbon-value">請輸入{ nonProcessType }碳排量</Label>
                          <Input name="per-carbon-value" onChange={ event => setPerCarbonValue(event.target.value) } type="text" value={perCarbonValue || ''} placeholder="請輸入每單元碳排量"/>
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="per-carbon-value-unit">請選擇{ nonProcessType }碳排量單位</Label>
                          <Input name="per-carbon-value-unit" onChange={ event => setNonProcessCO2eUnit(event.target.value) } type="select" value={nonProcessCO2eUnit || ''} placeholder="請選擇每單元碳排量單位">
                          {
                            ['gCO2e', 'kgCO2e', 'tCO2e'].map((item, key) => {
                              return <option key={'non-process-unit-' + key}>{ item }</option>
                            })
                          }
                          </Input>
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="product-number">請選擇產品製造批號</Label>
                          <Select
                            onChange={event => handleProductNumberListsChange(event)}
                            closeMenuOnSelect={true}
                            filterOption={ createFilter(filterConfig) }
                            isMulti
                            components={animatedComponents}
                            value={
                              (selectedProductNumberLists.length === 0 && productNumberLists.length > 1) ? {label: productNumberLists[1]['label'], value: productNumberLists[1]['value']} : selectedProductNumberLists
                            }
                            options={ productNumberLists }
                            placeholder="請選擇產品製造批號"
                            noOptionsMessage={() => '沒有產品製造批號可以選擇！'}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="source-factor">請輸入係數來源</Label>
                          <Input name="source-factor" onChange={ event => setSourceFactor(event.target.value) } type="text" value={sourceFactor || ''} placeholder="請輸入係數來源"/>
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="non-process-comment">備註<span className="text-danger">(如：可以填入ISO-14064或ISO-14067的證書編號)</span></Label>
                          <Input name="non-process-comment" onChange={ event => setNonProcessComment(event.target.value) } type="text" value={nonProcessComment || ''} placeholder="請輸入備註"/>
                        </FormGroup>
                        {
                          nonProcessCalculatedOptions.length > 1 ? <FormGroup>
                            <Label className="modal-form-label" for="non-process-emission-option">請選擇算好的碳排放量</Label>
                            <Select
                              onChange={event => handleNonProcessCalculatedOptionChange(event)}
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              value={ selectedNonProcessCalculatedOptions }
                              options={ nonProcessCalculatedOptions }
                              placeholder="請選擇算好的碳排放量"
                              noOptionsMessage={() => '沒有產品製造批號可以選擇！'}
                            />
                          </FormGroup> : <></>
                        }
                        <FormGroup>
                          <h4 className="text-info">{ updatedResMessage }</h4>
                          <h5 className="text-danger">{ errorMessage }</h5>
                        </FormGroup>
                      </Form>
                    </ModalBody>
                    <ModalFooter className="modal-footer-center">
                      <Button hidden={ true } id="CarbonCategoryDescriptionPopover" color="danger" onClick={ categoryDescriptionToggle }>碳排類別說明</Button>{' '}
                      <Popover placement="top" isOpen={ categoryDescriptionPopoverOpen.popoverOpen } target="CarbonCategoryDescriptionPopover" toggle={ categoryDescriptionToggle }>
                        <PopoverHeader>{ categoryNumber !== undefined ? categoryNumber : '' }</PopoverHeader>
                        <PopoverBody>{ carbonCategoryDescription }</PopoverBody>
                      </Popover>
                      <Button className="btn-setting" onClick={ handleUpdateNonProcess }>送出</Button>{' '}
                      <Button className="btn-cancel" onClick={ nonProcessDataEditorToggle }>取消</Button>
                    </ModalFooter>
                  </Modal>
                  <Modal key="delNonProcessModal" isOpen={deleteNonProcessDataModal} toggle={deleteNonProcessToggle} className="">
                    <ModalHeader className="modal-title" toggle={deleteNonProcessToggle}>刪除</ModalHeader>
                      <ModalBody>
                        <Form>
                          <FormGroup>
                            <h5 className="text-danger">確定刪除此產品之其他碳排？</h5>
                          </FormGroup>
                          <FormGroup>
                            <h4 className="text-info">{ updatedResMessage }</h4>
                            <h4 className="text-danger">{ errorMessage }</h4>
                          </FormGroup>
                        </Form>
                      </ModalBody>
                      <ModalFooter className="modal-footer-center">
                        <Button className="btn-setting" onClick={ handleDeleteNonProcessData }>確定</Button>{' '}
                        <Button className="btn-cancel" onClick={ deleteNonProcessToggle }>取消</Button>
                      </ModalFooter>
                  </Modal>
                  </Table>
                </CardBody>
            </Card>
          </Col>
        </Row>
      }
      </div>
    </>
  );
}

export default NonProcessDashboard;
