import Dashboard from "views/Dashboard.js";
import DataSourceDashboard from "views/DataSourceDashboard.js";
import MachineConfigDashboard from "views/MachineConfigDashboard.js";
import ProcessDashboard from "views/ProcessDashboard.js";
import NonProcessDashboard from "views/NonProcessDashboard.js";
import ProcessCarbonDashboard from "views/ProcessCarbonDashboard.js";
import OverviewProductCarbonDashboard from "views/OverviewProductCarbonDashboard.js";
import MesDataSetting from "views/MesDataSetting.js";
import MultipleProductCompare from "views/MultipleProductCompare.js";
import MultipleBatchCompare from "views/MultipleBatchCompare.js";
import CostCalculator from "views/CostCalculator.js";
import ProduceTrending from "views/ProduceTrending.js";
import DeviceManagement from "views/DeviceManagement.js";
import TestDeviceData from "views/TestDeviceData.js";
import DeviceCarbon from "views/DeviceCarbon";


var routes = [
  {
    path: "/dashboard",
    name: "感測器總覽",
    icon: "nc-icon nc-alert-circle-i",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/data-source",
    name: "資料來源管理",
    icon: "nc-icon nc-single-copy-04",
    component: DataSourceDashboard,
    layout: "/admin",
  },
  {
    path: "/machine-config",
    name: "機台串接與資料傳輸設定",
    icon: "nc-icon nc-settings-gear-65",
    component: MachineConfigDashboard,
    layout: "/admin",
  },
  {
    path: "/device",
    name: "機台與感測器裝置管理",
    icon: "nc-icon nc-settings-gear-65",
    component: DeviceManagement,
    layout: "/admin",
  },
  {
    path: "/process",
    name: "產品製程設定",
    icon: "nc-icon nc-settings-gear-65",
    component: ProcessDashboard,
    layout: "/admin",
  },
  {
    path: "/non-process",
    name: "原料與其他碳排資訊填入",
    icon: "nc-icon nc-ruler-pencil",
    component: NonProcessDashboard,
    layout: "/admin",
  },
  {
    path: "/mes",
    name: "產品製造資訊填入",
    icon: "nc-icon nc-ruler-pencil",
    component: MesDataSetting,
    layout: "/admin",
  },
  {
    path: "/produce-carbon",
    name: "產品碳排計算",
    icon: "nc-icon nc-settings",
    component: ProcessCarbonDashboard,
    layout: "/admin",
  },
  {
    path: "/multiple-product-compare",
    name: "多項產品碳排比較",
    icon: "nc-icon nc-paper",
    component: MultipleProductCompare,
    layout: "/admin",
  },
  {
    path: "/multiple-batch-compare",
    name: "產品批號碳排比較",
    icon: "nc-icon nc-bullet-list-67",
    component: MultipleBatchCompare,
    layout: "/admin",
  },
  {
    path: "/carbon-calculator",
    name: "碳排成本計算",
    icon: "nc-icon nc-ruler-pencil",
    component: CostCalculator,
    layout: "/admin",
  },
  {
    path: "/produce-trending",
    name: "產品製程碳排趨勢",
    icon: "nc-icon nc-sound-wave",
    component: ProduceTrending,
    layout: "/admin",
  },
  {
    path: "/product-carbon",
    name: "產品碳排總覽",
    icon: "nc-icon nc-settings",
    component: OverviewProductCarbonDashboard,
    layout: "/admin",
  },
  {
    path: "/test-device",
    name: "感測器裝置測試",
    icon: "nc-icon nc-settings-gear-65",
    component: TestDeviceData,
    layout: "/admin",
  },
  {
    path: "/carbon-device",
    name: "感測器裝置碳排",
    icon: "nc-icon nc-settings-gear-65",
    component: DeviceCarbon,
    layout: "/admin",
  },
];

export default routes;
