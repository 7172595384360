import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import AdminLayout from "layouts/Admin.js";
import LoginLayout from "layouts/Login.js";

import 'assets/css/table.css';
import 'assets/css/cards.css';
import 'assets/css/button.css';
import 'assets/css/modal.css';
import 'assets/css/sidebar.css';
import 'assets/css/fade.css';
import 'assets/css/flow.css';


ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/">
        <Redirect to="/admin/login" />
      </Route>
      <Route exact path="/paper-dashboard-react">
        <Redirect to="/admin/login" />
      </Route>
      <Route path="/admin/product-carbon" render={(props) => <AdminLayout {...props} />} />
      <Route path="/admin/dashboard" render={(props) => <AdminLayout {...props} />} />
      <Route path="/admin/login" render={(props) => <LoginLayout {...props} />} />
      <Route path="/admin/data-source" render={(props) => <AdminLayout {...props} />} />
      <Route path="/admin/machine-config" render={(props) => <AdminLayout {...props} />} />
      <Route path="/admin/device" render={(props) => <AdminLayout {...props} />} />
      <Route path="/admin/process" render={(props) => <AdminLayout {...props} />} />
      <Route path="/admin/non-process" render={(props) => <AdminLayout {...props} />} />
      <Route path="/admin/mes" render={(props) => <AdminLayout {...props} />} />
      <Route path="/admin/produce-carbon" render={(props) => <AdminLayout {...props} />} />
      <Route path="/admin/multiple-product-compare" render={(props) => <AdminLayout {...props} />} />
      <Route path="/admin/multiple-batch-compare" render={(props) => <AdminLayout {...props} />} />
      <Route path="/admin/carbon-calculator" render={(props) => <AdminLayout {...props} />} />
      <Route path="/admin/produce-trending" render={(props) => <AdminLayout {...props} />} />
      <Route path="/admin/test-device" render={(props) => <AdminLayout {...props} />} />
      <Route path="/admin/carbon-device" render={(props) => <AdminLayout {...props} />} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
