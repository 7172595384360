import React, { useEffect, useState } from "react";
import {
  Redirect,
} from 'react-router';
import { Bar, Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import "hw-chartjs-plugin-colorschemes";
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Spinner,
  CardHeader,
} from "reactstrap";
import { verifiedLoginWithSetState } from 'ajax/LoginRequest';
import { getOverviewTotalCarbon, getOverviewProductBatchInfoCarbon } from 'ajax/OverviewDashboard';


function OverviewProductCarbonDashboard() {
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [loadingDataSpinner, setLoadingDataSpinner] = useState(true);

  const [productCarbonBarChartData, setProductCarbonBarChartData] = useState({});
  const [overviewTotalData, setOverviewTotalData] = useState({});
  const [overviewTotalCarbon, setOverviewTotalCarbon] = useState(0);
  const [pieChartOptions,] = useState({
    maintainAspectRatio: true,
    legend: {
      display: true,
    },
    plugins: {
      datalabels: {
        anchor: 'center',
        display: (context) => {
          let dataset = context.dataset;
          let count = dataset.data.length;
          let value = dataset.data[context.dataIndex];

          return value > parseInt(count / 2);
        },
        color: 'white',
        font: (context) => {
          let w = context.chart.width;
          return {
            size: w < 512 ? 12 : 14,
            weight: 'bold',
          };
        },
        formatter: (value, context) => {
          return context.chart.data.labels[context.dataIndex];
        },
      },
      colorschemes: {
        scheme: 'tableau.Tableau20',
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          let dataIndex = tooltipItem.index;
          return data.labels[dataIndex] + ': ' + String(Number(data.datasets[0].data[dataIndex]).toFixed(4)) + '%';
        },
      },
    },
  });


  useEffect(() => {
    async function verifiedLogin() {
      await verifiedLoginWithSetState(setRedirectToLogin);
    }

    verifiedLogin();

    async function generateTotalOverview() {
      if (['rithai', 'liontool'].includes(localStorage.getItem('auth_account')) === false) {
        setLoadingDataSpinner(false);

        return false;
      }

      setLoadingDataSpinner(true);

      let responseOverviewTotalData = await getOverviewTotalCarbon();
      let producePiePercentData = responseOverviewTotalData['produce_pie_percent'];
      let carbonSourcePiePercentData = responseOverviewTotalData['carbon_source_pie_percent'];

      const producePieData = {
        labels: Object.keys(producePiePercentData),
        datasets: [{
          data: Object.values(producePiePercentData),
        }],
      };

      const carbonSourcePieData = {
        labels: ['製程設備', '原料', '其他'],
        datasets: [{
          data: Object.values(carbonSourcePiePercentData),
        }],
      };

      let responseOverviewProductBatchData = await getOverviewProductBatchInfoCarbon();
      let sumOfTotalCarbonValue = 0;
      for (let index=0; index<responseOverviewProductBatchData.length; index++) {
        sumOfTotalCarbonValue += responseOverviewProductBatchData[index]['total_carbon_value'];
      }

      setOverviewTotalData(responseOverviewTotalData);
      setOverviewTotalCarbon(sumOfTotalCarbonValue);

      setProductCarbonBarChartData(responseOverviewProductBatchData);

      setLoadingDataSpinner(false);
    }

    generateTotalOverview();
  }, []);

  if (redirectToLogin) {
    return <Redirect to='/admin/login'/>
  }

  return (
    <>
      <div className="content">
      {
        loadingDataSpinner === true ? <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem'}}/> :
        (
          (localStorage.getItem('auth_account') === 'liontool' || localStorage.getItem('auth_account') === 'rithai') ?
          <>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="4" xs="7">
                      <div className="numbers">
                        <CardTitle tag="p" className="spinner-color">
                          { Intl.NumberFormat(undefined, { minimumFractionDigits: 4 }).format((overviewTotalCarbon || '0')) }
                          {' '}<span className="category">TCO2e</span>
                        </CardTitle>
                      </div>
                      <h5>總碳排量</h5>
                      <hr />
                    </Col>
                    <Col md="4" xs="7">
                      <div className="numbers">
                        <CardTitle tag="p" className="spinner-color">
                          { Intl.NumberFormat(undefined, { minimumFractionDigits: 4 }).format(overviewTotalData['total_produce_carbon'] || '0') }
                          {' '}<span className="category">TCO2e</span>
                        </CardTitle>
                      </div>
                      <h5>生產過程總排量</h5>
                      <hr />
                    </Col>
                    <Col md="4" xs="7">
                      <div className="numbers">
                        <CardTitle tag="p" className="spinner-color">
                          { Intl.NumberFormat(undefined, { minimumFractionDigits: 4 }).format(overviewTotalData['total_ingredient_carbon'] || '0') }
                          {' '}<span className="category">TCO2e</span>
                        </CardTitle>
                      </div>
                      <h5>原料碳含量總量</h5>
                      <hr />
                    </Col>
                    <Col md="4" xs="7">
                      <div className="numbers">
                        <CardTitle tag="p" className="spinner-color">
                          { Intl.NumberFormat(undefined, { minimumFractionDigits: 4 }).format(overviewTotalData['total_product_weight'] || '0') }
                          {' '}<span className="category">T(公噸)</span>
                        </CardTitle>
                      </div>
                      <h5>生產產品數量</h5>
                      <hr />
                    </Col>
                    <Col md="4" xs="7">
                      <div className="numbers">
                        <CardTitle tag="p" className="spinner-color">
                          { Intl.NumberFormat(undefined, { minimumFractionDigits: 4 }).format(overviewTotalData['total_carbon_cost_price'] || '0') }
                          {' '}<span className="category">元(台幣)</span>
                        </CardTitle>
                      </div>
                      <h5>預計總碳費</h5>
                      <hr />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
            {
              productCarbonBarChartData.map((item, index) => {
                return <Row key={ 'product-carbon-batch-info-' + index }>
                <Col md="4">
                  <Card>
                    <CardBody style={{height: (window.innerHeight) + 'px'}}>
                      <Row>
                        <Col md="12" xs="7">
                          <CardTitle tag="p" className="spinner-color">
                            { item['product_name'] }
                          </CardTitle>
                          <h5>產品名稱</h5>
                          <hr />
                        </Col>
                        <Col md="12" xs="7">
                          <CardTitle tag="p" className="spinner-color">
                            { '(' + item['product_number'] + ')' }
                          </CardTitle>
                          <h5>批號</h5>
                          <hr />
                        </Col>
                        <Col md="12" xs="7">
                          <div className="numbers">
                            <CardTitle tag="p" className="spinner-color">
                              { Intl.NumberFormat(undefined, { minimumFractionDigits: 4 }).format(item['produce_number'] || '0') }
                            </CardTitle>
                          </div>
                          <h5>產量</h5>
                          <hr />
                        </Col>
                        <Col md="12" xs="7">
                          <div className="numbers">
                            <CardTitle tag="p" className="spinner-color">
                              { Intl.NumberFormat(undefined, { minimumFractionDigits: 4 }).format(item['total_carbon_value'] || '0') }
                              {' '}<span className="category">TCO2e</span>
                            </CardTitle>
                          </div>
                          <h5>碳排</h5>
                          <hr />
                        </Col>
                        <Col md="12" xs="7">
                          <div className="numbers">
                            <CardTitle tag="p" className="spinner-color">
                              { Intl.NumberFormat(undefined, { minimumFractionDigits: 4 }).format(item['total_carbon_price'] || '0') }
                              {' '}<span className="category">NTD</span>
                            </CardTitle>
                          </div>
                          <h5>碳費</h5>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card>
                    <CardBody style={{height: (window.innerHeight) + 'px'}}>
                      <CardHeader>
                        <h5 className="text-center">製程別碳排比率</h5>
                      </CardHeader>
                      <Pie
                        plugins={[ChartDataLabels]}
                        data={
                          {
                            labels: Object.keys(item['produce_carbon_info_percent']),
                            datasets: [{
                              data: Object.values(item['produce_carbon_info_percent']),
                            }],
                          }
                        }
                        height={600}
                        options={ pieChartOptions }
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card>
                    <CardBody style={{height: (window.innerHeight) + 'px'}}>
                      <CardHeader>
                        <h5 className="text-center">碳排放源別比率</h5>
                      </CardHeader>
                      <Pie
                        plugins={[ChartDataLabels]}
                        data={
                          {
                            labels: ['製程設備', '原料', '其他'],
                            datasets: [{
                              data: Object.values(item['source_carbon_info_percent']),
                            }],
                          }
                        }
                        height={600}
                        options={ pieChartOptions }
                      />
                    </CardBody>
                  </Card>
                </Col>
                </Row>
              })
            }
          </>
          : <h2 className="text-danger">尚未支援此功能！請點選「產品碳排計算」</h2>
        )
      }
      </div>
    </>
  );
}

export default OverviewProductCarbonDashboard;
