import Axios from 'axios';


var mesDataApiPath = '/mes';
var mesApiPath = '/mes_api';
var mesApiTestPath = '/mes_api_test';

export async function createMesData(requestPayload, setUpdatedResMessage, setErrorMessage, setModal, modal) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    await Axios.post(process.env.REACT_APP_API_ADDRESS + mesDataApiPath, requestPayload, requestConfig).then((response) => {
        let responseData = response.data['affected_count'];
        if (typeof responseData === 'number' && responseData > 0) {
            setUpdatedResMessage('新增成功！');
            setTimeout(() => {
                setModal(!modal);
            }, 2000);
        } else {
            setErrorMessage('新增失敗，資料已經存在！');
            console.log(responseData);
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function getMesData(setOriginalMesDataTable, setMesDataTable, setMesApiDataTablePagination, page=1) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    let size = 50;
    let getMesDataUrl = process.env.REACT_APP_API_ADDRESS + mesDataApiPath;
    getMesDataUrl += '?page=' + page + '&size=' + size;

    await Axios.get(getMesDataUrl, requestConfig).then((response) => {
        let responseData = response.data['items'];
        let totalCount = response.data['total'];
        let perRecordSize = response.data['size'];
        let totalPage = Math.ceil(Number(totalCount) / Number(perRecordSize));
        let pages = [];
        for (let index=0; index<totalPage; index++) {
            pages.push(index + 1);
        }

        setMesDataTable(responseData);
        setOriginalMesDataTable(responseData);
        setMesApiDataTablePagination(pages);
    }).catch((error) => {
        console.log(error);
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function updateMesData(requestPayload, setUpdatedResMessage, setErrorMessage, setMesDataEditorModal, mesDataEditorModal) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    await Axios.put(process.env.REACT_APP_API_ADDRESS + mesDataApiPath, requestPayload, requestConfig).then((response) => {
        let responseData = response.data['affected_count'];
        if (typeof responseData === 'number' && responseData > 0) {
            setUpdatedResMessage('更新成功！');
            setTimeout(() => {
                setMesDataEditorModal(!mesDataEditorModal);
            }, 2000);
        } else {
            setErrorMessage('更新失敗，資料已經存在！');
            console.log(responseData);
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function deleteMesData(mesId, setUpdatedResMessage, setErrorMessage, setDeleteMesDataModal, deleteMesDataModal) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    let requestUrl = process.env.REACT_APP_API_ADDRESS + mesDataApiPath + '/' + mesId;

    await Axios.delete(requestUrl, requestConfig).then((response) => {
        let responseData = response.data['affected_count'];
        if (typeof responseData === 'number' && responseData > 0) {
            setUpdatedResMessage('刪除成功！');
            setTimeout(() => {
                setDeleteMesDataModal(!deleteMesDataModal);
            }, 2000);
        } else {
            setErrorMessage('刪除資料失敗！');
            console.log(response.data);
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function createMesApiData(requestPayload, setUpdatedResMessage, setErrorMessage, setModal, modal) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    await Axios.post(process.env.REACT_APP_API_ADDRESS + mesApiPath, requestPayload, requestConfig).then((response) => {
        let responseData = response.data['affected_count'];
        if (typeof responseData === 'number' && responseData > 0) {
            setUpdatedResMessage('新增成功！');
            setTimeout(() => {
                setModal(!modal);
            }, 2000);
        } else {
            setErrorMessage('新增失敗，資料已經存在！');
            console.log(responseData);
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function getMesApiData(setMesApiDataTable) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    let getMesDataApiUrl = process.env.REACT_APP_API_ADDRESS + mesApiPath;

    await Axios.get(getMesDataApiUrl, requestConfig).then((response) => {
        let responseData = [];
        for (let index=0; index<response.data.length; index++) {
            responseData.push({
                'mes_api_id': response.data[index][0],
                'url_endpoint': response.data[index][1],
                'token': response.data[index][2],
                'mapped_process_name': response.data[index][3],
                'mapped_manufacture_number': response.data[index][4],
                'mapped_produce_number': response.data[index][5],
                'mapped_produce_start_datetime': response.data[index][6],
                'mapped_produce_end_datetime': response.data[index][7],
                'mapped_device_name': response.data[index][8],
            });
        }
        setMesApiDataTable(responseData);
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function updateMesApiData(requestPayload, setUpdatedResMessage, setErrorMessage, setMesApiDataEditorModal, mesApiDataEditorModal) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    await Axios.put(process.env.REACT_APP_API_ADDRESS + mesApiPath, requestPayload, requestConfig).then((response) => {
        let responseData = response.data['affected_count'];
        if (typeof responseData === 'number' && responseData > 0) {
            setUpdatedResMessage('更新成功！');
            setTimeout(() => {
                setMesApiDataEditorModal(!mesApiDataEditorModal);
            }, 2000);
        } else {
            setErrorMessage('更新失敗，資料已經存在！');
            console.log(responseData);
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function deleteMesApiData(mesApiId, setUpdatedResMessage, setErrorMessage, setDeleteMesApiDataModal, deleteMesApiDataModal) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    let requestUrl = process.env.REACT_APP_API_ADDRESS + mesApiPath + '/' + mesApiId;

    await Axios.delete(requestUrl, requestConfig).then((response) => {
        let responseData = response.data['affected_count'];
        if (typeof responseData === 'number' && responseData > 0) {
            setUpdatedResMessage('刪除成功！');
            setTimeout(() => {
                setDeleteMesApiDataModal(!deleteMesApiDataModal);
            }, 2000);
        } else {
            setErrorMessage('刪除資料失敗！');
            console.log(response.data);
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function testMesApiData(requestPayload, setUpdatedResMessage, setErrorMessage) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    await Axios.post(process.env.REACT_APP_API_ADDRESS + mesApiTestPath, requestPayload, requestConfig).then((response) => {
        let responseData = response.data['test_result'];
        if (responseData === '') {
            setUpdatedResMessage('測試成功！');
        } else {
            setErrorMessage('測試失敗： ' + responseData);
            console.log(responseData);
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

const MesDataHandler = {
    createMesData,
    getMesData,
    updateMesData,
    deleteMesData,
    createMesApiData,
    getMesApiData,
    updateMesApiData,
    deleteMesApiData,
    testMesApiData,
};

export default MesDataHandler;
