import Axios from 'axios';


var logoutApiPath = '/logout';

export function requestLogout(setRedirectToLogin) {
    let requestPayload = {
        'token': localStorage.getItem('auth_session'),
        'X-Account': localStorage.getItem('auth_account'),
    };

    Axios.post(process.env.REACT_APP_API_ADDRESS + logoutApiPath, requestPayload).then((response) => {
        let responseJsonString = JSON.stringify(response.data, null, 2);
        if (responseJsonString.includes('successful')) {
            localStorage.removeItem('auth_session');
            setRedirectToLogin(true);
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

const Logout = {
    requestLogout,
};

export default Logout;
