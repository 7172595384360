import Axios from 'axios';


var processApiPath = '/processes';
var erpDataApiPath = '/erp_data';
var processProductApiPath = '/process_product';
var nonProcessDataApiPath = '/non_process';
var factorQueryApiPath = '/factor_query';
var emissionListsApiPath = '/emission_form';
var gwpEmissionApiPath = '/gwp_for_emission';
var equipmentFactorApiPath = '/equipment_factor_lists';
var fridgeGwpApiPath = '/fridge_gwp_lists';

export async function getFactorQuery(factorKeyword) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    let responseData = await Axios.get(process.env.REACT_APP_API_ADDRESS + factorQueryApiPath + '/' + factorKeyword, requestConfig).then((response) => {
        let responseData = [];
        for (let index=0; index<response.data.length; index++) {
            responseData.push({
                'ch_name': response.data[index][0],
                'co2e_unit': response.data[index][1],
                'co2e_value': response.data[index][2],
                'checked_date_range': response.data[index][3],
                'lifecycle_boundary': response.data[index][4],
                'produce_area_name': response.data[index][5],
            });
        }

        return responseData;
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });

    return responseData;
};

export async function getGwpForEmission() {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    let responseData = await Axios.get(process.env.REACT_APP_API_ADDRESS + gwpEmissionApiPath, requestConfig).then((response) => {
        return response.data;
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });

    return responseData;
};

export async function getFridgeFactorLists() {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    let responseData = await Axios.get(process.env.REACT_APP_API_ADDRESS + equipmentFactorApiPath, requestConfig).then((response) => {
        return response.data;
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });

    return responseData;
};

export async function getFridgeGwpLists() {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    let responseData = await Axios.get(process.env.REACT_APP_API_ADDRESS + fridgeGwpApiPath, requestConfig).then((response) => {
        return response.data;
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });

    return responseData;
};

export async function getEmissionLists(emissionForm) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    let responseData = await Axios.get(process.env.REACT_APP_API_ADDRESS + emissionListsApiPath + '/' + emissionForm, requestConfig).then((response) => {
        return response.data;
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });

    return responseData;
};

export async function createErpData(requestPayload, setUpdatedResMessage, setErrorMessage, setModal, modal) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    await Axios.post(process.env.REACT_APP_API_ADDRESS + erpDataApiPath, requestPayload, requestConfig).then((response) => {
        let responseData = response.data['affected_count'];
        if (typeof responseData === 'number' && responseData > 0) {
            setUpdatedResMessage('新增成功！');
            setTimeout(() => {
                setModal(!modal);
            }, 2000);
        } else {
            setErrorMessage('新增失敗，資料已經存在！');
            console.log(responseData);
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function getErpDataForMes(setProductLists, setNoProcessMessage) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    let getProductUrl = process.env.REACT_APP_API_ADDRESS + processApiPath;

    let productLists = await Axios.get(getProductUrl, requestConfig).then((response) => {
        let productLists = [];
        for (let index=0; index<response.data.length; index++) {
            productLists.push({
                'process_name': response.data[index][0],
            });
        }
        setProductLists(productLists);

        return productLists;
    }).catch((error) => {
        console.log(error);
    });

    if (productLists.length <= 0) {
        setNoProcessMessage('查無產品，請先到『產品製程設定』進行設定！');
    }

    return productLists;
};

export async function getProductNumberListsByProductName(productName, setProductNumberLists) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    let getProductNumberUrl = process.env.REACT_APP_API_ADDRESS + processProductApiPath + '/' + productName;
    let productNumberLists = await Axios.get(getProductNumberUrl, requestConfig).then((response) => {
        let productNumberLists = [{
            'label': '全選',
            'value': '全選',
        }];
        for (let index=0; index<response.data.length; index++) {
            productNumberLists.push({
                'label': response.data[index][0],
                'value': response.data[index][0],
            });
        }
        setProductNumberLists(productNumberLists);

        return productNumberLists;
    }).catch((error) => {
        console.log(error);
    });

    return productNumberLists;
}

export async function getErpData(setProcessesLists, setErpDataTable, processName, setNonProcessesDataTable) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    let getProcessesUrl = process.env.REACT_APP_API_ADDRESS + processApiPath;

    let processesLists = await Axios.get(getProcessesUrl, requestConfig).then((response) => {
        let processesLists = [];
        for (let index=0; index<response.data.length; index++) {
            processesLists.push({
                'process_name': response.data[index][0],
            });
        }
        setProcessesLists(processesLists);

        return processesLists;
    }).catch((error) => {
        console.log(error);
    });

    if (processesLists.length === 0) {
        return false;
    }

    if (processName === '') {
        processName = processesLists[0]['process_name'];
    }

    await getNonProcessData(setNonProcessesDataTable, processName);

    let getErpDataApiUrl = process.env.REACT_APP_API_ADDRESS + erpDataApiPath + '/' + processName;

    await Axios.get(getErpDataApiUrl, requestConfig).then((response) => {
        let responseData = [];
        for (let index=0; index<response.data.length; index++) {
            responseData.push({
                'process_name': response.data[index][0],
                'erp_number': response.data[index][1],
                'erp_name': response.data[index][2],
                'erp_amount': response.data[index][3],
                'erp_unit': response.data[index][4],
                'erp_co2e': response.data[index][5],
                'erp_co2e_unit': response.data[index][6],
                'erp_id': response.data[index][7],
                'product_number': response.data[index][8],
                'source_factor': response.data[index][9],
            });
        }
        setErpDataTable(responseData);
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });

    return processName;
};

export async function updateErpData(requestPayload, setUpdatedResMessage, setErrorMessage, setErpDataEditorModal, erpDataEditorModal) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    await Axios.put(process.env.REACT_APP_API_ADDRESS + erpDataApiPath, requestPayload, requestConfig).then((response) => {
        let responseData = response.data['affected_count'];
        if (typeof responseData === 'number' && responseData > 0) {
            setUpdatedResMessage('更新成功！');
            setTimeout(() => {
                setErpDataEditorModal(!erpDataEditorModal);
            }, 2000);
        } else {
            setErrorMessage('更新失敗，資料已經存在！');
            console.log(responseData);
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function deleteErpData(erpId, setUpdatedResMessage, setErrorMessage, setDeleteErpDataModal, deleteErpDataModal) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    let requestUrl = process.env.REACT_APP_API_ADDRESS + erpDataApiPath + '/' + erpId;

    await Axios.delete(requestUrl, requestConfig).then((response) => {
        let responseData = response.data['affected_count'];
        if (typeof responseData === 'number' && responseData > 0) {
            setUpdatedResMessage('刪除成功！');
            setTimeout(() => {
                setDeleteErpDataModal(!deleteErpDataModal);
            }, 2000);
        } else {
            setErrorMessage('刪除資料失敗！');
            console.log(response.data);
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function createNonProcessData(requestPayload, setUpdatedResMessage, setErrorMessage, setModal, modal) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    await Axios.post(process.env.REACT_APP_API_ADDRESS + nonProcessDataApiPath, requestPayload, requestConfig).then((response) => {
        let responseData = response.data['affected_count'];
        if (typeof responseData === 'number' && responseData > 0) {
            setUpdatedResMessage('新增成功！');
            setTimeout(() => {
                setModal(!modal);
            }, 2000);
        } else {
            setErrorMessage('新增失敗，資料已經存在！');
            console.log(responseData);
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function getNonProcessData(setNonProcessesDataTable, processName) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    let getNonProcessDataApiUrl = process.env.REACT_APP_API_ADDRESS + nonProcessDataApiPath + '/' + processName;

    await Axios.get(getNonProcessDataApiUrl, requestConfig).then((response) => {
        let responseData = [];
        for (let index=0; index<response.data.length; index++) {
            responseData.push({
                'process_name': response.data[index][0],
                'category_number': response.data[index][1],
                'category_source': response.data[index][2],
                'co2e_per_number': response.data[index][3],
                'co2e_per_number_unit': response.data[index][4],
                'co2e_comment': response.data[index][5],
                'non_process_id': response.data[index][6],
                'non_process_type': response.data[index][7],
                'source_factor': response.data[index][9],
            });
        }
        setNonProcessesDataTable(responseData);
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function updateNonProcessData(requestPayload, setUpdatedResMessage, setErrorMessage, setNonProcessDataEditorModal, nonProcessDataEditorModal) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    await Axios.put(process.env.REACT_APP_API_ADDRESS + nonProcessDataApiPath, requestPayload, requestConfig).then((response) => {
        let responseData = response.data['affected_count'];
        if (typeof responseData === 'number' && responseData > 0) {
            setUpdatedResMessage('更新成功！');
            setTimeout(() => {
                setNonProcessDataEditorModal(!nonProcessDataEditorModal);
            }, 2000);
        } else {
            setErrorMessage('更新失敗，資料已經存在！');
            console.log(responseData);
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function deleteNonProcessData(nonProcessId, setUpdatedResMessage, setErrorMessage, setDeleteNonProcessDataModal, deleteNonProcessDataModal) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    let requestUrl = process.env.REACT_APP_API_ADDRESS + nonProcessDataApiPath + '/' + nonProcessId;

    await Axios.delete(requestUrl, requestConfig).then((response) => {
        let responseData = response.data['affected_count'];
        if (typeof responseData === 'number' && responseData > 0) {
            setUpdatedResMessage('刪除成功！');
            setTimeout(() => {
                setDeleteNonProcessDataModal(!deleteNonProcessDataModal);
            }, 2000);
        } else {
            setErrorMessage('刪除資料失敗！');
            console.log(response.data);
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

const NonProcess = {
    createErpData,
    getErpData,
    getErpDataForMes,
    updateErpData,
    deleteErpData,
    createNonProcessData,
    getNonProcessData,
    updateNonProcessData,
    deleteNonProcessData,
    getFactorQuery,
    getGwpForEmission,
    getEmissionLists,
    getFridgeFactorLists,
    getFridgeGwpLists,
};

export default NonProcess;
