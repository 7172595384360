import React, { useEffect, useState } from "react";
import {
  Redirect,
} from 'react-router';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { verifiedLoginWithSetState } from 'ajax/LoginRequest';
import {
  createProduct,
  copyProduct,
  getProduct,
  getProductByName,
  updateProduct,
  updateStation,
  deleteProduct,
  deleteStationById,
  getSpecificProcess,
} from "ajax/Product";
import { getMachineConfig } from "ajax/MachineConfig";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";


function ProcessDashboard() {
  const animatedComponents = makeAnimated();
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [processSettingTables, setProcessSettingTables] = useState([]);
  const [addProcessModal, setAddProcessModal] = useState(false);
  const [updateProcessModal, setUpdateProcessModal] = useState(false);
  const [deleteProcessModal, setDeleteProcessModal] = useState(false);
  const [copyProcessModal, setCopyProcessModal] = useState(false);
  const [updatedResMessage, setUpdatedResMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [batchNumber, setBatchNumber] = useState(1);
  const [newBatchNumber, setNewBatchNumber] = useState(1);
  const [productWeight, setProductWeight] = useState(1);
  const [produceNumber, setProduceNumber] = useState(1);
  const [selectedBatchNumber, setSelectedBatchNumber] = useState({});
  const [batchNumberList, setBatchNumberList] = useState([{'label': 1, 'value': 1}]);
  const [productName, setProductName] = useState('');
  const [originalProductName, setOriginalProductName] = useState('');
  const [processInputGroup, setProcessInputGroup] = useState([
    {
      'process': '',
      'station_id': '',
      'machine_config': [],
    },
  ]);
  const [originalStationIds, setOriginalStationIds] = useState([]);
  const [machineConfigTables, setMachineConfigTables] = useState([]);
  const [machineConfigOptions, setMachineConfigOptions] = useState([]);
  const [machineConfigOption, setMachineConfigOption] = useState([]);
  const [defaultMachineConfig, setDefaultMachineConfig] = useState('');
  const [copiedRequestPayload, setCopiedRequestPayload] = useState({});
  const [loadingSpinner, setLoadingSpinner] = useState('none');
  const [processingSpinner, setProcessingSpinner] = useState('none');
  const [statusNumber, setStatusNumber] = useState(0);


  const handleProcessInput = (e) => {
    if (e.target.dataset.action === 'deleteProcess') {
      return false;
    }

    let processInputGroups = [...processInputGroup];
    processInputGroups.push({'process': '', 'station_id': '', 'machine_config': []});
    setProcessInputGroup(processInputGroups);

    let theMachineConfigOption = [...machineConfigOption];
    theMachineConfigOption.push([]);
    setMachineConfigOption(theMachineConfigOption);
  };

  const handleBatchNumberListsChange = async (theSelectedBatchNumber) => {
    setProcessingSpinner('inline-block');

    setProcessInputGroup([{'process': '', 'station_id': '', 'machine_config': []}]);

    localStorage.setItem('batch_number', theSelectedBatchNumber['value']);

    let theMachineConfigOptions = [];
    let theMachineConfig = await getMachineConfig(setMachineConfigTables);
    for (let index=0; index<theMachineConfig.length; index++) {
      if (theMachineConfig[index]['config_file_name'] === 'uuid.txt') {
        let configFileContents = theMachineConfig[index]['config_file_content'].split('\n');

        for (let configIndex=0; configIndex<configFileContents.length; configIndex++) {
          if (configFileContents[configIndex].length > 0) {
            theMachineConfigOptions.push({
              label: (configFileContents[configIndex] + ' (uuid.txt)'),
              value: configFileContents[configIndex],
            });
          }
        }

        continue;
      }

      let theLabel = theMachineConfig[index]['config_file_name'];
      if (theMachineConfig[index]['sensor_device_name'] !== '') {
        theLabel = theMachineConfig[index]['sensor_device_name'] + ' (' + theLabel + ')';
      }

      theMachineConfigOptions.push({
        label: theLabel,
        value: theMachineConfig[index]['config_file_name'],
      });
    }

    setMachineConfigOptions(theMachineConfigOptions);

    let processInfo = await getSpecificProcess(originalProductName, theSelectedBatchNumber['value']);

    let stationNames = processInfo[0];
    let stationIds = processInfo[1];
    let machineConfigs = processInfo[2];

    setOriginalStationIds(stationIds);
    setProduceNumber(processInfo[3]);
    setProductWeight(processInfo[4]);

    let currentProcessGroup = [];
    let machineConfigArray = [];
    let theMachineConfigOption = [];
    let filtered = [];

    for (let index=0; index<stationNames.length; index++) {
      machineConfigArray = machineConfigs[index];
      currentProcessGroup.push({
        'process': stationNames[index],
        'station_id': stationIds[index],
        'machine_config': machineConfigArray,
      });

      theMachineConfigOption[index] = [];
      for (let machineConfigIndex=0; machineConfigIndex<machineConfigArray.length; machineConfigIndex++) {
        for (let valueIndex=0; valueIndex<theMachineConfigOptions.length; valueIndex++) {
          if (theMachineConfigOptions[valueIndex].value === machineConfigArray[machineConfigIndex]) {
            filtered.push(theMachineConfigOptions[valueIndex]);
          }
        }

        theMachineConfigOption[index].push(filtered[0]);
        filtered = [];
      }
    }

    setSelectedBatchNumber(theSelectedBatchNumber);
    setMachineConfigOption(theMachineConfigOption);
    setProcessInputGroup(currentProcessGroup);

    setProcessingSpinner('none');

    setStatusNumber(statusNumber + 1);
  };

  const handleProcessInputChange = (selectedValue, index, type) => {
    let processInputGroups = [...processInputGroup];
    if (type === 'process') {
      processInputGroups[index]['process'] = selectedValue;
    } else {
      let value = [];
      for (let index=0; index<selectedValue.length; index++) {
        value.push(selectedValue[index].value);
      }

      if (!processInputGroup[index]['machine_config']) {
        processInputGroup[index]['machine_config'] = [];
      }

      processInputGroup[index]['machine_config'] = value;

      let theMachineConfigOption = machineConfigOption;
      theMachineConfigOption[index] = selectedValue;
      setMachineConfigOption(theMachineConfigOption);
    }

    setProcessInputGroup(processInputGroups);
  };

  const handleDelProcessInput = (e) => {
    let currentIndex = Number(e.target.id.replace(/delProcessBtn/g, ''));
    let processInputGroups = [];
    let theMachineConfigOption = [];
    if (processInputGroup.length === 1) {
      return false;
    }
    for (let index=0; index<processInputGroup.length; index++) {
      if (index === currentIndex) {
        continue;
      }

      theMachineConfigOption.push(machineConfigOption[index]);
      processInputGroups.push(processInputGroup[index]);
    }

    setMachineConfigOption(theMachineConfigOption);
    setProcessInputGroup(processInputGroups);
  };

  const handleDelUpdatedProcessInput = async (e) => {
    await verifiedLoginWithSetState(setRedirectToLogin);
    setUpdatedResMessage('');
    setErrorMessage('');
    let currentStationId = Number(e.target.id.replace(/delProcessBtn/g, ''));
    let processInputGroups = [];
    let currentStationNames = [];
    let currentStationIds = [];
    let currentMachineConfigs = [];
    let theMachineConfigOption = [];
    if (processInputGroup.length === 1) {
      return false;
    }
    for (let index=0; index<processInputGroup.length; index++) {
      if (Number(processInputGroup[index]['station_id']) === currentStationId) {
        continue;
      }
      processInputGroups.push(processInputGroup[index]);
      currentStationNames.push(processInputGroup[index]['process']);
      currentStationIds.push(processInputGroup[index]['station_id']);
      currentMachineConfigs.push(processInputGroup[index]['machine_config']);
      theMachineConfigOption.push(machineConfigOption[index]);
    }

    await deleteStationById(currentStationId, setUpdatedResMessage, setErrorMessage);
    await getProduct(setProcessSettingTables);

    setOriginalStationIds(currentStationIds);
    setProcessInputGroup(processInputGroups);
    setMachineConfigOption(theMachineConfigOption);
  };

  const handleCopyProduct = async () => {
    setErrorMessage('');
    setProcessingSpinner('inline-block');
    await verifiedLoginWithSetState(setRedirectToLogin);

    let currentBatchNumber = selectedBatchNumber['value'];
    let currentProductName = productName;

    let theCopiedRequestPayload = copiedRequestPayload;
    theCopiedRequestPayload['station_name'] = theCopiedRequestPayload['station_name'][String(currentBatchNumber)];
    theCopiedRequestPayload['machine_config'] = theCopiedRequestPayload['machine_config'][String(currentBatchNumber)];
    theCopiedRequestPayload['produce_number'] = produceNumber;
    theCopiedRequestPayload['product_weight'] = productWeight;

    let nextBatchNumber = newBatchNumber;
    if (isNaN(Number(nextBatchNumber)) || nextBatchNumber <= 0) {
      setProcessingSpinner('none');
      setErrorMessage('輸入新的第幾批需要是正整數！');

      return false;
    }

    if (isNaN(Number(produceNumber)) || produceNumber <= 0) {
      setProcessingSpinner('none');
      setErrorMessage('該批生產數量需要是正整數！');

      return false;
    }

    if (isNaN(Number(productWeight)) || productWeight <= 0) {
      setProcessingSpinner('none');
      setErrorMessage('該批產品數量(公噸)需要是正整數！');

      return false;
    }

    await copyProduct(theCopiedRequestPayload, setErrorMessage, setUpdatedResMessage, setCopyProcessModal, copyProcessModal, nextBatchNumber, currentProductName);
    await getProduct(setProcessSettingTables);

    setProcessingSpinner('none');
  };

  const handleAddProduct = async () => {
    await verifiedLoginWithSetState(setRedirectToLogin);
    setErrorMessage('');

    let validateRes = validateAddProduct(productName, processInputGroup, setErrorMessage, produceNumber, batchNumber);
    if (validateRes === false) {
      setProcessingSpinner('none');
      return false;
    }

    let requestPayload = {
      'station_name': [],
      'process_name': productName,
      'machine_config': [],
      'batch_number': batchNumber,
      'product_weight': productWeight,
      'produce_number': produceNumber,
    };

    let theDefaultMachineConfig = '';
    if (defaultMachineConfig === '') {
      theDefaultMachineConfig = machineConfigTables[0]['config_file_name'];
      setDefaultMachineConfig(theDefaultMachineConfig);
    }

    for (let index=0; index<processInputGroup.length; index++) {
      requestPayload['station_name'].push(processInputGroup[index]['process']);

      if (processInputGroup[index]['machine_config'] === undefined
        || processInputGroup[index]['machine_config'] === null
        || processInputGroup[index]['machine_config'].length === 0) {
        processInputGroup[index]['machine_config'] = [theDefaultMachineConfig];
      }

      requestPayload['machine_config'].push(processInputGroup[index]['machine_config']);
    }

    let productCounter = await getProductByName(productName, batchNumber);
    if (productCounter > 0) {
      setErrorMessage(productName + ' 產品製程的第' + batchNumber + '批號已經存在！');
      return false;
    }

    setProcessingSpinner('inline-block');

    await createProduct(requestPayload, setErrorMessage, setUpdatedResMessage, setAddProcessModal, addProcessModal);
    await getProduct(setProcessSettingTables);

    setProcessingSpinner('none');
  };

  const validateAddProduct = (productName, processInputGroup, setErrorMessage, produceNumber, batchNumber=1) => {
    if (productName === '') {
      setErrorMessage('請輸入產品名稱');
      return false;
    }
    if (batchNumber === '' || isNaN(batchNumber) || batchNumber <= 0 || String(batchNumber).includes('.') === true) {
      setErrorMessage('輸入產品的第幾批需為正整數!');
      return false;
    }
    if (produceNumber === '' || isNaN(produceNumber) || produceNumber <= 0 || String(produceNumber).includes('.') === true) {
      setErrorMessage('輸入產品的第幾批需為正整數!');
      return false;
    }

    for (let index=0; index<processInputGroup.length; index++) {
      if (processInputGroup[index]['process'] === '') {
        setErrorMessage('第' + (index + 1) + '個的製程名稱尚未填寫！')
        return false;
      }
    }
  };

  const toggle = () => {
    setUpdatedResMessage('');
    setErrorMessage('');
    setProductName('');
    setProcessInputGroup([{'process': ''}]);
    setAddProcessModal(!addProcessModal);
    setProduceNumber(1);
    setProductWeight(1);

    let theMachineConfigOption = [];
    for (let index=0; index<machineConfigOptions.length; index++) {
      theMachineConfigOption.push([]);
    }

    setMachineConfigOption(theMachineConfigOption);
  };

  const copyToggle = (e) => {
    setErrorMessage('');
    setUpdatedResMessage('');
    setProduceNumber(1);
    setProductWeight(1);
    setCopyProcessModal(!copyProcessModal);
    if (e.target.dataset.stationnames === undefined) {
      return false;
    }

    let stationNames = JSON.parse(e.target.dataset.stationnames);
    let machineConfigs = JSON.parse(e.target.dataset.machineconfigs);

    let batchNumberLists = JSON.parse(e.target.dataset.batchnumberlists);
    let batchNumbers = [];
    for (let index=0; index<batchNumberLists.length; index++) {
      batchNumbers.push({
        'label': batchNumberLists[index],
        'value': batchNumberLists[index],
      });
    }
    setSelectedBatchNumber({'label': batchNumbers[0]['label'], 'value': batchNumbers[0]['value']});
    setBatchNumberList(batchNumbers);

    setProductName(e.target.dataset.productname);

    let requestPayload = {
      'station_name': stationNames,
      'process_name': e.target.dataset.productname,
      'machine_config': machineConfigs,
      'batch_number_lists': Object.keys(stationNames).map((value) => { return Number(value); }),
    };

    setCopiedRequestPayload(requestPayload);
  };

  const updateModalToggle = (e) => {
    setErrorMessage('');
    setUpdatedResMessage('');
    setUpdateProcessModal(!updateProcessModal);
    setProcessInputGroup([{'process': '', 'station_id': '', 'machine_config': []}]);

    if (e.target.dataset.stationnames === undefined) {
      return false;
    }

    let expectedBatchNumber = '1';
    let stationIds = e.target.dataset.stationids.split(',');
    let batchNumberLists = JSON.parse(e.target.dataset.batchnumberlists);
    let produceNumber = e.target.dataset.producenumber;
    let productWeight = e.target.dataset.productweight;

    let stationNames = JSON.parse(e.target.dataset.stationnames);
    stationNames = stationNames[expectedBatchNumber];

    let machineConfigs = JSON.parse(e.target.dataset.machineconfigs);
    machineConfigs = machineConfigs[expectedBatchNumber];

    let batchNumbers = [];
    for (let index=0; index<batchNumberLists.length; index++) {
      batchNumbers.push({
        'label': batchNumberLists[index],
        'value': batchNumberLists[index],
      });
    }

    setSelectedBatchNumber({});
    setBatchNumberList(batchNumbers);

    setProduceNumber(produceNumber);
    setProductWeight(productWeight);

    setOriginalStationIds(stationIds);

    let currentProcessGroup = [];
    let machineConfigArray = [];
    let theMachineConfigOption = [];
    let filtered = [];

    for (let index=0; index<stationNames.length; index++) {
      machineConfigArray = machineConfigs[index];
      currentProcessGroup.push({
        'process': stationNames[index],
        'station_id': stationIds[index],
        'machine_config': machineConfigArray,
      });

      theMachineConfigOption[index] = [];
      for (let machineConfigIndex=0; machineConfigIndex<machineConfigArray.length; machineConfigIndex++) {
        for (let valueIndex=0; valueIndex<machineConfigOptions.length; valueIndex++) {
          if (machineConfigOptions[valueIndex].value === machineConfigArray[machineConfigIndex]) {
            filtered.push(machineConfigOptions[valueIndex]);
          }
        }

        theMachineConfigOption[index].push(filtered[0]);
        filtered = [];
      }
    }

    setMachineConfigOption(theMachineConfigOption);
    setProcessInputGroup(currentProcessGroup);
    setProductName(e.target.dataset.productname);
    setOriginalProductName(e.target.dataset.productname);
  };

  const deleteToggle = (e) => {
    setErrorMessage('');
    setUpdatedResMessage('');
    setDeleteProcessModal(!deleteProcessModal);

    if (e.target.dataset.productname === undefined) {
      return false;
    }

    setProductName(e.target.dataset.productname);
    let batchNumberLists = JSON.parse(e.target.dataset.batchnumberlists);
    let batchNumbers = [];
    for (let index=0; index<batchNumberLists.length; index++) {
      batchNumbers.push({
        'label': batchNumberLists[index],
        'value': batchNumberLists[index],
      });
    }
    setSelectedBatchNumber({'label': batchNumbers[0]['label'], 'value': batchNumbers[0]['value']});
    setBatchNumberList(batchNumbers);
  };

  const cancelUpdateModalToggle = () => {
    setUpdateProcessModal(!updateProcessModal);
  };

  const handleUpdateProduct = async () => {
    setProcessingSpinner('inline-block');
    setErrorMessage('');
    setUpdatedResMessage('');
    await verifiedLoginWithSetState(setRedirectToLogin);

    let validateRes = validateAddProduct(productName, processInputGroup, setErrorMessage, produceNumber, batchNumber);
    if (validateRes === false) {
      setProcessingSpinner('none');
      return false;
    }

    let requestStationPayload = {
      'station_name': [],
      'station_id': [],
      'machine_config': [],
    };
    let requestPayload = {
      'process_name': productName,
      'original_process_name': originalProductName,
      'station_id': [],
      'produce_number': produceNumber,
      'product_weight': productWeight,
    };

    for (let index=0; index<processInputGroup.length; index++) {
      requestPayload['station_id'].push(processInputGroup[index]['station_id']);
      requestStationPayload['station_name'].push(processInputGroup[index]['process']);
      requestStationPayload['station_id'].push(originalStationIds[index]);
      requestStationPayload['machine_config'].push(processInputGroup[index]['machine_config']);
    }

    await updateProduct(requestPayload, setErrorMessage, setUpdatedResMessage, setUpdateProcessModal, updateProcessModal);

    if (requestStationPayload['station_id'].length > 0) {
      await updateStation(requestStationPayload, setErrorMessage, setUpdatedResMessage, setUpdateProcessModal, updateProcessModal);
    }

    await getProduct(setProcessSettingTables);

    setProcessingSpinner('none');
  };

  const handleDeleteProduct = async () => {
    await verifiedLoginWithSetState(setRedirectToLogin);
    setUpdatedResMessage('');
    setErrorMessage('');

    setProcessingSpinner('inline-block');
    await deleteProduct(setUpdatedResMessage, setErrorMessage, setDeleteProcessModal, deleteProcessModal, productName, selectedBatchNumber['value']);
    await getProduct(setProcessSettingTables);
    setProcessingSpinner('none');
  };

  useEffect(() => {
    if (statusNumber > 0) {
    } else {
      localStorage.removeItem('batch_number');
      async function verifiedLogin() {
        await verifiedLoginWithSetState(setRedirectToLogin);
      }

      verifiedLogin();

      async function genProcessSettingTable() {
        setLoadingSpinner('inline-block');
        await getProduct(setProcessSettingTables);
        setLoadingSpinner('none');
      }

      async function genMachineConfigList() {
        setLoadingSpinner('inline-block');

        let theMachineConfigOptions = [];
        let theMachineConfigOption = [];
        let theMachineConfig = await getMachineConfig(setMachineConfigTables);
        for (let index=0; index<theMachineConfig.length; index++) {
          if (theMachineConfig[index]['config_file_name'] === 'uuid.txt') {
            let configFileContents = theMachineConfig[index]['config_file_content'].split('\n');

            for (let configIndex=0; configIndex<configFileContents.length; configIndex++) {
              if (configFileContents[configIndex].length > 0) {
                theMachineConfigOptions.push({
                  label: (configFileContents[configIndex] + ' (uuid.txt)'),
                  value: configFileContents[configIndex],
                });
              }
            }

            continue;
          }

          theMachineConfigOption.push([]);

          let theLabel = theMachineConfig[index]['config_file_name'];
          if (theMachineConfig[index]['sensor_device_name'] !== '') {
            theLabel = theMachineConfig[index]['sensor_device_name'] + ' (' + theLabel + ')';
          }

          theMachineConfigOptions.push({
            label: theLabel,
            value: theMachineConfig[index]['config_file_name'],
          });
        }

        setMachineConfigOption(theMachineConfigOption);
        setMachineConfigOptions(theMachineConfigOptions);
        setLoadingSpinner('none');
      }

      genProcessSettingTable();
      genMachineConfigList();
    }
  }, [statusNumber]);

  if (redirectToLogin) {
    return <Redirect to='/admin/login'/>
  }

  return (
    <>
      <div className="content">
      <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem', display: loadingSpinner}} children={'false'} />
      { (loadingSpinner === 'none' && machineConfigOption.length > 0 && machineConfigOptions.length > 0) ?
      <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle className="cart-title">產品製程設定 {' '}
                  <Button disabled={ machineConfigOption.length === 0 || machineConfigOptions.length === 0 } className="btn-icon-setting" onClick={toggle}><FontAwesomeIcon icon={ faPlus } />新增設定</Button> {' '}
                </CardTitle>
                <Modal keyboard={ false } backdrop={ "static" } size="xl" key="addProcessModal" isOpen={addProcessModal} toggle={toggle}>
                  <ModalHeader className="modal-title" toggle={toggle}>新增{' '}
                    <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem', display: processingSpinner}} children={'false'} />
                  </ModalHeader>
                    <ModalBody>
                      <Form onSubmit={ (event) => { event.preventDefault(); } }>
                        <FormGroup>
                          <span className="modal-secondary-title">產品製程設定</span>
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="source-name">請輸入產品名稱</Label>{' '}
                          <Button className="btn-setting-add" onClick={handleProcessInput}><FontAwesomeIcon icon={ faPlus } />{' '}新增製程</Button>
                        </FormGroup>
                        <FormGroup>
                          <Input name="product-name" onChange={event => setProductName(event.target.value)} type="text" value={productName || ''} placeholder="請輸入產品名稱" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="batch-number">請輸入第幾個批號</Label>{' '}
                          <Input name="batch-number" onChange={event => setBatchNumber(event.target.value)} type="number" value={batchNumber || ''} placeholder="請輸入第幾個批號" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="product-weight">請輸入該批產品數量(公噸)</Label>{' '}
                          <Input name="product-weight" onChange={event => setProductWeight(event.target.value)} type="number" value={productWeight || ''} placeholder="請輸入該批的重量" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="produce-number">請輸入該批生產數量</Label>{' '}
                          <Input name="produce-number" onChange={event => setProduceNumber(event.target.value)} type="number" value={produceNumber || ''} placeholder="請輸入該批生產數量" />
                        </FormGroup>
                        {
                          processInputGroup.map((item, index) => {
                            return <FormGroup row className="form-inline" key={'processInputGroup' + index}>
                              <Col md="3">
                                <Input className="w-100" key={'processInput' + index} name="process-name" onChange={event => handleProcessInputChange(event.target.value, index, 'process')} type="text" value={item.process || ''} placeholder="請輸入製程名稱" />
                              </Col>
                              <Col md="6">
                                <MultiSelect
                                  hasSelectAll={ false }
                                  overrideStrings={{
                                    allItemsAreSelected: "所有選項已經選擇",
                                    clearSearch: "清除篩選結果",
                                    clearSelected: "清除篩選已選的設定",
                                    noOptions: "無選項可以選擇",
                                    search: "請輸入搜尋關鍵字...",
                                    selectSomeItems: "請選擇感測器設定...",
                                    create: "建立",
                                  }}
                                  options={ machineConfigOptions }
                                  value={ machineConfigOption[index] }
                                  onChange={ (selectedValue) => handleProcessInputChange(selectedValue, index, 'machine_config') }
                                  labelledBy=""
                                />
                              </Col>
                              <Col md="3">
                                <Button className="btn-delete" id={'delProcessBtn' + index} key={'del' + index} name="process-name" onClick={handleDelProcessInput}><FontAwesomeIcon icon={ faTrashCan } />{' '}刪除</Button>
                              </Col>
                            </FormGroup>
                          })
                        }
                        <FormGroup>
                          <h4 className="text-info">{ updatedResMessage }</h4>
                          <h5 className="text-danger">{ errorMessage }</h5>
                        </FormGroup>
                      </Form>
                    </ModalBody>
                    <ModalFooter className="modal-footer-center">
                      <Button className="btn-setting" onClick={ handleAddProduct }>新增</Button>{' '}
                      <Button className="btn-cancel" onClick={ toggle }>取消</Button>
                    </ModalFooter>
                </Modal>
              </CardHeader>
              <CardBody>
                <Table>
                  {
                    processSettingTables.length > 0 ?
                    <thead className="text-info">
                    <tr>
                      <th className="th-customize">產品名稱</th>
                      <th className="th-customize">機台流程</th>
                      <th className="th-customize">共有幾批</th>
                      <th className="th-customize">設定</th>
                      <th className="th-customize">複製</th>
                      <th className="th-customize">刪除</th>
                    </tr>
                  </thead> : []
                  }
                  <tbody>
                    { processSettingTables.map((item, index) => {
                      return <tr key={ Number(index) }>
                      <td className="td-customize">{ item['product_name'] }</td>
                      <td style={{ fontWeight: (item['station_names'][Object.keys(item['station_names'])[0]].length >= 5 ? 'bold' : '') }} id={ 'station_names' + index } className="td-customize">
                        { item['station_names'][Object.keys(item['station_names'])[0]].length >= 5 ? (item['station_names'][Object.keys(item['station_names'])[0]].slice(0, 4).join('→') + '...') : item['station_names'][Object.keys(item['station_names'])[0]].join('→') }
                        {
                          (item['station_names'][Object.keys(item['station_names'])[0]]).length >= 5 ?
                          <ReactTooltip
                            anchorSelect={ '#station_names' + index }
                            place='bottom'
                            variant='info'
                            float='false'
                            content={ item['station_names'][Object.keys(item['station_names'])[0]].join('→') }
                          /> : null
                        }
                      </td>
                      <td className="td-customize">{ item['batch_number'].length }</td>
                      <td className="td-customize">
                      <Button
                          className="btn-setting"
                          id={'product' + index}
                          onClick={ updateModalToggle }
                          data-id = { index }
                          data-productids={ item['product_ids'] }
                          data-stationids={ item['station_ids'] }
                          data-stationnames={ JSON.stringify(item['station_names']) }
                          data-productname={ item['product_name'] }
                          data-batchnumberlists={ JSON.stringify(item['batch_number']) }
                          data-machineconfigs={ JSON.stringify(item['machine_config']) }
                          data-productweight={ item['product_weight'] }
                          data-producenumber={ item['produce_number'] }
                      >
                      設定
                      </Button>
                    </td>
                      <td className="td-customize">
                      <Button
                          className="btn-setting-add"
                          id={'product-copy' + index}
                          data-stationnames={ JSON.stringify((item['station_names'])) }
                          data-productname={ item['product_name'] }
                          data-machineconfigs={ JSON.stringify(item['machine_config']) }
                          data-batchnumberlists={ JSON.stringify(item['batch_number']) }
                          onClick={ copyToggle }
                      >
                      複製
                      </Button>
                    </td>
                    <td className="td-customize">
                      <Button
                          className="btn-delete"
                          id={'delete' + index}
                          onClick={deleteToggle}
                          data-productid = { item['product_id'] }
                          data-productname = { item['product_name'] }
                          data-batchnumberlists={ JSON.stringify(item['batch_number']) }
                      >
                      <FontAwesomeIcon icon={ faTrashCan } />{ ' ' }
                      刪除
                      </Button>
                    </td>
                  </tr>
                    }) }
                  </tbody>
                  <Modal keyboard={ false } backdrop={ "static" } size="xl" key="updateProductModal" isOpen={ updateProcessModal } toggle={ updateModalToggle } className="">
                  <ModalHeader className="modal-title" toggle={cancelUpdateModalToggle}>
                    修改{' '}
                    <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem', display: processingSpinner}} children={'false'} />
                  </ModalHeader>
                    <ModalBody>
                      <Form onSubmit={ (event) => { event.preventDefault(); } }>
                        <FormGroup>
                          <span className="modal-secondary-title">產品製程設定</span>
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="source-name">請輸入產品名稱</Label>
                          <Input name="source-name" onChange={event => setProductName(event.target.value)} type="text" value={productName || ''} placeholder="請輸入產品名稱" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="batch-number">請選擇產品第幾批</Label>
                          <Select
                            isDisabled={ processingSpinner === 'inline-block' }
                            onChange={event => handleBatchNumberListsChange(event)}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            value={ Object.keys(selectedBatchNumber).length === 0 ? batchNumberList[0] : selectedBatchNumber }
                            isMulti={false}
                            options={ batchNumberList }
                            placeholder="請選擇第幾批產品"
                            noOptionsMessage={() => '沒有該產品批號選項！'}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="product-weight">請輸入該批產品數量(公噸)</Label>{' '}
                          <Input name="product-weight" onChange={event => setProductWeight(event.target.value)} type="number" value={productWeight || ''} placeholder="請輸入該批的重量" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="produce-number">請輸入該批生產數量</Label>{' '}
                          <Input name="produce-number" onChange={event => setProduceNumber(event.target.value)} type="number" value={produceNumber || ''} placeholder="請輸入該批生產重量" />
                        </FormGroup>
                        {
                          processInputGroup.map((item, index) => {
                            return <FormGroup row className="form-inline" key={'processInputGroup' + index}>
                              <Col md="3">
                                <Input className="w-100" key={'processInput' + index} name="process-name" onChange={event => handleProcessInputChange(event.target.value, index, 'process')} type="text" value={item.process || ''} placeholder="請輸入製程名稱" />
                              </Col>
                              <Col md="6">
                                <MultiSelect
                                  hasSelectAll={ false }
                                  overrideStrings={{
                                    allItemsAreSelected: "所有選項已經選擇",
                                    clearSearch: "清除篩選結果",
                                    clearSelected: "清除篩選已選的設定",
                                    noOptions: "無選項可以選擇",
                                    search: "請輸入搜尋關鍵字...",
                                    selectSomeItems: "請選擇感測器設定...",
                                    create: "建立",
                                  }}
                                  options={ machineConfigOptions }
                                  value={ machineConfigOption[index] }
                                  onChange={ (selectedValue) => handleProcessInputChange(selectedValue, index, 'machine_config') }
                                  labelledBy=""
                                />
                              </Col>
                              <Col md="3">
                                <Button className="btn-delete" id={'delProcessBtn' + item['station_id']} key={'del' + index} name="process-name" onClick={handleDelUpdatedProcessInput}><FontAwesomeIcon icon={ faTrashCan } />{' '}刪除製程</Button>
                              </Col>
                            </FormGroup>
                          })
                        }
                        <FormGroup>
                          <h4 className="text-info">{ updatedResMessage }</h4>
                          <h5 className="text-danger">{ errorMessage }</h5>
                        </FormGroup>
                      </Form>
                    </ModalBody>
                    <ModalFooter className="modal-footer-center">
                      <Button className="btn-setting" onClick={ handleUpdateProduct }>送出</Button>{' '}
                      <Button className="btn-cancel" onClick={ cancelUpdateModalToggle }>取消</Button>
                    </ModalFooter>
                  </Modal>
                  <Modal keyboard={ false } backdrop={ "static" } key="delProductModal" isOpen={deleteProcessModal} toggle={deleteToggle} className="">
                    <ModalHeader className="modal-title" toggle={deleteToggle}>刪除{' '}
                      <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem', display: processingSpinner}} children={'false'} />
                    </ModalHeader>
                      <ModalBody>
                        <Form>
                          <FormGroup>
                            <h5 className="text-danger">確定刪除此批號的產品製程？</h5>
                          </FormGroup>
                          <FormGroup>
                            <Label className="modal-form-label" for="product-name">產品名稱</Label>
                            <Input disabled={true} name="product-name" onChange={event => setProductName(event.target.value)} type="text" value={productName || ''} placeholder="請輸入產品名稱" />
                          </FormGroup>
                          <FormGroup>
                            <Label className="modal-form-label" for="batch-number">請選擇要刪除第幾批的產品</Label>
                            <Select
                              isDisabled={ processingSpinner === 'inline-block' }
                              onChange={theSelectedBatchNumber => setSelectedBatchNumber(theSelectedBatchNumber)}
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              value={ selectedBatchNumber }
                              isMulti={false}
                              options={ batchNumberList }
                              placeholder="請選擇第幾批產品"
                              noOptionsMessage={() => '沒有該產品批號選項！'}
                            />
                          </FormGroup>
                          <FormGroup>
                            <h4 className="text-info">{ updatedResMessage }</h4>
                            <h4 className="text-danger">{ errorMessage }</h4>
                          </FormGroup>
                        </Form>
                      </ModalBody>
                      <ModalFooter className="modal-footer-center">
                        <Button className="btn-setting" onClick={ handleDeleteProduct }>確定</Button>{' '}
                        <Button className="btn-cancel" onClick={ deleteToggle }>取消</Button>
                      </ModalFooter>
                  </Modal>
                  <Modal keyboard={ false } backdrop={ "static" } key="copyProductModal" isOpen={copyProcessModal} toggle={copyToggle}>
                      <ModalHeader className="modal-title" toggle={copyToggle}>
                        複製製程{' '}
                        <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem', display: processingSpinner}} children={'false'} />
                      </ModalHeader>
                      <ModalBody>
                        <Form>
                          <FormGroup>
                            <h5 className="text-danger">確定複製此產品製程？</h5>
                          </FormGroup>
                          <FormGroup>
                            <Label className="modal-form-label" for="product-name">產品名稱</Label>
                            <Input disabled={true} name="product-name" onChange={event => setProductName(event.target.value)} type="text" value={productName || ''} placeholder="請輸入產品名稱" />
                          </FormGroup>
                          <FormGroup>
                            <Label className="modal-form-label" for="batch-number">請選擇要複製第幾批的產品</Label>
                            <Select
                              isDisabled={ processingSpinner === 'inline-block' }
                              onChange={theSelectedBatchNumber => setSelectedBatchNumber(theSelectedBatchNumber)}
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              value={ selectedBatchNumber }
                              isMulti={false}
                              options={ batchNumberList }
                              placeholder="請選擇第幾批產品"
                              noOptionsMessage={() => '沒有該產品批號選項！'}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="modal-form-label" for="new-batch-number">請輸入新的第幾批</Label>{' '}
                            <Input name="new-batch-number" onChange={event => setNewBatchNumber(event.target.value)} type="number" value={newBatchNumber || ''} placeholder="請輸入要新增新的第幾批" />
                          </FormGroup>
                          <FormGroup>
                            <Label className="modal-form-label" for="product-weight">請輸入該批產品數量(公噸)</Label>{' '}
                            <Input name="product-weight" onChange={event => setProductWeight(event.target.value)} type="number" value={productWeight || ''} placeholder="請輸入該批的重量" />
                          </FormGroup>
                          <FormGroup>
                            <Label className="modal-form-label" for="produce-number">請輸入該批生產數量</Label>{' '}
                            <Input name="produce-number" onChange={event => setProduceNumber(event.target.value)} type="number" value={produceNumber || ''} placeholder="請輸入該批生產數量" />
                          </FormGroup>
                          <FormGroup>
                            <h4 className="text-info">{ updatedResMessage }</h4>
                            <h4 className="text-danger">{ errorMessage }</h4>
                          </FormGroup>
                        </Form>
                      </ModalBody>
                      <ModalFooter className="modal-footer-center">
                        <Button className="btn-setting" onClick={ handleCopyProduct }>確定</Button>{' '}
                        <Button className="btn-cancel" onClick={ copyToggle }>取消</Button>
                      </ModalFooter>
                  </Modal>
                  </Table>
              </CardBody>
            </Card>
          </Col>
        </Row> : <></>
      }
      </div>
    </>
  );
}

export default ProcessDashboard;
