import Axios from 'axios';


var emsTagListByDatabasePath = '/tag_list_by_db';
var deviceCarbonPath = '/query_tag_carbon';

export async function getEmsTagListFromSensorDatabase(setDeviceTagList, setErrorMessage, setSelectedTagName) {
  await Axios.get(process.env.REACT_APP_API_ADDRESS + emsTagListByDatabasePath).then((response) => {
    if (response.data['error_message']) {
      setErrorMessage(response.data['error_message']);
      return false;
    }

    let deviceTagList = [];
    let dataList = response.data;
    if (dataList.length > 0) {
      setSelectedTagName({
        label: dataList[0][0],
        value: dataList[0][0],
      });
    }
    for (let index=0; index<dataList.length; index++) {
      deviceTagList.push({
        label: dataList[index][0],
        value: dataList[index][0],
      });
    }

    setDeviceTagList(deviceTagList);
  }).catch((error) => {
    let errResponseJsonString = JSON.stringify(error.response, null, 2);
    console.log(errResponseJsonString);
  });
};

export async function getDeviceCarbon(setResultMessage, setDeviceCarbonChartData, requestPayload, electricCO2e, setShowChart, setExportedCsvFileName, setDeviceCarbonData) {
  let requestConfig = {
    'headers': {
      'X-Token': localStorage.getItem('auth_session'),
      'X-Account': localStorage.getItem('auth_account'),
    },
  };

  setExportedCsvFileName(requestPayload.tag_name + '_' + requestPayload.started_measured_date + '_' + requestPayload.end_measured_date + '.csv');

  await Axios.post(process.env.REACT_APP_API_ADDRESS + deviceCarbonPath, requestPayload, requestConfig).then((response) => {
    let precision = 4;
    let responseData = response.data;
    let csvData = [
      ['日期', '電力度數', '電力碳排係數(KgCO2e)', '電力碳排放量(TCO2e)'],
    ];
    if (responseData.length <= 0) {
      setResultMessage('選擇的時間範圍無感測資料！');
      setShowChart(true);
    } else {
      let resultCarbonLineChartData = {
        labels: [],
        datasets: [],
      };
      let value = 0;
      let data = [];
      let measuredDate = Object.keys(responseData);

      for (let index=0; index<measuredDate.length; index++) {
        resultCarbonLineChartData.labels.push(measuredDate[index]);
        value = Number(responseData[measuredDate[index]]) * electricCO2e / 1000;
        data.push(value.toFixed(precision));
        csvData.push([
          measuredDate[index],
          responseData[measuredDate[index]],
          electricCO2e,
          value.toFixed(precision),
        ]);
      }

      resultCarbonLineChartData.datasets.push({
        label: (requestPayload['tag_name'] + '之碳排放量變化'),
        data: data,
        fill: true,
      });

      setDeviceCarbonChartData(resultCarbonLineChartData);
      setDeviceCarbonData(csvData);
      setShowChart(false);
    }
  }).catch((error) => {
    let errResponseJsonString = JSON.stringify(error.response, null, 2);
    console.log(errResponseJsonString);
  });
};

const RithaiDeviceCarbon = {
  getEmsTagListFromSensorDatabase,
  getDeviceCarbon,
};

export default RithaiDeviceCarbon;
