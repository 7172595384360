import Axios from 'axios';


var processApiPath = '/process';
var stationApiPath = '/station';
var specificProcessApiPath = '/specific_process';
var mesProcessStationApiPath = '/mes/process_station';


export async function getSpecificProcess(productName, batchNumber) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };
    let getSpecificProcessUrl = process.env.REACT_APP_API_ADDRESS + specificProcessApiPath + '/' + productName + '/' + batchNumber;

    let responseData = await Axios.get(getSpecificProcessUrl, requestConfig).then((response) => {
        let stationNames = [];
        let stationIds = [];
        let machineConfigs = [];
        let produceNumber = null;
        let productWeight = null;
        for (let index=0; index<response.data.length; index++) {
            stationNames.push(response.data[index][4]);
            stationIds.push(response.data[index][3]);
            machineConfigs.push(response.data[index][2]);
            if (produceNumber === null) {
                produceNumber = response.data[index][6];
            }
            if (productWeight === null) {
                productWeight = response.data[index][7];
            }
        }

        return [
            stationNames,
            stationIds,
            machineConfigs,
            produceNumber,
            productWeight,
        ];
    }).catch((error) => {
        console.log(error);
    });

    return responseData;
};

export async function getProcessStationByProductName(productName, setProcessTypeLists, setMachineConfigs=null) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };
    let getProcessStationByProductNameUrl = process.env.REACT_APP_API_ADDRESS + mesProcessStationApiPath + '/' + productName;

    let responseData = await Axios.get(getProcessStationByProductNameUrl, requestConfig).then((response) => {
        let responseData = [];
        let processTypes = [];
        let machineConfigs = {};
        let configArr = [];
        for (let index=0; index<response.data.length; index++) {
            if (!processTypes.includes(response.data[index][0])) {
                responseData.push({
                    'process_type': response.data[index][0],
                });
                processTypes.push(response.data[index][0]);
            }

            configArr = response.data[index][5];
            machineConfigs[response.data[index][0]] = [];
            for (let configIndex=0; configIndex<configArr.length; configIndex++) {
                machineConfigs[response.data[index][0]].push({
                    'config_file_name': response.data[index][3][configIndex],
                    'sensor_device_name': response.data[index][5][configIndex],
                });
            }
        }

        setProcessTypeLists(responseData);
        if (setMachineConfigs !== null) {
            setMachineConfigs(machineConfigs);
        }

        return responseData;
    }).catch((error) => {
        console.log(error);
    });

    return responseData;
};

export async function getProductByName(productName, batchNumber) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };
    let getProductByNameUrl = process.env.REACT_APP_API_ADDRESS + processApiPath + '/' + productName + '/' + batchNumber;

    let productNumber = await Axios.get(getProductByNameUrl, requestConfig).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });

    return productNumber;
};

export async function getProduct(setProcessSettingTables) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };
    let responseData = await Axios.get(process.env.REACT_APP_API_ADDRESS + processApiPath, requestConfig).then((response) => {
        let responseData = [];
        let batchNumberLists = {};
        let findProduct = false;
        let machineConfigs = {};
        let stationNames = {};
        for (let index=0; index<response.data.length; index++) {
            if (response.data[index][5] > 1) {
                if (!Object.keys(batchNumberLists).includes(response.data[index][1])) {
                    batchNumberLists[response.data[index][1]] = [];
                }

                batchNumberLists[response.data[index][1]].push(response.data[index][5]);

                findProduct = false;
                for (let responseDataIndex=0; responseDataIndex < responseData.length; responseDataIndex++) {
                    if (!machineConfigs[String(response.data[index][5])]) {
                        machineConfigs[String(response.data[index][5])] = [];
                        responseData[responseDataIndex]['machine_config'][String(response.data[index][5])] = [];
                    }
                    if (!stationNames[String(response.data[index][5])]) {
                        stationNames[String(response.data[index][5])] = [];
                        responseData[responseDataIndex]['station_names'][String(response.data[index][5])] = [];
                    }

                    if (responseData[responseDataIndex]['product_name'] === response.data[index][1]) {
                        responseData[responseDataIndex]['product_ids'].push(response.data[index][0]);
                        responseData[responseDataIndex]['machine_config'][String(response.data[index][5])].push(response.data[index][2]);
                        responseData[responseDataIndex]['station_ids'].push(response.data[index][3]);
                        responseData[responseDataIndex]['station_names'][String(response.data[index][5])].push(response.data[index][4]);
                        findProduct = true;
                        break;
                    }
                }
                if (findProduct === false) {
                    machineConfigs = {};
                    machineConfigs[String(response.data[index][5])] = [response.data[index][2]];
                    stationNames = {};
                    stationNames[String(response.data[index][5])] = [response.data[index][4]];

                    responseData.push({
                        'product_name': response.data[index][1],
                        'machine_config': machineConfigs,
                        'station_ids': [response.data[index][3]],
                        'station_names': stationNames,
                        'product_ids': [response.data[index][0]],
                        'produce_number': response.data[index][6],
                        'product_weight': response.data[index][7],
                    });
                }

                continue;
            }

            findProduct = false;
            for (let responseDataIndex=0; responseDataIndex < responseData.length; responseDataIndex++) {
                if (!machineConfigs[String(response.data[index][5])]) {
                    machineConfigs[String(response.data[index][5])] = [];
                    responseData[responseDataIndex]['machine_config'][String(response.data[index][5])] = [];
                }
                if (!stationNames[String(response.data[index][5])]) {
                    stationNames[String(response.data[index][5])] = [];
                    responseData[responseDataIndex]['station_names'][String(response.data[index][5])] = [];
                }

                if (responseData[responseDataIndex]['product_name'] === response.data[index][1]) {
                    responseData[responseDataIndex]['product_ids'].push(response.data[index][0]);
                    responseData[responseDataIndex]['machine_config'][String(response.data[index][5])].push(response.data[index][2]);
                    responseData[responseDataIndex]['station_ids'].push(response.data[index][3]);
                    responseData[responseDataIndex]['station_names'][String(response.data[index][5])].push(response.data[index][4]);
                    batchNumberLists[response.data[index][1]].push(response.data[index][5]);
                    findProduct = true;
                    break;
                }
            }
            if (findProduct === false) {
                machineConfigs = {};
                machineConfigs[String(response.data[index][5])] = [response.data[index][2]];
                stationNames = {};
                stationNames[String(response.data[index][5])] = [response.data[index][4]];

                responseData.push({
                    'product_name': response.data[index][1],
                    'machine_config': machineConfigs,
                    'station_ids': [response.data[index][3]],
                    'station_names': stationNames,
                    'product_ids': [response.data[index][0]],
                    'produce_number': response.data[index][6],
                    'product_weight': response.data[index][7],
                });
                batchNumberLists[response.data[index][1]] = [];
                batchNumberLists[response.data[index][1]].push(response.data[index][5]);
            }
        }

        for (let index=0; index<responseData.length; index++) {
            responseData[index]['batch_number'] = Array.from(new Set(batchNumberLists[responseData[index]['product_name']]));
        }

        console.log(responseData);

        setProcessSettingTables(responseData);

        return responseData;
    }).catch((error) => {
        console.log(error);

        return error;
    });

    return responseData;
};

export async function updateProduct(requestPayload, setErrorMessage, setUpdatedResMessage, setUpdateProcessModal, updateProcessModal) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    await Axios.put(process.env.REACT_APP_API_ADDRESS + processApiPath, requestPayload, requestConfig).then((response) => {
        let responseData = response.data['affected_count'];
        if (typeof responseData === 'number' && responseData > 0) {
            setUpdatedResMessage('更新成功！');
            setTimeout(() => {
                setUpdateProcessModal(!updateProcessModal);
            }, 2000);
        } else {
            setErrorMessage('更新失敗，資料已經存在！');
            console.log(responseData);
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function updateStation(requestStationPayload, setErrorMessage, setUpdatedResMessage, setUpdateProcessModal, updateProcessModal) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };
    await Axios.put(process.env.REACT_APP_API_ADDRESS + stationApiPath, requestStationPayload, requestConfig).then((response) => {
        let responseData = response.data['affected_count'];
        if (Array.isArray(responseData) === true && responseData.length > 0) {
            setUpdatedResMessage('更新流程成功！');
            setTimeout(() => {
                setUpdateProcessModal(!updateProcessModal);
            }, 2000);
        } else {
            setErrorMessage('更新失敗，資料已經存在！');
            console.log(responseData);
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function createProduct(requestPayload, setErrorMessage, setUpdatedResMessage, setModal, modal) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };
    await Axios.post(process.env.REACT_APP_API_ADDRESS + processApiPath, requestPayload, requestConfig).then((response) => {
        let responseData = response.data['affected_count'];
        if (typeof responseData === 'number' && responseData > 0) {
            setUpdatedResMessage('新增成功！');
            setTimeout(() => {
                setModal(!modal);
            }, 2000);
        } else {
            setErrorMessage('新增失敗，資料已經存在！');
            console.log(responseData);
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function copyProduct(requestPayload, setErrorMessage, setUpdatedResMessage, setModal, modal, currentBatchNumber, productName) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };
    requestPayload['batch_number'] = currentBatchNumber;
    requestPayload['process_name'] = productName;

    await Axios.post(process.env.REACT_APP_API_ADDRESS + processApiPath, requestPayload, requestConfig).then((response) => {
        let responseData = response.data['affected_count'];
        if (typeof responseData === 'number' && responseData > 0) {
            setUpdatedResMessage('複製成功！');
            setTimeout(() => {
                setModal(!modal);
            }, 2000);
        } else {
            setErrorMessage('新增失敗，資料已經存在！');
            console.log(responseData);
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function deleteProduct(setUpdatedResMessage, setErrorMessage, setDeleteProcessModal, deleteProcessModal, productName, currentBatchNumber) {
    let headers = {
        'X-Token': localStorage.getItem('auth_session'),
        'X-Account': localStorage.getItem('auth_account'),
    };
    headers = {
        'headers': headers,
    };
    let requestUrl = process.env.REACT_APP_API_ADDRESS + processApiPath + '/' + productName + '/' + currentBatchNumber;

    await Axios.delete(requestUrl, headers).then((response) => {
        let responseData = response.data['affected_count'];
        if (typeof responseData === 'number' && responseData > 0) {
            setUpdatedResMessage('刪除成功！');
            setTimeout(() => {
                setDeleteProcessModal(!deleteProcessModal);
            }, 2000);
        } else {
            setErrorMessage('刪除資料失敗！');
            console.log(response.data);
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function deleteStationById(currentIndex, setUpdatedResMessage, setErrorMessage) {
    let headers = {
        'X-Token': localStorage.getItem('auth_session'),
    };
    headers = {
        'headers': headers,
    };
    let requestUrl = process.env.REACT_APP_API_ADDRESS + stationApiPath + '/' + currentIndex;

    await Axios.delete(requestUrl, headers).then((response) => {
        let responseData = response.data['affected_count'];
        if (typeof responseData === 'number' && responseData > 0) {
            setUpdatedResMessage('刪除成功！');
        } else {
            setErrorMessage('刪除資料失敗！');
            console.log(response.data);
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

const Product = {
    getProduct,
    getProductByName,
    getSpecificProcess,
    updateProduct,
    updateStation,
    createProduct,
    copyProduct,
    deleteProduct,
    deleteStationById,
    getProcessStationByProductName,
};

export default Product;
