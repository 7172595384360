import Axios from 'axios';


var emsTagListApiPath = '/ems_tag_list_api';
var emsTestTagNameApiPath = '/test_ems_tag_name';

export async function getEmsTagList(setDeviceTagList, setErrorMessage, setSelectedTagName) {
    await Axios.get(process.env.REACT_APP_API_ADDRESS + emsTagListApiPath).then((response) => {
        if (response.data['error_message']) {
            setErrorMessage('能源管理系統忙碌中，請稍後再試！');
            return false;
        }

        let deviceTagList = [];
        let dataList = response.data['DataList'];
        if (dataList.length > 0) {
            setSelectedTagName({
                label: dataList[0]['TagName'],
                value: dataList[0]['TagName'],
            });
        }
        for (let index=0; index<dataList.length; index++) {
            deviceTagList.push({
                label: dataList[index]['TagName'],
                value: dataList[index]['TagName'],
            });
        }

        setDeviceTagList(deviceTagList);
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function testEmsDeviceDataByTagName(requestPayload, setErrorMessage) {
    let deviceData = await Axios.post(process.env.REACT_APP_API_ADDRESS + emsTestTagNameApiPath, requestPayload).then((response) => {
        if (response.data['Result'] && !response.data['DataList']) {
            setErrorMessage(response.data['Result']);
            return false;
        }
        if (response.data['error_message']) {
            setErrorMessage('能源管理系統忙碌中，請稍後再試！');
            return false;
        }

        return response.data['DataList'];
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });

    return deviceData;
};

const TestDeviceData = {
    getEmsTagList,
    testEmsDeviceDataByTagName,
};

export default TestDeviceData;
