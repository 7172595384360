import Axios from 'axios';
import { randomColor } from 'randomcolor';
import { getElectricCO2e } from './CarbonFactor';


var carbonCalculatePath = '/carbon_calculate';
var batchNumberApiPath = '/batch_number_lists';
var multipleProductCompareApiPath = '/multiple_product_compare_result';
var multipleBatchCompareApiPath = '/multiple_batch_compare_result';
var historyCarbonCo2e = '/lucky_top_history_mes_carbon';
var futureCarbonCo2e = '/lucky_top_predicted_mes_carbon';
var futureCarbonModelCo2e = '/lucky_top_predicted_model_carbon';
var exportProcessCarbonFileApiPath = '/process_carbon_file';


function randomRGB() {
    let randomHexColor = randomColor({luminosity: 'random', hue: 'random', });
    let hex = 16;
    let x = parseInt(randomHexColor[1] + randomHexColor[2], hex);
    let y = parseInt(randomHexColor[3] + randomHexColor[4], hex);
    let z = parseInt(randomHexColor[5] + randomHexColor[6], hex);

    return [x, y, z];
}

export async function getProductCarbonCalculateData(productName, setProductCarbonDataTable, setProductCarbonDataTablePagination, setOriginalChartData, maxElectricCO2e, page=1) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    if (isNaN(Number(maxElectricCO2e))) {
        maxElectricCO2e = maxElectricCO2e['electric_co2e'];
    }

    let chartData = null;
    let size = 50;
    let getCarbonCalculateDataUrl = process.env.REACT_APP_API_ADDRESS + carbonCalculatePath;
    getCarbonCalculateDataUrl += '/' + productName;
    getCarbonCalculateDataUrl += '?page=' + page + '&size=' + size;

    let resultChartData = await Axios.get(getCarbonCalculateDataUrl, requestConfig).then((response) => {
        let responseData = response.data['items'];
        let totalCount = response.data['total'];
        let perRecordSize = response.data['size'];
        let totalPage = Math.ceil(Number(totalCount) / Number(perRecordSize));
        let pages = [];
        for (let index=0; index<totalPage; index++) {
            pages.push(index + 1);
        }

        if (setOriginalChartData === null) {
            setProductCarbonDataTable(responseData);
            setProductCarbonDataTablePagination(pages);

            return false;
        }

        chartData = {};
        let tempTimestamp = 0;
        let timestamps = [];
        let mappedLabels = {};
        let chartLabels = [];
        let chartDatasets = [];
        let chartDataset = {};
        let rgbColor = [];
        let rgbColors = [];
        let isDuplicated = false;
        let backgroundColor = '';
        let borderColor = '';
        let carbonValue = 0;
        let tempDate = '';

        for (let index=0; index<responseData.length; index++) {
            for (let timeIndex=0; timeIndex < responseData[index]['multiple_product_info'].length; timeIndex++) {
                tempTimestamp = new Date(responseData[index]['multiple_product_info'][timeIndex]['rec_date']);
                timestamps.push(tempTimestamp.getTime());
                mappedLabels[String(tempTimestamp.getTime())] = responseData[index]['multiple_product_info'][timeIndex]['rec_date'];
            }
        }

        timestamps.sort((prev, next) => {
            return prev - next;
        });

        for (let index=0; index<timestamps.length; index++) {
            if (!chartLabels.includes(mappedLabels[String(timestamps[index])])) {
                chartLabels.push(mappedLabels[String(timestamps[index])]);
            }
        }

        for (let index=0; index<responseData.length; index++) {
            responseData[index]['carbon_cost_price'] = 0;

            rgbColor = randomRGB();
            isDuplicated = false;
            while (!isDuplicated) {
                for (let rgbColorIndex=0; rgbColorIndex<rgbColors.length; rgbColorIndex++) {
                    if (rgbColors[rgbColorIndex][0] === rgbColor[0] &&
                        rgbColors[rgbColorIndex][1] === rgbColor[1] &&
                        rgbColors[rgbColorIndex][2] === rgbColor[2]) {
                        isDuplicated = true;
                        break;
                    }
                }
                if (!isDuplicated) {
                    rgbColors.push(rgbColor);
                    break;
                }
                if (isDuplicated) {
                    rgbColor = randomRGB();
                    isDuplicated = false;
                }
            }
            backgroundColor = undefined;
            borderColor = undefined;

            chartDataset = {
                label: (responseData[index]['product_number']),
                data: (new Array(chartLabels.length)).fill(null),
                fill: true,
                backgroundColor: backgroundColor,
                borderColor: borderColor,
            };

            for (let recDateIndex=0; recDateIndex<responseData[index]['multiple_product_info'].length; recDateIndex++) {
                tempDate = responseData[index]['multiple_product_info'][recDateIndex]['rec_date'];
                carbonValue = responseData[index]['multiple_product_info'][recDateIndex]['carbon_value'];
                if (chartLabels.indexOf(tempDate) !== -1) {
                    chartDataset.data[chartLabels.indexOf(tempDate)] = carbonValue;
                }
            }

            chartDatasets.push(chartDataset);
        }

        chartData['labels'] = chartLabels;
        chartData['datasets'] = chartDatasets;

        setProductCarbonDataTable(responseData);
        setProductCarbonDataTablePagination(pages);
        setOriginalChartData(JSON.stringify(chartData));

        return chartData;
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });

    return resultChartData;
};

export async function getMultipleBatchCarbonCompare(requestPayload, setChartData) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    let electricCO2e = await getElectricCO2e();
    let maxElectricCO2e = electricCO2e[electricCO2e.length-1]['electric_co2e'] / 1000;

    await Axios.post(process.env.REACT_APP_API_ADDRESS + multipleBatchCompareApiPath, requestPayload, requestConfig).then((response) => {
        let chartData = {
            labels: [],
            datasets: [],
        };
        let responseData = response.data;
        let labels = [];
        let datasets = [];
        let dataset = {};
        let borderColor = '';
        let backgroundColor = '';
        let rgbColor = [];
        let produceKwValueInfo = [];
        let processCarbonValue = 0.0;

        let maxProcessTypeLength = 0;
        for (let index=0; index<responseData.length; index++) {
            if (maxProcessTypeLength < responseData[index]['produce_kw_value_info'].length) {
                maxProcessTypeLength = responseData[index]['produce_kw_value_info'].length;
            }
        }

        for (let index=0; index<responseData.length; index++) {
            rgbColor = randomRGB();
            backgroundColor = undefined;
            borderColor = undefined;

            dataset = {
                label: (responseData[index]['product_name'] + '(' + responseData[index]['product_number'] + ')'),
                data: (new Array(maxProcessTypeLength)).fill(null),
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                fill: true,
            };

            produceKwValueInfo = responseData[index]['produce_kw_value_info'];
            for (let processIndex=0; processIndex<produceKwValueInfo.length-1; processIndex++) {
                if (!labels.includes(produceKwValueInfo[processIndex]['process_type'])) {
                    labels.push(produceKwValueInfo[processIndex]['process_type']);
                }

                let processTypeIndex = labels.indexOf(produceKwValueInfo[processIndex]['process_type']);

                if (produceKwValueInfo[processIndex]['expected_formula']) {
                    for (let formulaIndex=0; formulaIndex<produceKwValueInfo[processIndex]['expected_formula'].length; formulaIndex++) {
                        processCarbonValue += Number(produceKwValueInfo[processIndex]['expected_formula'][formulaIndex].split('=')[1].replace(' (kgCO2e)', ''));
                    }

                    processCarbonValue = processCarbonValue / 1000;
                }
                else {
                    processCarbonValue = Number(produceKwValueInfo[processIndex]['kwatt_value']) * Number(maxElectricCO2e);
                }

                dataset.data[processTypeIndex] = processCarbonValue;
            }

            datasets.push(dataset);
        }

        chartData.labels = labels;
        chartData.datasets = datasets;

        console.log(datasets);

        setChartData(chartData);
    }).catch((error) => {
        console.log(error);

        return error;
    });
}

export async function getMultipleProductCarbonCompare(requestPayload, setChartData) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    await Axios.post(process.env.REACT_APP_API_ADDRESS + multipleProductCompareApiPath, requestPayload, requestConfig).then((response) => {
        let responseData = response.data;
        let chartData = {};
        let tempTimestamp = 0;
        let timestamps = [];
        let mappedLabels = {};
        let chartLabels = [];
        let chartDatasets = [];
        let chartDataset = {};
        let carbonValue = 0;
        let tempDate = '';

        for (let index=0; index<responseData.length; index++) {
            for (let timeIndex=0; timeIndex < responseData[index]['multiple_product_info'].length; timeIndex++) {
                tempTimestamp = new Date(responseData[index]['multiple_product_info'][timeIndex]['rec_date']);
                timestamps.push(tempTimestamp.getTime());
                mappedLabels[String(tempTimestamp.getTime())] = responseData[index]['multiple_product_info'][timeIndex]['rec_date'];
            }
        }

        timestamps.sort((prev, next) => {
            return prev - next;
        });

        for (let index=0; index<timestamps.length; index++) {
            if (!chartLabels.includes(mappedLabels[String(timestamps[index])])) {
                chartLabels.push(mappedLabels[String(timestamps[index])]);
            }
        }

        for (let index=0; index<responseData.length; index++) {
            chartDataset = {
                label: (responseData[index]['product_name'] + '(' + responseData[index]['product_number'] + ')'),
                data: (new Array(chartLabels.length)).fill(null),
                fill: true,
            };

            for (let recDateIndex=0; recDateIndex<responseData[index]['multiple_product_info'].length; recDateIndex++) {
                tempDate = responseData[index]['multiple_product_info'][recDateIndex]['rec_date'];
                carbonValue = responseData[index]['multiple_product_info'][recDateIndex]['carbon_value'];
                if (chartLabels.indexOf(tempDate) !== -1) {
                    chartDataset.data[chartLabels.indexOf(tempDate)] = carbonValue;
                }
            }

            chartDatasets.push(chartDataset);
        }

        chartData['labels'] = chartLabels;
        chartData['datasets'] = chartDatasets;

        setChartData(chartData);
    }).catch((error) => {
        console.log(error);

        return error;
    });
};

export async function getBatchNumberLists(requestPayload, setBatchNumberLists) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    let batchNumberLists = await Axios.post(process.env.REACT_APP_API_ADDRESS + batchNumberApiPath, requestPayload, requestConfig).then((response) => {
        let productNameKey = '';
        let responseData = [];
        let theBatchNumberLists = [];
        let productNameKeys = Object.keys(response.data);
        let groupOption = {};
        for (let index=0; index<productNameKeys.length; index++) {
            productNameKey = productNameKeys[index];
            groupOption = {
                label: productNameKeys[index],
                options: [],
            };
            for (let valueIndex=0; valueIndex<response.data[productNameKey].length; valueIndex++) {
                groupOption.options.push({
                    label: response.data[productNameKey][valueIndex],
                    value: response.data[productNameKey][valueIndex],
                });
                theBatchNumberLists.push(response.data[productNameKey][valueIndex]);
            }
            responseData.push(groupOption);
        }

        setBatchNumberLists(responseData);

        return theBatchNumberLists;
    }).catch((error) => {
        console.log(error);

        return error;
    });

    return batchNumberLists;
};

export async function getHistoryProductCo2e(requestPayload, setChartData) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    await Axios.post(process.env.REACT_APP_API_ADDRESS + historyCarbonCo2e, requestPayload, requestConfig).then((response) => {
        let chartData = {
            labels: [],
            datasets: [],
        };
        let months = ['october_', 'november_', 'december_'];
        let monthsName = ['_10月', '_11月', '_12月'];
        let responseData = response.data;

        let processType = 'furnace';
        let processTypeName = '重力鑄造';
        if (responseData['october_furnace'].length === 0) {
            processType = 'machining';
            processTypeName = '機加工';
        }

        let rgbColors = [];
        let datasets = [];
        let dataset = {};
        let borderColor = '';
        let backgroundColor = '';
        let rgbColor = [];
        let monthKey = '';
        let recDate = '';
        let carbonValue = 0;
        let chartLabels = [];
        let isDuplicated = false;

        for (let monthIndex=0; monthIndex<months.length; monthIndex++) {
            monthKey = months[monthIndex] + processType;
            for (let index=0; index<responseData[monthKey].length; index++) {
                chartLabels.push(responseData[monthKey][index]['rec_date']);
            }
        }

        for (let monthIndex=0; monthIndex<months.length; monthIndex++) {
            monthKey = months[monthIndex] + processType;
            rgbColor = randomRGB();
            isDuplicated = false;
            while (!isDuplicated) {
                for (let rgbColorIndex=0; rgbColorIndex<rgbColors.length; rgbColorIndex++) {
                    if (rgbColors[rgbColorIndex][0] === rgbColor[0] &&
                        rgbColors[rgbColorIndex][1] === rgbColor[1] &&
                        rgbColors[rgbColorIndex][2] === rgbColor[2]) {
                        isDuplicated = true;
                        break;
                    }
                }
                if (!isDuplicated) {
                    rgbColors.push(rgbColor);
                    break;
                }
                if (isDuplicated) {
                    rgbColor = randomRGB();
                    isDuplicated = false;
                }
            }
            backgroundColor = undefined;
            borderColor = undefined;

            dataset = {
                label: (processTypeName + monthsName[monthIndex]),
                data: [],
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                fill: true,
            };

            for (let recDateIndex=0; recDateIndex<responseData[monthKey].length; recDateIndex++) {
                recDate = responseData[monthKey][recDateIndex]['rec_date'];
                carbonValue = responseData[monthKey][recDateIndex]['carbon_value'];
                if (chartLabels.indexOf(recDate) !== -1) {
                    dataset.data[chartLabels.indexOf(recDate)] = carbonValue;
                }
            }

            if (dataset.data.length > 0) {
                datasets.push(dataset);
            }
        }

        chartData.labels = chartLabels;
        chartData.datasets = datasets;

        setChartData(chartData);
    }).catch((error) => {
        console.log(error);

        return error;
    });
};

export async function getFutureProductCo2e(requestPayload, setPredictedChartData, setValidatedMessage, setPredictedProduceNumberTable=null) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    let requestUrl = process.env.REACT_APP_API_ADDRESS + futureCarbonCo2e;
    if (setPredictedProduceNumberTable === null) {
        requestUrl = process.env.REACT_APP_API_ADDRESS + futureCarbonModelCo2e;
    }

    await Axios.post(requestUrl, requestPayload, requestConfig).then((response) => {
        let chartData = {
            labels: [],
            datasets: [],
        };

        let datasets = [];
        let dataset = {};
        let borderColor = '';
        let backgroundColor = '';
        let rgbColor = [];
        let chartLabels = [];

        rgbColor = randomRGB();
        backgroundColor = undefined;
        borderColor = undefined;

        dataset = {
            label: '未來碳排趨勢(start_date~end_date)',
            data: [],
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            fill: true,
            produceNumberData: [],
        };

        for (let predictedIndex=0; predictedIndex<response.data.length; predictedIndex++) {
            if (predictedIndex === 0) {
                dataset.label = dataset.label.replace('start_date', response.data[predictedIndex]['predicted_date']);
            }
            if (predictedIndex === (response.data.length-1)) {
                dataset.label = dataset.label.replace('end_date', response.data[predictedIndex]['predicted_date']);
            }

            chartLabels.push(response.data[predictedIndex]['predicted_date']);
            dataset.data.push(response.data[predictedIndex]['co2e_predict']);
            dataset.produceNumberData.push(response.data[predictedIndex]['produce_numbers']);
        }

        datasets.push(dataset);

        chartData.labels = chartLabels;
        chartData.datasets = datasets;

        setPredictedChartData(chartData);
        if (setPredictedProduceNumberTable != null) {
            setPredictedProduceNumberTable(response.data);
        }
    }).catch((error) => {
        console.log(error);
        if (error.response.data[0]['error']) {
            if (error.response.data[0]['error_message']) {
                setValidatedMessage('Error: ' + error.response.data[0]['error_message']);
            } else {
                setValidatedMessage('Error' + error.response.data[0]['status_code']);
            }
        }

        return error;
    });
};

export async function exportProcessCarbonFile(requestPayload) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    let requestUrl = process.env.REACT_APP_API_ADDRESS + exportProcessCarbonFileApiPath;

    let exportedCsvContents = await Axios.post(requestUrl, requestPayload, requestConfig).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);

        return error;
    });

    return exportedCsvContents;
};

const ProductCarbon = {
    getProductCarbonCalculateData,
    getBatchNumberLists,
    getMultipleProductCarbonCompare,
    getMultipleBatchCarbonCompare,
    getHistoryProductCo2e,
    getFutureProductCo2e,
    exportProcessCarbonFile,
};

export default ProductCarbon;
