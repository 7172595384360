import Axios from 'axios';


var overviewTotalCarbonPath = '/overview_total_carbon';
var overviewProductBatchInfoPath = '/overview_product_batch_info';


export async function getOverviewTotalCarbon() {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };
    let getOverviewTotalCarbonUrl = process.env.REACT_APP_API_ADDRESS + overviewTotalCarbonPath;

    let responseData = await Axios.get(getOverviewTotalCarbonUrl, requestConfig).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });

    return responseData;
};

export async function getOverviewProductBatchInfoCarbon() {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };
    let getOverviewProductBatchInfoUrl = process.env.REACT_APP_API_ADDRESS + overviewProductBatchInfoPath;

    let responseData = await Axios.get(getOverviewProductBatchInfoUrl, requestConfig).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });

    return responseData;
};

const OverviewDashboard = {
    getOverviewTotalCarbon,
    getOverviewProductBatchInfoCarbon,
};

export default OverviewDashboard;
