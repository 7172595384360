import React, { useState } from "react";
import classNames from "classnames";
import { Collapse, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";

const SubMenu = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const toggle = () => setCollapsed(!collapsed);
  const { icon, title, items, activeroute } = props;

  return (
    <div>
      <NavItem
        onClick={toggle}
        className={classNames({ "menu-open": !collapsed })}
      >
        <NavLink className="dropdown-toggle">
          <i className={ icon } />
          {title}
        </NavLink>
      </NavItem>
      <Collapse
        isOpen={!collapsed}
        navbar
        className={classNames("items-menu", { "mb-1": !collapsed })}
      >
        {items.map((item, index) => {
          if (index === items.length-1 && localStorage.getItem('auth_account') !== 'rithai') {
            return null;
          }
          return <NavItem key={index} className="pl-4">
            <NavLink
              tag={Link}
              to={item.layout + item.path}
              className="nav-link"
              active={activeroute(item.path) === 'active'}
            >
              {item.name}
            </NavLink>
          </NavItem>
        })}
      </Collapse>
    </div>
  );
};

export default SubMenu;
