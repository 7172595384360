import Axios from 'axios';


var electricCO2ePath = '/electric_co2e';

export async function getElectricCO2e(setElectricCO2e=null) {
    let electricCO2e = await Axios.get(process.env.REACT_APP_API_ADDRESS + electricCO2ePath).then((response) => {
        if (setElectricCO2e !== null) {
            setElectricCO2e(response.data);
        }

        return response.data;
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });

    return electricCO2e;
};

const CarbonFactor = {
    getElectricCO2e,
};

export default CarbonFactor;
