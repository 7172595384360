import Axios from 'axios';


var loginApiPath = '/login';
var verifiedLoginPath = '/verified';

export function requestLogin(thisState) {
    let requestPayload = {
        'account_name': thisState.state.userName,
        'account_password': thisState.state.userPassword,
    };

    Axios.post(process.env.REACT_APP_API_ADDRESS + loginApiPath, requestPayload).then((response) => {
        let responseJsonString = JSON.stringify(response.data, null, 2);
        if (responseJsonString.includes('failed')) {
            thisState.setState({errorMessage: 'text-danger visible'});
        } else {
            let authSession = response.headers['auth-session'];
            localStorage.setItem('auth_session', authSession);
            localStorage.setItem('auth_account', requestPayload['account_name']);
            thisState.setState({redirectToDashboard: true});
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export function verifiedLogin(thisState) {
    let authSession = localStorage.getItem('auth_session');
    if (authSession === null) {
        thisState.setState({redirectToLogin: true});
        return false;
    }
    let requestPayload = {
        'token': authSession,
    };

    Axios.post(process.env.REACT_APP_API_ADDRESS + verifiedLoginPath, requestPayload).then((response) => {
        let responseJsonString = JSON.stringify(response.data, null, 2);
        if (responseJsonString.includes('valid')) {
            thisState.setState({redirectToDashboard: true});
        } else {
            localStorage.removeItem('auth_session');
            localStorage.removeItem('auth_account');
            thisState.setState({redirectToLogin: true});
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
}

export function verifiedLoginWithSetState(setRedirectToLogin) {
    let authSession = localStorage.getItem('auth_session');
    if (authSession === null) {
        setRedirectToLogin(true);
        return false;
    }
    let requestPayload = {
        'token': authSession,
    };

    Axios.post(process.env.REACT_APP_API_ADDRESS + verifiedLoginPath, requestPayload).then((response) => {
        let responseJsonString = JSON.stringify(response.data, null, 2);
        if (!responseJsonString.includes('valid')) {
            localStorage.removeItem('auth_session');
            localStorage.removeItem('auth_account');
            setRedirectToLogin(true);
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
}

const Login = {
    requestLogin,
    verifiedLogin,
    verifiedLoginWithSetState,
};

export default Login;
