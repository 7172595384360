import React, { useEffect, useState } from "react";
import {
  Redirect,
} from 'react-router';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Spinner,
} from "reactstrap";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { verifiedLoginWithSetState } from 'ajax/LoginRequest';
import { getMachineConfig, updateMachineConfig } from "ajax/MachineConfig";


function MachineConfigDashboard() {
  const [machineConfigTables, setMachineConfigTables] = useState([]);
  const [fileConfigTables, setFileConfigTables] = useState([]);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [configFileSourceModal, setConfigFileSourceModal] = useState(false);
  const [machineScriptListsModal, setMachineScriptListsModal] = useState(false);
  const [editConfigFileSourceModal, setEditConfigFileSourceModal] = useState(false);
  const [fileSourceModal, setFileSourceModal] = useState(false);
  const [fileScriptListsModal, setFileScriptListsModal] = useState(false);
  const [editFileSourceModal, setEditFileSourceModal] = useState(false);
  const [configFileContents, setConfigFileContents] = useState('');
  const [fileContents, setFileContents] = useState('');
  const [codeString, setCodeString] = useState('');
  const [codename, setConfigFileName] = useState('');
  const [editedMessage, setEditedMessage] = useState('');
  const [usedScript, setUsedScript] = useState([]);
  const [loadingDataSpinner, setLoadingDataSpinner] = useState('none');

  const configFileSourceToggle = (e) => {
    setConfigFileSourceModal(!configFileSourceModal);
    setCodeString(e.target.dataset.code);
    setConfigFileName(e.target.dataset.codename);
  };

  const machineScriptListsToggle = (e) => {
    let scriptLists = [];
    if (e.target.dataset.script !== undefined) {
      scriptLists = e.target.dataset.script.split(',');
    }
    setUsedScript(scriptLists);
    setMachineScriptListsModal(!machineScriptListsModal);
  };

  const editConfigFileSourceToggle = (e) => {
    setEditConfigFileSourceModal(!editConfigFileSourceModal);
    setConfigFileContents(e.target.dataset.code);
    setConfigFileName(e.target.dataset.codename);
    setEditedMessage('');
  };

  const fileSourceToggle = (e) => {
    setFileSourceModal(!fileSourceModal);
    setCodeString(e.target.dataset.code);
    setConfigFileName(e.target.dataset.codename);
  };

  const fileScriptListsToggle = (e) => {
    let scriptLists = [];
    if (e.target.dataset.script !== undefined) {
      scriptLists = e.target.dataset.script.split(',');
    }
    setUsedScript(scriptLists);
    setFileScriptListsModal(!fileScriptListsModal);
  };

  const editFileSourceToggle = (e) => {
    setEditFileSourceModal(!editFileSourceModal);
    setFileContents(e.target.dataset.code);
    setConfigFileName(e.target.dataset.codename);
    setEditedMessage('');
  };

  const handleEditedConfig = async () => {
    let requestPayload = {
      'updated_codes': configFileContents,
      'setting_file': codename,
    };

    await updateMachineConfig(requestPayload, setEditedMessage, setConfigFileContents, setCodeString);
    await getMachineConfig(setMachineConfigTables, setFileConfigTables);
  };

  const handleEditedFile = async () => {
    let requestPayload = {
      'updated_codes': fileContents,
      'setting_file': codename,
    };

    await updateMachineConfig(requestPayload, setEditedMessage, setFileContents, setCodeString);
    await getMachineConfig(setMachineConfigTables, setFileConfigTables);
  };

  useEffect(() => {
    setLoadingDataSpinner('inline-block');

    async function verifiedLogin() {
      await verifiedLoginWithSetState(setRedirectToLogin);
    }
    verifiedLogin();

    async function genMachineConfigTable() {
      await getMachineConfig(setMachineConfigTables, setFileConfigTables);

      setLoadingDataSpinner('none');
    }

    genMachineConfigTable();
  }, []);

  if (redirectToLogin) {
    return <Redirect to='/admin/login'/>
  }

  return (
    <>
      <div className="content">
        {
          loadingDataSpinner === 'inline-block' ?
          <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem', display: 'inline-block'}} children={'false'} /> :
          <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">機台串接設定</CardTitle>
              </CardHeader>
              <CardBody>
                <Table>
                  <thead className="text-info">
                    <tr>
                      <th className="th-customize">機台名稱</th>
                      <th className="th-customize">參數設定檔名稱</th>
                      <th className="th-customize">參數設定檔版本</th>
                      <th className="th-customize">會用到的腳本</th>
                      <th className="th-customize">查看設定檔內容</th>
                      <th className="th-customize">編輯設定檔內容</th>
                    </tr>
                  </thead>
                  <tbody>
                    { machineConfigTables.map((item) => {
                      return <tr key={ Number(item['key']) }>
                        <td className="td-customize">{ item['machine_name'] }</td>
                        <td className="td-customize">{ item['config_file_name'] }</td>
                        <td className="td-customize">{ item['config_file_version'] }</td>
                        <td className="td-customize">
                          <Button
                            id={ item['machine_script'] }
                            color="secondary"
                            onClick={ machineScriptListsToggle }
                            data-script={ item['machine_script'] }
                          >
                          查看
                          </Button>
                        </td>
                        <td className="td-customize">
                          <Button
                            id={ item['config_file_name'] }
                            color="primary"
                            onClick={ configFileSourceToggle }
                            data-id={ item['config_file_version'] }
                            data-code={ item['config_file_content'] }
                            data-codename={ item['config_file_name'] }
                          >
                          查看
                          </Button>
                        </td>
                        <td className="td-customize">
                          <Button
                            id={ item['config_file_name'] }
                            color="success"
                            onClick={ editConfigFileSourceToggle }
                            data-id={ item['config_file_version'] }
                            data-code={ item['config_file_content'] }
                            data-codename={ item['config_file_name'] }
                          >
                          編輯
                          </Button>
                        </td>
                      </tr>
                    }) }
                  </tbody>
                  <Modal key="configFileSourceModal" isOpen={configFileSourceModal} toggle={configFileSourceToggle} className="">
                    <ModalHeader toggle={configFileSourceToggle}>{codename}<span className="text-primary">{ '' }</span></ModalHeader>
                      <ModalBody>
                        <Form>
                          <FormGroup>
                          <SyntaxHighlighter language="text" style={dark} showLineNumbers={true}>
                            {codeString}
                          </SyntaxHighlighter>
                          </FormGroup>
                        </Form>
                      </ModalBody>
                      <ModalFooter></ModalFooter>
                  </Modal>
                  <Modal key="machineScriptListsModal" isOpen={machineScriptListsModal} toggle={machineScriptListsToggle} className="">
                    <ModalHeader toggle={machineScriptListsToggle}>會用到的腳本清單<span className="text-primary">{ '' }</span></ModalHeader>
                      <ModalBody>
                        <Form>
                          <FormGroup>
                          <ListGroup>
                            { usedScript.map((item, index) => {
                              return <React.Fragment key={ 'useScript' + index }>
                                <ListGroupItem>{ item }</ListGroupItem>
                                </React.Fragment>
                            }) }
                          </ListGroup>
                          </FormGroup>
                        </Form>
                      </ModalBody>
                      <ModalFooter></ModalFooter>
                  </Modal>
                  <Modal keyboard={ false } backdrop={ "static" } key="editConfigFileSourceModal" isOpen={editConfigFileSourceModal} toggle={editConfigFileSourceToggle} className="">
                    <ModalHeader toggle={editConfigFileSourceToggle}>{ codename }<span className="text-primary">{ '' }</span></ModalHeader>
                      <ModalBody>
                        <Form>
                          <FormGroup>
                          <CodeEditor
                            value={configFileContents}
                            language="text"
                            placeholder="Please enter config contents."
                            onChange={(event) => setConfigFileContents(event.target.value)}
                            padding={15}
                            style={{
                              fontSize: 12,
                              backgroundColor: "#f5f5f5",
                              fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                            }}
                          />
                          </FormGroup>
                          <FormGroup>
                            <h4 className="text-info">{ editedMessage }</h4>
                          </FormGroup>
                        </Form>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={ handleEditedConfig }>更新</Button>{' '}
                      </ModalFooter>
                  </Modal>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">資料傳輸設定</CardTitle>
              </CardHeader>
              <CardBody>
              <Table>
                  <thead className="text-info">
                    <tr>
                      <th className="th-customize">傳輸協定名稱</th>
                      <th className="th-customize">參數設定檔名稱</th>
                      <th className="th-customize">參數設定檔版本</th>
                      <th className="th-customize">會用到的腳本</th>
                      <th className="th-customize">查看設定檔內容</th>
                      <th className="th-customize">編輯設定檔內容</th>
                    </tr>
                  </thead>
                  <tbody>
                    { fileConfigTables.map((item) => {
                      return <tr key={ Number(item['key']) }>
                        <td className="td-customize">{ item['machine_name'] }</td>
                        <td className="td-customize">{ item['config_file_name'] }</td>
                        <td className="td-customize">{ item['config_file_version'] }</td>
                        <td className="td-customize">
                          <Button
                            id={ item['machine_script'] }
                            color="secondary"
                            onClick={ fileScriptListsToggle }
                            data-script={ item['machine_script'] }
                          >
                          查看
                          </Button>
                        </td>
                        <td className="td-customize">
                          <Button
                            id={ item['config_file_name'] }
                            color="primary"
                            onClick={ fileSourceToggle }
                            data-id={ item['config_file_version'] }
                            data-code={ item['config_file_content'] }
                            data-codename={ item['config_file_name'] }
                          >
                          查看
                          </Button>
                        </td>
                        <td className="td-customize">
                          <Button
                            id={ item['config_file_name'] }
                            color="success"
                            onClick={ editFileSourceToggle }
                            data-id={ item['config_file_version'] }
                            data-code={ item['config_file_content'] }
                            data-codename={ item['config_file_name'] }
                          >
                          編輯
                          </Button>
                        </td>
                      </tr>
                    }) }
                  </tbody>
                  <Modal key="fileSourceModal" isOpen={fileSourceModal} toggle={fileSourceToggle} className="">
                    <ModalHeader toggle={fileSourceToggle}>{codename}<span className="text-primary">{ '' }</span></ModalHeader>
                      <ModalBody>
                        <Form>
                          <FormGroup>
                          <SyntaxHighlighter language="text" style={dark} showLineNumbers={true}>
                            {codeString}
                          </SyntaxHighlighter>
                          </FormGroup>
                        </Form>
                      </ModalBody>
                      <ModalFooter></ModalFooter>
                  </Modal>
                  <Modal key="fileScriptListsModal" isOpen={fileScriptListsModal} toggle={fileScriptListsToggle} className="">
                    <ModalHeader toggle={fileScriptListsToggle}>會用到的腳本清單<span className="text-primary">{ '' }</span></ModalHeader>
                      <ModalBody>
                        <Form>
                          <FormGroup>
                          <ListGroup>
                            { usedScript.map((item, index) => {
                              return <React.Fragment key={ 'useScript' + index }>
                                  <ListGroupItem>{ item }</ListGroupItem>
                                </React.Fragment>
                            }) }
                          </ListGroup>
                          </FormGroup>
                        </Form>
                      </ModalBody>
                      <ModalFooter></ModalFooter>
                  </Modal>
                  <Modal key="editFileSourceModal" isOpen={editFileSourceModal} toggle={editFileSourceToggle} className="">
                    <ModalHeader toggle={editFileSourceToggle}>{ codename }<span className="text-primary">{ '' }</span></ModalHeader>
                      <ModalBody>
                        <Form>
                          <FormGroup>
                          <CodeEditor
                            value={fileContents}
                            language="text"
                            placeholder="Please enter config contents."
                            onChange={(event) => setFileContents(event.target.value)}
                            padding={15}
                            style={{
                              fontSize: 12,
                              backgroundColor: "#f5f5f5",
                              fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                            }}
                          />
                          </FormGroup>
                          <FormGroup>
                            <h4 className="text-info">{ editedMessage }</h4>
                          </FormGroup>
                        </Form>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={ handleEditedFile }>更新</Button>{' '}
                      </ModalFooter>
                  </Modal>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        }
      </div>
    </>
  );
}

export default MachineConfigDashboard;
