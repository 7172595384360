import React, { useEffect, useState } from "react";
import {
  Redirect,
} from 'react-router';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Spinner,
  Input,
  Label,
} from "reactstrap";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { verifiedLoginWithSetState } from 'ajax/LoginRequest';
import { getDevice, insertDevice, updateDevice, updateDeviceSettingFile, deleteDevice } from "ajax/DeviceManagement";
import { getEmsTagListFromSensorDatabase } from 'ajax/RithaiDeviceCarbon';


function DeviceManagement() {
  const [deviceManagementTable, setDeviceManagementTable] = useState([]);
  const [originalDeviceManagementTable, setOriginalDeviceManagementTable] = useState([]);

  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [editDeviceModal, setEditDeviceModal] = useState(false);
  const [createDeviceModal, setCreateDeviceModal] = useState(false);
  const [deleteDeviceModal, setDeleteDeviceModal] = useState(false);

  const [deviceSettingContentModal, setDeviceSettingContentModal] = useState(false);
  const [editDeviceSettingContentModal, setEditDeviceSettingContentModal] = useState(false);
  const [codeName, setCodeName] = useState('');
  const [codeString, setCodeString] = useState('');

  const [loadingDataSpinner, setLoadingDataSpinner] = useState('none');
  const [deviceId, setDeviceId] = useState(null);
  const [deviceNumber, setDeviceNumber] = useState('');
  const [deviceName, setDeviceName] = useState('');
  const [deviceBatchNumber, setDeviceBatchNumber] = useState(null);
  const [productName, setProductName] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [statusNumber, setStatusNumber] = useState(0);

  const [searchText, setSearchText] = useState('');
  const [updatedResMessage, setUpdatedResMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [modalLoadingSpinner, setModalLoadingSpinner] = useState(true);

  const [tagListMode, setTagListMode] = useState('manual-input');
  const [selectedTagName, setSelectedTagName] = useState({});
  const [deviceTagList, setDeviceTagList] = useState([]);

  const animatedComponents = makeAnimated();

  const handleSearchTable = (event, deviceData=null) => {
    let searchText = (typeof event === 'string' ? event : event.target.value);
    setSearchKeyword(searchText);

    let theDeviceManagementTable = (deviceData === null ? originalDeviceManagementTable : deviceData);
    if (searchText === '') {
      setDeviceManagementTable(theDeviceManagementTable);
      return false;
    }

    let filteredDeviceManagementTable = [];
    let record = null;
    for (let index=0; index<theDeviceManagementTable.length; index++) {
      record = theDeviceManagementTable[index];
      if (String(record['device_id']).includes(searchText) ||
          record['number'].includes(searchText) ||
          record['name'].includes(searchText) ||
          String(record['batch_number']).includes(searchText) ||
          String(record['product_name']).includes(searchText)
        ) {
          filteredDeviceManagementTable.push(record);
      }
    }

    setDeviceManagementTable(filteredDeviceManagementTable);
  };

  const createDeviceToggle = () => {
    setUpdatedResMessage('');
    setErrorMessage('');
    setDeviceNumber('');
    setDeviceName('');
    setDeviceBatchNumber(1);
    setProductName('');
    setTagListMode('manual-input');

    setCreateDeviceModal(!createDeviceModal);
  };

  const deviceSettingContentToggle = (event) => {
    let deviceNumber = event.target.dataset.devicenumber;
    let settingFileContents = event.target.dataset.settingfilecontents;

    setCodeName(deviceNumber + '.txt');
    setCodeString(settingFileContents);

    setDeviceSettingContentModal(!deviceSettingContentModal);
  };

  const editDeviceSettingContentToggle = (event) => {
    let deviceNumber = event.target.dataset.devicenumber;
    let settingFileContents = event.target.dataset.settingfilecontents;

    setCodeName(deviceNumber + '.txt');
    setCodeString(settingFileContents);

    setEditDeviceSettingContentModal(!editDeviceSettingContentModal);
  };

  const handleEditFileContents = async () => {
    let requestPayload = {
      'setting_file_name': codeName,
      'setting_file_content': codeString,
    };

    setModalLoadingSpinner(false);

    let affectedCount = await updateDeviceSettingFile(requestPayload, setUpdatedResMessage, setErrorMessage, setEditDeviceSettingContentModal, editDeviceSettingContentModal);

    if (typeof affectedCount === 'number' && affectedCount > 0) {
      await getDevice(setDeviceManagementTable, setOriginalDeviceManagementTable);
    }

    setModalLoadingSpinner(true);
  };

  const deleteDeviceToggle = (event) => {
    if (event.target.dataset.deviceid === undefined) {
      setDeleteDeviceModal(!deleteDeviceModal);
      return false;
    }

    setUpdatedResMessage('');
    setErrorMessage('');
    setDeviceId(event.target.dataset.deviceid);

    setDeleteDeviceModal(!deleteDeviceModal);
  };

  const editDeviceToggle = (event) => {
    if (event.target.dataset.deviceid === undefined) {
      setEditDeviceModal(!editDeviceModal);
      return false;
    }

    setUpdatedResMessage('');
    setErrorMessage('');

    setDeviceId(event.target.dataset.deviceid);
    setDeviceNumber(event.target.dataset.devicenumber);
    setDeviceName(event.target.dataset.devicename);
    setDeviceBatchNumber(event.target.dataset.devicebatchnumber);
    setProductName(event.target.dataset.productname);

    setEditDeviceModal(!editDeviceModal);
  };

  const validateDeviceInput = (deviceName, deviceNumber, deviceBatchNumber, productName) => {
    if (deviceName === '') {
      return '請輸入機台裝置名稱！';
    }
    if (deviceNumber === '') {
      return '請輸入機裝置編號！';
    }
    if (isNaN(Number(deviceBatchNumber)) || deviceBatchNumber <= 0) {
      return '請輸入正確的第幾批數字！';
    }
    if (productName === '') {
      return '請輸入產品名稱！';
    }

    return true;
  };

  const handleTagNameChange = (selected) => {
    setSelectedTagName(selected);
    setDeviceNumber(selected.value);
  };

  const handleRetrieveDevice = (e) => {
    setTagListMode('select');
    if (e.target.dataset.listmode !== 'update') {
      if (deviceTagList.length > 0) {
        setDeviceNumber(deviceTagList[0].value);
      }
    }
  };

  const handleCreateDevice = async () => {
    let validatedResult = validateDeviceInput(deviceName, deviceNumber, deviceBatchNumber, productName);
    if (validatedResult !== true) {
      setErrorMessage(validatedResult);
      return false;
    }

    setModalLoadingSpinner(false);

    let requestPayload = {
      'number': deviceNumber,
      'name': deviceName,
      'batch_number': deviceBatchNumber,
      'product_name': productName,
    };

    await insertDevice(requestPayload, setUpdatedResMessage, setErrorMessage, setCreateDeviceModal, createDeviceModal);
    let deviceData = await getDevice(setDeviceManagementTable, setOriginalDeviceManagementTable);
    handleSearchTable(searchKeyword, deviceData);

    setModalLoadingSpinner(true);
  };

  const handleEditedDevice = async () => {
    let validatedResult = validateDeviceInput(deviceName, deviceNumber, deviceBatchNumber, productName);
    if (validatedResult !== true) {
      setErrorMessage(validatedResult);
      return false;
    }

    setModalLoadingSpinner(false);

    let requestPayload = {
      'number': deviceNumber,
      'name': deviceName,
      'batch_number': deviceBatchNumber,
      'device_id': deviceId,
      'product_name': productName,
    };

    await updateDevice(requestPayload, setUpdatedResMessage, setErrorMessage, setEditDeviceModal, editDeviceModal);
    let deviceData = await getDevice(setDeviceManagementTable, setOriginalDeviceManagementTable);
    handleSearchTable(searchKeyword, deviceData);

    setModalLoadingSpinner(true);
  };

  const handleDeleteDevice = async () => {
    setModalLoadingSpinner(false);

    setUpdatedResMessage('');
    setErrorMessage('');

    await deleteDevice(deviceId, setUpdatedResMessage, setErrorMessage, setDeleteDeviceModal, deleteDeviceModal);
    await getDevice(setDeviceManagementTable, setOriginalDeviceManagementTable);

    setModalLoadingSpinner(true);
  };

  useEffect(() => {
    setLoadingDataSpinner('inline-block');

    if (statusNumber > 0) {
    } else {
      async function verifiedLogin() {
        await verifiedLoginWithSetState(setRedirectToLogin);
      }
      verifiedLogin();

      async function genDeviceManagementTable() {
        await getDevice(setDeviceManagementTable, setOriginalDeviceManagementTable);
        await getEmsTagListFromSensorDatabase(setDeviceTagList, setErrorMessage, setSelectedTagName);

        setLoadingDataSpinner('none');
      }

      genDeviceManagementTable();
    }
  }, [statusNumber]);

  if (redirectToLogin) {
    return <Redirect to='/admin/login'/>
  }

  return (
    <>
      <div className="content">
        {
          loadingDataSpinner === 'inline-block' ?
          <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem', display: 'inline-block'}} children={'false'} /> :
          <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  機台與感測器裝置管理{' '}
                  <Button disabled={ loadingDataSpinner === 'none' ? false : true } className="btn-icon-setting" onClick={ createDeviceToggle }><FontAwesomeIcon icon={ faPlus } />新增設定</Button> {' '}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Form onSubmit={ (event) => { event.preventDefault(); } }>
                  <FormGroup>
                    <Input type="text" onChangeCapture={ (event) => handleSearchTable(event) } placeholder="請輸入關鍵字進行查詢..." onChange={ (event) => setSearchText(event.target.value) } value={ searchText || '' } />
                  </FormGroup>
                </Form>
                <Table>
                  <thead className="text-info">
                    <tr>
                      <th className="th-customize">#</th>
                      <th className="th-customize">感測裝置編號</th>
                      <th className="th-customize">對應機台名稱</th>
                      <th className="th-customize">所屬第幾批</th>
                      <th className="th-customize">所屬產品名稱</th>
                      <th className="th-customize">編輯</th>
                      <th className="th-customize">查看設定檔</th>
                      <th className="th-customize">編輯設定檔</th>
                      <th className="th-customize">刪除</th>
                    </tr>
                  </thead>
                  <tbody>
                    { deviceManagementTable.map((item, key) => {
                      return <tr key={ 'device-management-' + key }>
                        <td className="td-customize">{ (key + 1) }</td>
                        <td className="td-customize">{ item['number'] }</td>
                        <td className="td-customize">{ item['name'] }</td>
                        <td className="td-customize">{ item['batch_number'] }</td>
                        <td className="td-customize">{ item['product_name'] }</td>
                        <td className="td-customize">
                          <Button
                            className="btn-setting"
                            id={ 'edit-device-' + key }
                            onClick={ editDeviceToggle }
                            data-deviceid={ item['device_id'] }
                            data-devicenumber={ item['number'] }
                            data-devicename={ item['name'] }
                            data-devicebatchnumber={ item['batch_number'] }
                            data-productname={ item['product_name'] }
                          >
                          編輯
                          </Button>
                        </td>
                        <td className="td-customize">
                          <Button
                            className="btn-setting-add"
                            id={ 'edit-device-content-' + key }
                            onClick={ deviceSettingContentToggle }
                            data-devicenumber={ item['number'] }
                            data-settingfilecontents={ item['setting_file_contents'] }
                          >
                          查看
                          </Button>
                        </td>
                        <td className="td-customize">
                          <Button
                            className="btn-setting-add"
                            id={ 'view-device-content-' + key }
                            onClick={ editDeviceSettingContentToggle }
                            data-devicenumber={ item['number'] }
                            data-settingfilecontents={ item['setting_file_contents'] }
                          >
                          編輯
                          </Button>
                        </td>
                        <td className="td-customize">
                          <Button
                            className="btn-delete"
                            id={ 'delete-device-' + key }
                            onClick={ deleteDeviceToggle }
                            data-deviceid={ item['device_id'] }
                          >
                          刪除
                          </Button>
                        </td>
                      </tr>
                    }) }
                  </tbody>
                  <Modal keyboard={ false } backdrop={ "static" } key="editDeviceModal" isOpen={editDeviceModal} toggle={editDeviceToggle} className="">
                    <ModalHeader toggle={editDeviceToggle}>
                      編輯機台與感測裝置{' '}
                      <Spinner hidden={ modalLoadingSpinner } className="spinner-color" style={{ width: '2rem', height: '2rem', display: 'inline-block'}} children={'false'} />
                    </ModalHeader>
                      <ModalBody>
                        <Form>
                          <FormGroup>
                            <Label for="device-name">請輸入機台設備名稱</Label>
                            <Input name="device-name" value={ deviceName || '' } onChange={ (e) => setDeviceName(e.target.value) } type="text"></Input>
                          </FormGroup>
                          <FormGroup>
                            <Label for="device-number">請輸入感測器裝置編號</Label>
                            <Input name="device-number" value={ deviceNumber || '' } onChange={ (e) => setDeviceNumber(e.target.value) } type="text"></Input>
                          </FormGroup>
                          <FormGroup hidden={ tagListMode === 'manual-input' }>
                            <Label for="select-device-number">請選擇感測器裝置編號</Label>
                            <Select
                              onChange={selected => handleTagNameChange(selected)}
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              value={selectedTagName}
                              options={deviceTagList}
                              placeholder="請選擇能源管理系統TagName..."
                              noOptionsMessage={() => '沒有TagName可以選擇！'}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="batch-number">請輸入所屬第幾批</Label>
                            <Input name="batch-number" value={ deviceBatchNumber || 1 } onChange={ (e) => setDeviceBatchNumber(e.target.value) } type="number"></Input>
                          </FormGroup>
                          <FormGroup>
                            <Label for="product-name">請輸入產品名稱</Label>
                            <Input name="product-name" value={ productName || '' } onChange={ (e) => setProductName(e.target.value) } type="text"></Input>
                          </FormGroup>
                          <FormGroup>
                            <h4 className="text-info">{ updatedResMessage }</h4>
                            <h4 className="text-danger">{ errorMessage }</h4>
                          </FormGroup>
                        </Form>
                      </ModalBody>
                      <ModalFooter className="modal-footer-center">
                        <Button className="text-center" color="primary" onClick={ handleEditedDevice }>更新</Button>{' '}
                        <Button className="text-center" color="secondary" data-listmode="update" onClick={ handleRetrieveDevice }>即時取得裝置清單</Button>{' '}
                      </ModalFooter>
                  </Modal>
                  <Modal keyboard={ false } backdrop={ "static" } key="deviceSettingContentModal" isOpen={deviceSettingContentModal} toggle={deviceSettingContentToggle} className="">
                    <ModalHeader toggle={deviceSettingContentToggle}>{ codeName }<span className="text-primary">{ '' }</span></ModalHeader>
                      <ModalBody>
                        <Form>
                          <FormGroup>
                          <SyntaxHighlighter language="text" style={dark} showLineNumbers={true}>
                            { codeString }
                          </SyntaxHighlighter>
                          </FormGroup>
                        </Form>
                      </ModalBody>
                      <ModalFooter></ModalFooter>
                  </Modal>
                  <Modal keyboard={ false } backdrop={ "static" } key="editDeviceSettingContentModal" isOpen={editDeviceSettingContentModal} toggle={editDeviceSettingContentToggle} className="">
                    <ModalHeader toggle={editDeviceSettingContentToggle}>
                      { codeName }<span className="text-primary">{ ' ' }</span>
                      <Spinner hidden={ modalLoadingSpinner } className="spinner-color" style={{ width: '2rem', height: '2rem', display: 'inline-block'}} children={'false'} />
                    </ModalHeader>
                      <ModalBody>
                        <Form>
                          <FormGroup>
                          <CodeEditor
                            value={ codeString }
                            language="text"
                            placeholder="請輸入設定內容..."
                            onChange={(event) => setCodeString(event.target.value)}
                            padding={15}
                            style={{
                              fontSize: 12,
                              backgroundColor: "#f5f5f5",
                              fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                            }}
                          />
                          </FormGroup>
                          <FormGroup>
                            <h4 className="text-info">{ updatedResMessage }</h4>
                            <h4 className="text-danger">{ errorMessage }</h4>
                          </FormGroup>
                        </Form>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={ handleEditFileContents }>更新</Button>{' '}
                      </ModalFooter>
                  </Modal>
                  <Modal keyboard={ false } backdrop={ "static" } key="deleteDeviceModal" isOpen={deleteDeviceModal} toggle={deleteDeviceToggle} className="">
                    <ModalHeader toggle={deleteDeviceToggle}>
                      確認刪除{' '}
                      <Spinner hidden={ modalLoadingSpinner } className="spinner-color" style={{ width: '2rem', height: '2rem', display: 'inline-block'}} children={'false'} />
                    </ModalHeader>
                      <ModalBody>
                        <Form>
                          <FormGroup>
                            <h4 className="text-danger">{ '確定刪除此感測器與機台對應設定？' }</h4>
                            <h4 className="text-info">{ updatedResMessage }</h4>
                            <h4 className="text-danger">{ errorMessage }</h4>
                          </FormGroup>
                        </Form>
                      </ModalBody>
                      <ModalFooter className="modal-footer-center">
                        <Button className="text-center" color="primary" onClick={ handleDeleteDevice }>確定</Button>{' '}
                      </ModalFooter>
                  </Modal>
                  <Modal keyboard={ false } backdrop={ "static" } key="createDeviceModal" isOpen={createDeviceModal} toggle={createDeviceToggle} className="">
                    <ModalHeader toggle={createDeviceToggle}>
                      新增機台與感測裝置{' '}
                      <Spinner hidden={ modalLoadingSpinner } className="spinner-color" style={{ width: '2rem', height: '2rem', display: 'inline-block'}} children={'false'} />
                    </ModalHeader>
                      <ModalBody>
                        <Form>
                          <FormGroup>
                            <Label for="device-name">請輸入機台設備名稱</Label>
                            <Input name="device-name" value={ deviceName || '' } onChange={ (e) => setDeviceName(e.target.value) } type="text"></Input>
                          </FormGroup>
                          <FormGroup>
                            <Label for="device-number">請輸入感測器裝置編號</Label>
                            <Input name="device-number" value={ deviceNumber || '' } onChange={ (e) => setDeviceNumber(e.target.value) } type="text"></Input>
                          </FormGroup>
                          <FormGroup hidden={ tagListMode === 'manual-input' }>
                            <Label for="select-device-number">請選擇感測器裝置編號</Label>
                            <Select
                              onChange={selected => handleTagNameChange(selected)}
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              value={selectedTagName}
                              options={deviceTagList}
                              placeholder="請選擇能源管理系統TagName..."
                              noOptionsMessage={() => '沒有TagName可以選擇！'}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="batch-number">請輸入所屬第幾批</Label>
                            <Input name="batch-number" value={ deviceBatchNumber || 1 } onChange={ (e) => setDeviceBatchNumber(e.target.value) } type="number"></Input>
                          </FormGroup>
                          <FormGroup>
                            <Label for="product-name">請輸入產品名稱</Label>
                            <Input name="product-name" value={ productName || '' } onChange={ (e) => setProductName(e.target.value) } type="text"></Input>
                          </FormGroup>
                          <FormGroup>
                            <h4 className="text-info">{ updatedResMessage }</h4>
                            <h4 className="text-danger">{ errorMessage }</h4>
                          </FormGroup>
                        </Form>
                      </ModalBody>
                      <ModalFooter className="modal-footer-center">
                        <Button className="text-center" color="primary" onClick={ handleCreateDevice }>送出</Button>{' '}
                        <Button className="text-center" color="secondary" onClick={ handleRetrieveDevice }>即時取得裝置清單</Button>{' '}
                      </ModalFooter>
                  </Modal>
                </Table>
              </CardBody>
            </Card>
          </Col>
          </Row>
        }
      </div>
    </>
  );
}

export default DeviceManagement;
