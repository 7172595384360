import Axios from 'axios';


var machineConfigApiPath = '/machine_config';

export async function getMachineConfig(setMachineConfigTables, setFileConfigTables=null) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
            'X-BatchNumber': localStorage.getItem('batch_number') || 1,
        },
    };

    let responseData = await Axios.get(process.env.REACT_APP_API_ADDRESS + machineConfigApiPath, requestConfig).then((response) => {
        let responseData = [];
        let fileConfigResponseData = [];
        for (let index=0; index<response.data.length; index++) {
            let machineScript = response.data[index][2];
            if (Array.isArray(machineScript)) {
                machineScript = machineScript.join(',');
            }
            let machineName = response.data[index][1];
            let settingKeyword = machineName[machineName.length-2] + machineName[machineName.length-1];
            let record = {
                'key': response.data[index][0],
                'machine_name': response.data[index][1],
                'machine_script': machineScript,
                'config_file_name': response.data[index][3],
                'config_file_content': response.data[index][4],
                'config_file_version': response.data[index][5],
                'sensor_device_name': response.data[index][6],
            };
            if (settingKeyword === '設定') {
                fileConfigResponseData.push(record);
            } else {
                responseData.push(record);
            }
        }

        setMachineConfigTables(responseData);
        if (setFileConfigTables !== null) {
            setFileConfigTables(fileConfigResponseData);
        }

        return responseData;
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });

    return responseData;
};

export async function updateMachineConfig(requestPayload, setEditedMessage, setConfigFileContents, setCodeString) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
        },
    };
    await Axios.put(process.env.REACT_APP_API_ADDRESS + machineConfigApiPath, requestPayload, requestConfig).then((response) => {
        let responseDataMessage = response.data['message'];
        responseDataMessage = responseDataMessage.replace(' is updated', '已經更新');
        responseDataMessage = responseDataMessage.replace('The', '');

        setConfigFileContents(requestPayload['updated_codes']);
        setCodeString(requestPayload['updated_codes']);
        setEditedMessage(responseDataMessage);
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

const MachineConfig = {
    getMachineConfig,
    updateMachineConfig,
};

export default MachineConfig;
