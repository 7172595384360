import Axios from 'axios';


var getCountryListApi = '/getCountry';
var getPriceResultApi = '/getPrice';
var getRealtimePriceApi = '/getRealtimePrice';


export async function getCountryList(setExportedCountry, setCarbonCostSettingForm) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
        },
    };

    await Axios.get(process.env.REACT_APP_API_ADDRESS + getCountryListApi, requestConfig).then((response) => {
        let responseData = response.data.options;
        let exportedCountryLists = [];

        for (let index=0; index<responseData.length; index++) {
            exportedCountryLists.push({
                label: responseData[index]['text'],
                value: responseData[index]['index'],
            });
        }

        setExportedCountry(exportedCountryLists.slice(0, -1));
        setCarbonCostSettingForm([{
            exportedCountryIndex: response.data.options[0]['index'],
            exportedCountryName: response.data.options[0]['text'],
            invalid: false,
            invalidMessage: '',
            inputProducedNumber: 0,
        }]);
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function getCarbonCostPrice(requestPayload) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
        },
    };

    let priceCalculatingApi = process.env.REACT_APP_API_ADDRESS + getPriceResultApi;
    let responseData = await Axios.post(priceCalculatingApi, requestPayload, requestConfig).then((response) => {
        return response.data;
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });

    return responseData;
};

export async function getRealtimeCarbonPrice(requestPayload) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
        },
    };

    let realtimePriceApi = process.env.REACT_APP_API_ADDRESS + getRealtimePriceApi;
    let responseData = await Axios.post(realtimePriceApi, requestPayload, requestConfig).then((response) => {
        return response.data;
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });

    return responseData;
}

const CostCalculator = {
    getCountryList,
    getCarbonCostPrice,
    getRealtimeCarbonPrice,
};

export default CostCalculator;
