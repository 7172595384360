import React, { useEffect, useState } from "react";
import {
  Redirect,
} from 'react-router';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { DatePicker } from 'reactstrap-date-picker';
import TimePicker from 'react-time-picker';
import { verifiedLoginWithSetState } from 'ajax/LoginRequest';
import { getProcessStationByProductName } from 'ajax/Product.js';
import { getErpDataForMes } from 'ajax/NonProcess.js';
import {
  createMesData, getMesData, updateMesData, deleteMesData, createMesApiData,
  getMesApiData, updateMesApiData, deleteMesApiData, testMesApiData,
} from "ajax/MesDataHandler.js";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { formatISO } from "date-fns";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";


function MesDataSetting() {
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [mesMethod, setMesMethod] = useState('');
  const [mesMethodTitle, setMesMethodTitle] = useState('產品製造資料手動填入');
  const [addMesDataModal, setAddMesDataModal] = useState(false);
  const [productName, setProductName] = useState('');
  const [manufactureNumber, setManufactureNumber] = useState('');
  const [mesId, setMesId] = useState('');
  const [produceNumber, setProduceNumber] = useState('');
  const [produceStartDateTime, setProduceStartDateTime] = useState({value: (formatISO(new Date()).split('+')[0] + 'Z')});
  const [produceStartTime, setProduceStartTime] = useState('00:00');
  const [produceEndDateTime, setProduceEndDateTime] = useState({value: (formatISO(new Date()).split('+')[0] + 'Z')});
  const [produceEndTime, setProduceEndTime] = useState('00:00');
  const [updatedResMessage, setUpdatedResMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [mesDataTable, setMesDataTable] = useState([]);
  const [originalMesDataTable, setOriginalMesDataTable] = useState([]);
  const [mesDataEditorModal, setMesDataEditorModal] = useState(false);
  const [deleteMesDataModal, setDeleteMesDataModal] = useState(false);
  const [productLists, setProductLists] = useState([]);

  const [addMesApiSettingModal, setAddMesApiSettingModal] = useState(false);
  const [apiUrl, setApiUrl] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [mappedManufactureNumber, setMappedManufactureNumber] = useState('');
  const [mappedProductName, setMappedProductName]= useState('');
  const [mappedDeviceName, setMappedDeviceName]= useState('');
  const [mappedProduceNumber, setMappedProduceNumber] = useState('');
  const [mappedStartProduceDateTime, setMappedStartProduceDateTime] = useState('');
  const [mappedEndProduceDateTime, setMappedEndProduceDateTime] = useState('');
  const [mesApiDataTable, setMesApiDataTable] = useState([]);
  const [mesApiDataEditorModal, setMesApiDataEditorModal] = useState(false);
  const [deleteMesApiDataModal, setDeleteMesApiDataModal] = useState(false);
  const [mesApiId, setMesApiId] = useState('');
  const [expectedMesApiDataModal, setExpectedMesApiDataModal] = useState(false);
  const [responseJsonString, setResponseJsonString] = useState('');
  const [testMesApiDataModal, setTestMesApiDataModal] = useState(false);
  const [testLoadingSpinner, setTestLoadingSpinner] = useState('none');
  const [testLoadingButtonDisabled, setTestLoadingButtonDisabled] = useState(false);
  const [noProcessMessage, setNoProcessMessage] = useState('');
  const [mesApiDataTablePagination, setMesApiDataTablePagination] = useState([]);
  const [mesDataPage, setMesDataPage] = useState(1);
  const [processType, setProcessType] = useState('');
  const [processTypeLists, setProcessTypeLists] = useState([]);

  const [deviceName, setDeviceName] = useState('');
  const [machineConfigs, setMachineConfigs] = useState({});
  const [statusNumber, setStatusNumber] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loadingMesDataSpinner, setLoadingMesDataSpinner] = useState('none');
  const [searchText, setSearchText] = useState('');
  const [batchNumber, setBatchNumber] = useState(1);


  const handleMesMethodChange = async (selectedValue) => {
    setMesMethod(selectedValue);
    setMesMethodTitle('產品製造資料' + selectedValue);
  };

  const handleProduceStartDateTimeChange = (value, formattedValue) => {
    setProduceStartDateTime({
      value: value,
      formattedValue: formattedValue,
    });
  };

  const handleProcessTypeChange = (selectedValue) => {
    setProcessType(selectedValue);
  };

  const handleProductNameChange = async (productName) => {
    setProductName(productName);
    setTestLoadingSpinner('inline-block');

    let theProcessType = await getProcessStationByProductName(productName, setProcessTypeLists, setMachineConfigs);
    setProcessType(theProcessType[0]['process_type']);

    setTestLoadingSpinner('none');
  };

  const handleSearchTable = (event) => {
    let searchText = event.target.value;
    if (searchText === '') {
      setMesDataTable(originalMesDataTable);
      return false;
    }

    let theMesDataTable = originalMesDataTable;
    let filteredMesDataTable = [];
    let record = null;
    for (let index=0; index<theMesDataTable.length; index++) {
      record = theMesDataTable[index];
      if (record['manufacture_number'].includes(searchText) ||
          record['product_name'].includes(searchText) ||
          record['process_type'].includes(searchText) ||
          String(record['produce_number']).includes(searchText) ||
          record['produce_start_datetime'].includes(searchText) ||
          record['produce_end_datetime'].includes(searchText)
        ) {
        filteredMesDataTable.push(record);
      }
    }

    setMesDataTable(filteredMesDataTable);
  };

  const handleProduceStartTimeChange = (value) => {
    setProduceStartTime(value);
  };

  const handleProduceEndDateTimeChange = (value, formattedValue) => {
    setProduceEndDateTime({
      value: value,
      formattedValue: formattedValue,
    });
  };

  const handleProduceEndTimeChange = (value) => {
    setProduceEndTime(value);
  };

  const handleUpdateMesData = async (event) => {
    await verifiedLoginWithSetState(setRedirectToLogin);
    setErrorMessage('');

    let validatedResult = validateMesData(
      manufactureNumber, produceNumber,
      produceStartDateTime, produceStartTime,
      produceEndDateTime, produceEndTime, batchNumber
    );
    if (validatedResult !== true) {
      setErrorMessage(validatedResult);
      return false;
    }

    let mesDataPage = event.target.dataset.mesdatapage;
    if (mesDataPage === undefined) {
      mesDataPage = 1;
    }

    let processType = event.target.dataset.processtype;
    let productName = event.target.dataset.productname;
    let deviceName = event.target.dataset.devicename;
    let theMachineConfigs = JSON.parse(event.target.dataset.machineconfigs);

    if (deviceName === '') {
      deviceName = theMachineConfigs[Object.keys(theMachineConfigs)[0]][0]['config_file_name'];
    }

    productName = ((productName === undefined || productName === '') ? productLists[0]['process_name'] : productName);
    processType = ((processType === undefined || processType === '') ? processTypeLists[0]['process_type'] : processType);

    let theProduceStartDateTime = (produceStartDateTime.value.split('T')[0] + ' ' + produceStartTime);
    let theProduceEndDateTime = (produceEndDateTime.value.split('T')[0] + ' ' + produceEndTime);

    let requestPayload = {
      'manufacture_number': manufactureNumber,
      'mes_id': mesId,
      'process_name': productName,
      'produce_number': Number(produceNumber),
      'produce_start_datetime': theProduceStartDateTime.replace(/\//g, '-'),
      'produce_end_datetime': theProduceEndDateTime.replace(/\//g, '-'),
      'process_type': processType,
      'device_name': deviceName,
      'batch_number': Number(batchNumber),
    };

    await updateMesData(requestPayload, setUpdatedResMessage, setErrorMessage, setMesDataEditorModal, mesDataEditorModal);
    await getMesData(setOriginalMesDataTable, setMesDataTable, setMesApiDataTablePagination, mesDataPage);

    setStatusNumber(statusNumber + 1);
  };

  const handleDeleteMesData = async (event) => {
    await verifiedLoginWithSetState(setRedirectToLogin);
    setUpdatedResMessage('');
    setErrorMessage('');

    let mesDataPage = event.target.dataset.mesdatapage;
    if (mesDataPage === undefined) {
      mesDataPage = 1;
    }

    await deleteMesData(mesId, setUpdatedResMessage, setErrorMessage, setDeleteMesDataModal, deleteMesDataModal);
    await getMesData(setOriginalMesDataTable, setMesDataTable, setMesApiDataTablePagination, mesDataPage);
  };

  const handleAddMesApiSetting = async () => {
    await verifiedLoginWithSetState(setRedirectToLogin);
    setErrorMessage('');

    let validatedResult = validateMesApiSetting(apiUrl);
    let duplicatedResult = duplicatedMesApiSetting([
      apiUrl, authToken, mappedProductName, mappedDeviceName, mappedManufactureNumber,
      mappedProduceNumber, mappedStartProduceDateTime, mappedEndProduceDateTime,
    ]);

    if (validatedResult !== true) {
      setErrorMessage(validatedResult);
      return false;
    }

    if (duplicatedResult !== true) {
      setErrorMessage(duplicatedResult);
      return false;
    }

    let requestPayload = {
      'url_endpoint': apiUrl,
      'token': authToken,
      'mapped_process_name': mappedProductName,
      'mapped_manufacture_number': mappedManufactureNumber,
      'mapped_produce_number': mappedProduceNumber,
      'mapped_produce_start_datetime': mappedStartProduceDateTime,
      'mapped_produce_end_datetime': mappedEndProduceDateTime,
      'mapped_device_name': mappedDeviceName,
    };

    await createMesApiData(requestPayload, setUpdatedResMessage, setErrorMessage, setAddMesApiSettingModal, addMesApiSettingModal);
    await getMesApiData(setMesApiDataTable);
  };

  const handleMesApiDataTablePagination = async (event) => {
    let text = event.target.dataset.value;
    let currentPage = mesDataPage;

    if (text === '...') {
      text = 11;
    }
    if (text === 'Previous') {
      if ((currentPage - 1) === 0) {
        return false;
      }
      text = Number(currentPage) - 1;
    }
    if (text === 'Next') {
      if ((currentPage + 1) > mesApiDataTablePagination.length) {
        return false;
      }
      text = Number(currentPage) + 1;
    }

    setMesDataPage(Number(text));
    setTestLoadingSpinner('inline-block');

    await getMesData(setOriginalMesDataTable, setMesDataTable, setMesApiDataTablePagination, text);

    setTestLoadingSpinner('none');
  };

  const validateMesApiSetting = (apiUrl) => {
    let url = undefined;
    try {
      url = new URL(apiUrl);
    } catch (Error) {}

    if (url === undefined) {
      return 'API網址：' + apiUrl + ' 格式錯誤！';
    }

    return true;
  };

  const duplicatedMesApiSetting = (requestPayloadArr) => {
    let fieldNames = [
      'API網址', '驗證Token', '工單編號', '產品名稱', '機台設定檔',
      '生產數量', '生產開始時間', '生產結束時間',
    ];
    let errorMessage = true;
    let item = null;

    for (let index=0; index<requestPayloadArr.length; index++) {
      if (index <= 1) {
        continue;
      }

      item = requestPayloadArr[index];
      if (requestPayloadArr.indexOf(item) !== requestPayloadArr.lastIndexOf(item)) {
        errorMessage = fieldNames[index] + '對應的欄位名稱有重複！';
      }
    }

    return errorMessage;
  };

  const handleAddMesData = async (event) => {
    await verifiedLoginWithSetState(setRedirectToLogin);
    setErrorMessage('');

    let validatedResult = validateMesData(
      manufactureNumber, produceNumber,
      produceStartDateTime, produceStartTime,
      produceEndDateTime, produceEndTime, batchNumber
    );
    if (validatedResult !== true) {
      setErrorMessage(validatedResult);
      return false;
    }

    let mesDataPage = event.target.dataset.mesdatapage;
    if (mesDataPage === undefined) {
      mesDataPage = 1;
    }

    let processType = event.target.dataset.processtype;
    let productName = event.target.dataset.productname;
    let deviceName = event.target.dataset.devicename;
    let theMachineConfigs = JSON.parse(event.target.dataset.machineconfigs);

    if (deviceName === '') {
      deviceName = theMachineConfigs[Object.keys(theMachineConfigs)[0]][0]['config_file_name'];
    }

    productName = ((productName === undefined || productName === '') ? productLists[0]['process_name'] : productName);
    processType = ((processType === undefined || processType === '') ? processTypeLists[0]['process_type'] : processType);

    let theProduceStartDateTime = (produceStartDateTime.value.split('T')[0] + ' ' + produceStartTime);
    let theProduceEndDateTime = (produceEndDateTime.value.split('T')[0] + ' ' + produceEndTime);

    let requestPayload = {
      'manufacture_number': manufactureNumber,
      'process_name': productName,
      'produce_number': Number(produceNumber),
      'produce_start_datetime': theProduceStartDateTime.replace(/\//g, '-'),
      'produce_end_datetime': theProduceEndDateTime.replace(/\//g, '-'),
      'process_type': processType,
      'device_name': deviceName,
      'batch_number': Number(batchNumber),
    };

    await createMesData(requestPayload, setUpdatedResMessage, setErrorMessage, setAddMesDataModal, addMesDataModal);
    await getMesData(setOriginalMesDataTable, setMesDataTable, setMesApiDataTablePagination, mesDataPage);
  };

  const validateMesData = (
    manufactureNumber, produceNumber,
    produceStartDateTime, produceStartTime,
    produceEndDateTime, produceEndTime, batchNumber) => {

      if (isNaN(Number(batchNumber)) || batchNumber === '' || Number(batchNumber) <= 0) {
        return '輸入第幾批需要是正整數！';
      }

      if (manufactureNumber === '') {
        return '請輸入工單編號！';
      }

      if (produceNumber === '') {
        return '請輸入生產數量！';
      }

      if (produceStartDateTime.value === '') {
        return '請選擇開始生產日期！';
      }

      if (produceStartTime === '') {
        return '請選擇開始生產時間！';
      }

      if (produceEndDateTime.value === '') {
        return '請選擇結束生產日期！';
      }

      if (produceEndTime === '') {
        return '請選擇結束生產時間！';
      }

      if (isNaN(Number(produceNumber)) === true) {
        return '生產數量需要是數字！';
      }

      if (produceNumber === '0' || produceNumber[0] === '-') {
        return '生產數量需要是大於0的數字！';
      }

      let theProduceStartDateTime = produceStartDateTime.value.split('T')[0] + ' ' + produceStartTime;
      let theProduceEndDateTime = produceEndDateTime.value.split('T')[0] + ' ' + produceEndTime;

      let produceStartTimestamp = Date.parse(theProduceStartDateTime);
      let produceEndTimestamp = Date.parse(theProduceEndDateTime);

      if (produceStartTimestamp === produceEndTimestamp) {
        return '生產開始時間與結束時間應為不同！';
      }

      if (produceStartTimestamp > produceEndTimestamp) {
        return '生產開始時間需比結束時間小！';
      }

      return true;
    };

  const toggle = async (event) => {
    setDeviceName('');
    setManufactureNumber('');
    setProduceNumber('');
    setProduceStartDateTime('');
    setProduceEndDateTime('');
    setProduceStartTime('00:00');
    setProduceEndTime('00:00');
    setUpdatedResMessage('');
    setErrorMessage('');
    setBatchNumber(1);

    setTestLoadingSpinner('inline-block');
    setButtonDisabled(true);

    if (!addMesDataModal) {
      let theProcessType = [];
      if (event.target.dataset.productname === undefined) {
        let productName = productLists[0]['process_name'];
        theProcessType = await getProcessStationByProductName(productName, setProcessTypeLists, setMachineConfigs);
        if (processType === undefined || processType.length === 0) {
          setProcessType(theProcessType[0]['process_type']);
        }
      } else {
        theProcessType = await getProcessStationByProductName(event.target.dataset.productname, setProcessTypeLists, setMachineConfigs);
        if (processType === undefined || processType.length === 0) {
          setProcessType(theProcessType[0]['process_type']);
        }
      }
    }

    setAddMesDataModal(!addMesDataModal);
    setTestLoadingSpinner('none');
    setButtonDisabled(false);
  };

  const mesDataEditorToggle = async (e) => {
    setManufactureNumber(e.target.dataset.manufacturenumber);
    setProductName(e.target.dataset.productname);
    setProcessType(e.target.dataset.processtype);
    setMesId(e.target.dataset.mesid);
    setProduceNumber(e.target.dataset.producenumber);
    setDeviceName(e.target.dataset.devicename);
    setBatchNumber(e.target.dataset.batchnumber);

    if (e.target.dataset.producestartdatetime === undefined) {
      setMesDataEditorModal(!mesDataEditorModal);
      return false;
    }

    let theProduceStartDateTime = e.target.dataset.producestartdatetime.split('T');
    let theProduceEndDateTime = e.target.dataset.produceenddatetime.split('T');

    setProduceStartDateTime({
      value: theProduceStartDateTime[0].replace(/-/g, '/'),
      formattedValue: 'YYYY/MM/DD',
    });
    setProduceEndDateTime({
      value: theProduceEndDateTime[0].replace(/-/g, '/'),
      formattedValue: 'YYYY/MM/DD',
    });
    setProduceStartTime(theProduceStartDateTime[1]);
    setProduceEndTime(theProduceEndDateTime[1]);

    setUpdatedResMessage('');
    setErrorMessage('');

    setTestLoadingSpinner('inline-block');
    setButtonDisabled(true);

    if (!mesDataEditorModal) {
      let theProcessType = await getProcessStationByProductName(e.target.dataset.productname, setProcessTypeLists, setMachineConfigs);
      if (processType === undefined || processType.length === 0) {
        setProcessType(theProcessType[0]['process_type']);
      }
    }

    setTestLoadingSpinner('none');
    setMesDataEditorModal(!mesDataEditorModal);
    setButtonDisabled(false);
  };

  const deleteMesDataToggle = (e) => {
    setErrorMessage('');
    setUpdatedResMessage('');
    setMesId(e.target.dataset.mesid);
    setDeleteMesDataModal(!deleteMesDataModal);
  };

  const mesApiSettingToggle = () => {
    setErrorMessage('');
    setUpdatedResMessage('');
    setApiUrl('');
    setAuthToken('');
    setMappedManufactureNumber('');
    setMappedProductName('');
    setMappedProduceNumber('');
    setMappedStartProduceDateTime('');
    setMappedEndProduceDateTime('');

    setAddMesApiSettingModal(!addMesApiSettingModal);
  };

  const mesApiDataEditorToggle = (e) => {
    setMesApiId(e.target.dataset.mesapiid);
    setApiUrl(e.target.dataset.urlendpoint);
    setAuthToken(e.target.dataset.token);
    setMappedManufactureNumber(e.target.dataset.mappedmanufacturenumber);
    setMappedProductName(e.target.dataset.mappedprocessname);
    setMappedProduceNumber(e.target.dataset.mappedproducenumber);
    setMappedStartProduceDateTime(e.target.dataset.mappedproducestartdatetime);
    setMappedEndProduceDateTime(e.target.dataset.mappedproduceenddatetime);
    setMappedDeviceName(e.target.dataset.mappeddevicename);

    setUpdatedResMessage('');
    setErrorMessage('');
    setMesApiDataEditorModal(!mesApiDataEditorModal);
  };

  const handleUpdateMesApiData = async () => {
    await verifiedLoginWithSetState(setRedirectToLogin);
    setErrorMessage('');
    setUpdatedResMessage('');

    let validatedResult = validateMesApiSetting(apiUrl);
    let duplicatedResult = duplicatedMesApiSetting([
      apiUrl, authToken, mappedProductName, mappedDeviceName, mappedManufactureNumber,
      mappedProduceNumber, mappedStartProduceDateTime, mappedEndProduceDateTime,
    ]);

    if (validatedResult !== true) {
      setErrorMessage(validatedResult);
      return false;
    }

    if (duplicatedResult !== true) {
      setErrorMessage(duplicatedResult);
      return false;
    }

    let requestPayload = {
      'mes_api_id': Number(mesApiId),
      'url_endpoint': apiUrl,
      'token': authToken,
      'mapped_process_name': mappedProductName,
      'mapped_device_name': mappedDeviceName,
      'mapped_manufacture_number': mappedManufactureNumber,
      'mapped_produce_number': mappedProduceNumber,
      'mapped_produce_start_datetime': mappedStartProduceDateTime,
      'mapped_produce_end_datetime': mappedEndProduceDateTime,
    };

    await updateMesApiData(requestPayload, setUpdatedResMessage, setErrorMessage, setMesApiDataEditorModal, mesApiDataEditorModal);
    await getMesApiData(setMesApiDataTable);
  };

  const deleteMesApiDataToggle = (e) => {
    setErrorMessage('');
    setUpdatedResMessage('');
    setMesApiId(e.target.dataset.mesapiid);
    setDeleteMesApiDataModal(!deleteMesApiDataModal);
  };

  const expectedResponseMesApiDataToggle = (e) => {
    setErrorMessage('');
    setUpdatedResMessage('');
    let mappedManufactureNumber = e.target.dataset.mappedmanufacturenumber;
    let mappedProductName = e.target.dataset.mappedprocessname;
    let mappedProduceNumber = e.target.dataset.mappedproducenumber;
    let mappedStartProduceDateTime = e.target.dataset.mappedproducestartdatetime;
    let mappedEndProduceDateTime = e.target.dataset.mappedproduceenddatetime;

    let jsonString = `[
  {
    "mapped_manufacture_number": "工單編號",
    "mapped_product_name": "產品名稱",
    "mapped_produce_number": 100,
    "mapped_device_name": "機台設定檔",
    "mapped_start_produce_datetime": "2023-02-02 08:47",
    "mapped_end_produce_datetime": "2023-02-02 09:50"
  },
  {
    "mapped_manufacture_number": "工單編號",
    "mapped_product_name": "產品名稱",
    "mapped_produce_number": 100,
    "mapped_device_name": "機台設定檔",
    "mapped_start_produce_datetime": "2023-02-02 08:47",
    "mapped_end_produce_datetime": "2023-02-02 09:50"
  }, ......
]`;

    jsonString = jsonString.replace(/mapped_manufacture_number/g, mappedManufactureNumber);
    jsonString = jsonString.replace(/mapped_product_name/g, mappedProductName);
    jsonString = jsonString.replace(/mapped_produce_number/g, mappedProduceNumber);
    jsonString = jsonString.replace(/mapped_start_produce_datetime/g, mappedStartProduceDateTime);
    jsonString = jsonString.replace(/mapped_end_produce_datetime/g, mappedEndProduceDateTime);

    setResponseJsonString(jsonString);

    setExpectedMesApiDataModal(!expectedMesApiDataModal);
  };

  const testMesApiDataToggle = async (e) => {
    if (e.target.dataset.urlendpoint === undefined) {
      setTestLoadingSpinner('none');
      setTestLoadingButtonDisabled(false);
      setTestMesApiDataModal(!testMesApiDataModal);
      return false;
    }

    setTestLoadingButtonDisabled(true);
    setUpdatedResMessage('');
    setErrorMessage('');
    setTestLoadingSpinner('inline-block');

    let requestPayload = {
      'url_endpoint': e.target.dataset.urlendpoint,
      'token': e.target.dataset.token,
      'mapped_process_name': e.target.dataset.mappedprocessname,
      'mapped_device_name': e.target.dataset.mappeddevicename,
      'mapped_manufacture_number': e.target.dataset.mappedmanufacturenumber,
      'mapped_produce_number': e.target.dataset.mappedproducenumber,
      'mapped_produce_start_datetime': e.target.dataset.mappedproducestartdatetime,
      'mapped_produce_end_datetime': e.target.dataset.mappedproduceenddatetime,
    };

    await testMesApiData(requestPayload, setUpdatedResMessage, setErrorMessage);
    setTestMesApiDataModal(!testMesApiDataModal);
    setTestLoadingSpinner('none');
    setTestLoadingButtonDisabled(false);
  };

  const handleDeleteMesApiData = async () => {
    await verifiedLoginWithSetState(setRedirectToLogin);
    setUpdatedResMessage('');
    setErrorMessage('');

    await deleteMesApiData(mesApiId, setUpdatedResMessage, setErrorMessage, setDeleteMesApiDataModal, deleteMesApiDataModal);
    await getMesApiData(setMesApiDataTable);
  };

  useEffect(() => {
    if (statusNumber > 0) {
    } else {
      setTestLoadingSpinner('inline-block');

      async function verifiedLogin() {
        await verifiedLoginWithSetState(setRedirectToLogin);
      }

      verifiedLogin();

      async function genMesDataTable() {
        setLoadingMesDataSpinner('inline-block');
        await getMesData(setOriginalMesDataTable, setMesDataTable, setMesApiDataTablePagination);
        setLoadingMesDataSpinner('none');
      }

      genMesDataTable();

      async function genMesApiDataTable() {
        setLoadingMesDataSpinner('inline-block');
        await getMesApiData(setMesApiDataTable);
        setLoadingMesDataSpinner('none');
      }

      genMesApiDataTable();

      async function genProductAndProcessTypeLists() {
        setLoadingMesDataSpinner('inline-block');
        await getErpDataForMes(setProductLists, setNoProcessMessage);
        setLoadingMesDataSpinner('none');
      }

      setTestLoadingSpinner('none');

      genProductAndProcessTypeLists();
    }
  }, [statusNumber]);

  if (redirectToLogin) {
    return <Redirect to='/admin/login'/>
  }

  return (
    <>
      <div className="content">
        <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem', display: loadingMesDataSpinner}} children={'false'} />
        { loadingMesDataSpinner === 'none' ?
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                { productLists.length <= 0 ? <h4 className="text-danger">{ noProcessMessage }</h4> : <><Input className="w-100" name="mes-method" onChange={event => handleMesMethodChange(event.target.value)} type="select" value={ mesMethod } placeholder="請選擇產品製造資料串接方式">
                  {
                    ['手動填入', 'API串接'].map((item, itemIndex) => {
                      return <option key={'mes-method-' + itemIndex}>{ item }</option>
                    })
                  }
                </Input>
                <CardTitle tag="h4">
                  { mesMethodTitle }{' '}
                  {
                  mesMethodTitle.includes('手動填入') ? <><Button className="btn-icon-setting" data-productname={ productName === '' ? (productLists.length > 0 ? productLists[0]['process_name'] : '') : productName } disabled={ (productLists.length > 0 ? false : true) || buttonDisabled } onClick={toggle}>
                    <FontAwesomeIcon icon={ faPlus }/>新增資料
                  </Button>{' '}
                  <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem', display: testLoadingSpinner}} children={'false'} />
                <Modal keyboard={ false } backdrop={ "static" } key="addMesDataModal" isOpen={addMesDataModal} toggle={toggle} className="">
                  <ModalHeader className="modal-title" toggle={toggle}>新增產品製造資料{' '}
                    <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem', display: testLoadingSpinner}} children={'false'} />
                  </ModalHeader>
                    <ModalBody>
                      <Form onSubmit={ (event) => { event.preventDefault(); } }>
                        <FormGroup>
                          <Label className="modal-form-label" for="manufacture-number">請輸入工單編號</Label>
                          <Input name="manufacture-number" onChange={event => setManufactureNumber(event.target.value)} type="text" value={manufactureNumber || ''} placeholder="請輸入工單編號" />
                        </FormGroup>
                          <Label className="modal-form-label" for="product-name">請選擇產品名稱</Label>
                          <Input name="product-name" onChange={event => handleProductNameChange(event.target.value)} type="select" value={productName || ''} placeholder="請選擇產品名稱">
                          {
                            productLists.map((item, itemIndex) => {
                              return <option key={'product-list-name-' + itemIndex}>{ item['process_name'] }</option>
                            })
                          }
                          </Input><p></p>
                          <Label for="device-name">請選擇裝置設定檔</Label>
                          <Input type="select" name="device-name" onChange={event => setDeviceName(event.target.value)} value={deviceName || ''} placeholder="請選擇裝置設定檔">
                            {
                              machineConfigs[processType] !== undefined ?
                              machineConfigs[processType].map((item, index) => {
                                return <option label={ item['sensor_device_name'] } key={ 'device-name' + index }>{ item['config_file_name'] }</option>
                              }) : <></>
                            }
                          </Input><p></p>
                          <Label className="modal-form-label" for="process-type">請選擇產品線別</Label>
                          <Input name="process-type" onChange={event => handleProcessTypeChange(event.target.value)} type="select" value={processType || ''} placeholder="請選擇產品線別">
                          {
                            processTypeLists.map((item, itemIndex) => {
                              return <option key={'process-type-name-' + itemIndex}>{ item['process_type'] }</option>
                            })
                          }
                          </Input><p></p>
                        <FormGroup>
                          <Label className="modal-form-label" for="produce-number">請輸入生產數量</Label>
                          <Input name="produce-number" onChange={event => setProduceNumber(event.target.value)} type="text" value={produceNumber || ''} placeholder="請輸入生產數量" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="produce-start-date">請選擇生產開始日期</Label>
                          <DatePicker name="produce-start-date" onChange={(value, formattedValue) => handleProduceStartDateTimeChange(value, formattedValue)} dateFormat="YYYY/MM/DD" value={produceStartDateTime.value || ''} placeholder="請選擇生產開始日期" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="produce-start-time">請選擇生產開始時間</Label>{'  '}
                          <TimePicker name="produce-start-time" onChange={event => handleProduceStartTimeChange(event)} value={produceStartTime || ''} placeholder="請選擇生產開始時間" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="produce-end-date">請選擇生產結束日期</Label>
                          <DatePicker name="produce-end-date" onChange={(value, formattedValue) => handleProduceEndDateTimeChange(value, formattedValue)} dateFormat="YYYY/MM/DD" value={produceEndDateTime.value || ''} placeholder="請選擇生產結束日期" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="produce-end-time">請選擇生產結束時間</Label>{'  '}
                          <TimePicker name="produce-end-time" onChange={event => handleProduceEndTimeChange(event)} value={produceEndTime || ''} placeholder="請選擇生產結束時間" />
                        </FormGroup>
                        <FormGroup>
                          <Label for="batch-number">請輸入第幾批</Label>{'  '}
                          <Input type="number" name="batch-number" onChange={event => setBatchNumber(event.target.value)} value={batchNumber || '1'} placeholder="請輸入第幾批" />
                        </FormGroup>
                        <FormGroup>
                          <h4 className="text-info">{ updatedResMessage }</h4>
                          <h5 className="text-danger">{ errorMessage }</h5>
                        </FormGroup>
                      </Form>
                    </ModalBody>
                    <ModalFooter className="modal-footer-center">
                      <Button className="btn-setting"
                        data-productname={ productName }
                        data-processtype={ processType }
                        data-mesdatapage={ mesDataPage }
                        data-devicename={ deviceName }
                        data-machineconfigs={ JSON.stringify(machineConfigs) }
                        onClick={ handleAddMesData }>新增
                      </Button>{' '}
                      <Button color="secondary" onClick={ toggle }>取消</Button>
                    </ModalFooter>
                </Modal></> : <><Button className="btn-icon-setting" disabled={ (mesApiDataTable.length > 0 || productLists.length <= 0) ? true : false } onClick={mesApiSettingToggle}>
                    <FontAwesomeIcon icon={ faPlus }/>新增設定
                  </Button>{' '}
                  <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem', display: testLoadingSpinner}} children={'false'} />
                <Modal keyboard={ false } backdrop={ "static" } key="addMesApiSettingModal" isOpen={addMesApiSettingModal} toggle={mesApiSettingToggle}>
                  <ModalHeader className="modal-title" toggle={mesApiSettingToggle}>新增產品製造API串接設定</ModalHeader>
                    <ModalBody>
                      <Form onSubmit={ (event) => { event.preventDefault(); } }>
                        <FormGroup>
                          <Label className="modal-form-label" for="api-url">請輸入API網址</Label>
                          <Input name="api-url" onChange={event => setApiUrl(event.target.value)} type="text" value={apiUrl || ''} placeholder="請輸入API網址" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="auth-token">請輸入驗證Token(Bearer Basic)</Label>
                          <Input name="auth-token" onChange={event => setAuthToken(event.target.value)} type="text" value={authToken || ''} placeholder="請輸入驗證Token" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="mapped-manufacture-number">請輸入工單編號對應的欄位名稱</Label>
                          <Input name="mapped-manufacture-number" onChange={event => setMappedManufactureNumber(event.target.value)} type="text" value={mappedManufactureNumber || ''} placeholder="請輸入工單編號對應的欄位名稱" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="mapped-product-name">請輸入產品名稱對應的欄位名稱</Label>
                          <Input name="mapped-product-name" onChange={event => setMappedProductName(event.target.value)} value={mappedProductName || ''} placeholder="請輸入產品名稱對應的欄位名稱" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="mapped-device-name">請輸入產品製程對應的機台設定檔</Label>
                          <Input name="mapped-device-name" onChange={event => setMappedDeviceName(event.target.value)} value={mappedDeviceName || ''} placeholder="請輸入產品製程對應的機台設定檔" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="mapped-produce-number">請輸入生產數量對應的欄位名稱</Label>
                          <Input name="mapped-produce-number" onChange={event => setMappedProduceNumber(event.target.value)} value={mappedProduceNumber || ''} placeholder="請輸入生產數量對應的欄位名稱" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="mapped-produce-start-date-time">請輸入生產開始時間對應的欄位名稱</Label>
                          <Input name="mapped-produce-start-date-time" onChange={event => setMappedStartProduceDateTime(event.target.value)} value={mappedStartProduceDateTime || ''} placeholder="請輸入生產開始時間對應的欄位名稱" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="mapped-produce-end-date-time">請輸入生產結束時間對應的欄位名稱</Label>
                          <Input name="mapped-produce-end-date-time" onChange={event => setMappedEndProduceDateTime(event.target.value)} value={mappedEndProduceDateTime || ''} placeholder="請輸入生產結束時間對應的欄位名稱" />
                        </FormGroup>
                        <FormGroup>
                          <h4 className="text-info">{ updatedResMessage }</h4>
                          <h5 className="text-danger">{ errorMessage }</h5>
                        </FormGroup>
                      </Form>
                    </ModalBody>
                    <ModalFooter className="modal-footer-center">
                      <Button className="btn-setting" onClick={ handleAddMesApiSetting }>新增</Button>{' '}
                      <Button className="btn-cancel" onClick={ mesApiSettingToggle }>取消</Button>
                    </ModalFooter>
                </Modal>
                </>
              }
                </CardTitle></>
                }
              </CardHeader>
              <CardBody>
                {
                  (mesMethodTitle.includes('手動填入') && productLists.length > 0) ?
                    <Form onSubmit={ (event) => { event.preventDefault(); } }>
                      <FormGroup>
                        <Input type="text" onChangeCapture={ (event) => handleSearchTable(event) } placeholder="請輸入關鍵字進行查詢..." onChange={ (event) => setSearchText(event.target.value) } value={ searchText || '' } />
                      </FormGroup>
                    </Form> : <></>
                }
                <Pagination style={{display: ((mesMethodTitle.includes('手動填入') && productLists.length > 0) ? '' : 'none')}}>
                  <PaginationItem disabled={ mesDataPage === 1 ? true : false }>
                    <PaginationLink
                      aria-label="Previous"
                      data-value="Previous"
                      onClick={ handleMesApiDataTablePagination }
                    >
                      <i data-value="Previous" className="fa fa-angle-left"></i>
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                    {
                      mesApiDataTablePagination.map((item, index) => {
                        if (item === 11) {
                          return <PaginationItem active={ (mesDataPage >= item && mesDataPage < mesApiDataTablePagination.length) ? true : false } key={item + index}>
                            <PaginationLink  data-value="..." aria-label="..." onClick={ handleMesApiDataTablePagination }>
                              { '...' }
                            </PaginationLink>
                            </PaginationItem>
                        }

                        if (item > 11 && index !== mesApiDataTablePagination.length-1) {
                          return null
                        }

                        return <PaginationItem active={ mesDataPage === item ? true : false } key={item + index}>
                            <PaginationLink data-value={ item } aria-label={ String(item) } onClick={ handleMesApiDataTablePagination }>
                              { item }
                            </PaginationLink>
                            </PaginationItem>
                        })
                      }
                  <PaginationItem disabled={ mesDataPage >= (mesApiDataTablePagination.length) ? true : false }>
                    <PaginationLink
                      aria-label="Next"
                      data-value="Next"
                      onClick={ handleMesApiDataTablePagination }
                    >
                      <i data-value="Next" className="fa fa-angle-right"></i>
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
                {
                  mesMethodTitle.includes('手動填入') ?
                  <Table style={{ display: (productLists.length > 0 ? '' : 'none') }}>
                    {
                    mesDataTable.length > 0 ? <><thead className="text-info">
                    <tr>
                      <th className="th-customize">工單編號</th>
                      <th className="th-customize">產品名稱</th>
                      <th className="th-customize">線別</th>
                      <th className="th-customize">生產數量</th>
                      <th className="th-customize">生產開始時間</th>
                      <th className="th-customize">生產結束時間</th>
                      <th className="th-customize">編輯</th>
                      <th className="th-customize">刪除</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      mesDataTable.map((item, index) => {
                        return <tr key={ item['manufacture_number'] + index }>
                          <td className="td-customize">{ item['manufacture_number'] }</td>
                          {
                            <td className="td-customize">
                                <span style={{ fontWeight: (item['product_name'].length > 7 ? 'bold' : '') }} id={ 'mes_data' + index } data-productname={ item['product_name'] } href="#" target='_blank' rel="noreferrer" >{ item['product_name'].slice(0, 7) }{ item['product_name'].length > 7 ? '...' : '' }</span>
                                {
                                  item['product_name'].length > 7 ?
                                    <ReactTooltip
                                      anchorSelect={ '#mes_data' + index }
                                      place='right'
                                      variant='dark'
                                      float='false'
                                      content={ item['product_name'] }
                                    /> : null
                                }
                            </td>
                          }
                          <td className="td-customize">{ item['process_type'] }</td>
                          <td className="td-customize">{ item['produce_number'] }</td>
                          <td className="td-customize">{ item['produce_start_datetime'] }</td>
                          <td className="td-customize">{ item['produce_end_datetime'] }</td>
                          <td className="td-customize">
                          <Button
                            id={ item['manufacture_number'] + index + 'button' }
                            className="btn-setting"
                            disabled={ buttonDisabled }
                            onClick={ mesDataEditorToggle }
                            data-devicename={ item['device_name'] }
                            data-manufacturenumber={ item['manufacture_number'] }
                            data-mesid={ item['mes_id'] }
                            data-productname={ item['product_name'] }
                            data-processtype={ item['process_type'] }
                            data-producenumber={ item['produce_number'] }
                            data-producestartdatetime={ item['produce_start_datetime'] }
                            data-produceenddatetime={ item['produce_end_datetime'] }
                            data-batchnumber={ item['batch_number'] }
                          >
                          編輯
                          </Button>
                        </td>
                        <td className="td-customize">
                          <Button
                            id={ item['manufacture_number'] + index + 'deleted_button' }
                            className="btn-delete"
                            disabled={ buttonDisabled }
                            onClick={ deleteMesDataToggle }
                            data-mesid={ item['mes_id'] }
                          >
                          <FontAwesomeIcon icon={ faTrashCan } />{ ' ' }
                          刪除
                          </Button>
                        </td>
                        </tr>
                      })
                    }</tbody></> : <></>
                  }
                  <Modal keyboard={ false } backdrop={ "static" } key="mesDataEditorModal" isOpen={ mesDataEditorModal } toggle={ mesDataEditorToggle } className="">
                  <ModalHeader className="modal-title" toggle={mesDataEditorToggle}>{ '修改' } <span className="modal-text">{ manufactureNumber }</span> { '之製造資料' }{' '}
                    <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem', display: testLoadingSpinner}} children={'false'} />
                  </ModalHeader>
                    <ModalBody>
                    <Form onSubmit={ (event) => { event.preventDefault(); } }>
                        <FormGroup>
                          <Label for="manufacture-number">請輸入工單編號</Label>
                          <Input name="manufacture-number" onChange={event => setManufactureNumber(event.target.value)} type="text" value={manufactureNumber || ''} placeholder="請輸入工單編號" />
                        </FormGroup>
                          <Label for="product-name">請選擇產品名稱</Label>
                          <Input name="product-name" onChange={event => handleProductNameChange(event.target.value)} type="select" value={productName || ''} placeholder="請選擇產品名稱">
                          {
                            productLists.map((item, itemIndex) => {
                              return <option key={'product-list-name-' + itemIndex}>{ item['process_name'] }</option>
                            })
                          }
                          </Input><p></p>
                          <Label for="process-type">請選擇產品線別</Label>
                          <Input name="process-type" onChange={event => handleProcessTypeChange(event.target.value)} type="select" value={processType || ''} placeholder="請選擇產品線別">
                          {
                            processTypeLists.map((item, itemIndex) => {
                              return <option key={'process-type-name-' + itemIndex}>{ item['process_type'] }</option>
                            })
                          }
                          </Input><p></p>
                        <FormGroup>
                          <Label for="produce-number">請輸入生產數量</Label>
                          <Input name="produce-number" onChange={event => setProduceNumber(event.target.value)} type="text" value={produceNumber || ''} placeholder="請輸入生產數量" />
                        </FormGroup>
                          <Label for="device-name">請選擇裝置設定檔</Label>
                          <Input type="select" name="device-name" onChange={event => setDeviceName(event.target.value)} value={deviceName || ''} placeholder="請選擇裝置設定檔">
                            {
                              machineConfigs[processType] !== undefined ?
                              machineConfigs[processType].map((item, index) => {
                                return <option label={ item['sensor_device_name'] } key={ 'device-name' + index }>{ item['config_file_name'] }</option>
                              }) : <></>
                            }
                          </Input><p></p>
                        <FormGroup>
                          <Label for="produce-start-date">請選擇生產開始日期</Label>
                          <DatePicker name="produce-start-date" onChange={(value, formattedValue) => handleProduceStartDateTimeChange(value, formattedValue)} dateFormat="YYYY/MM/DD" value={produceStartDateTime.value || ''} placeholder="請選擇生產開始日期" />
                        </FormGroup>
                        <FormGroup>
                          <Label for="produce-start-time">請選擇生產開始時間</Label>{'  '}
                          <TimePicker name="produce-start-time" onChange={event => handleProduceStartTimeChange(event)} value={produceStartTime || ''} placeholder="請選擇生產開始時間" />
                        </FormGroup>
                        <FormGroup>
                          <Label for="produce-end-date">請選擇生產結束日期</Label>
                          <DatePicker name="produce-end-date" onChange={(value, formattedValue) => handleProduceEndDateTimeChange(value, formattedValue)} dateFormat="YYYY/MM/DD" value={produceEndDateTime.value || ''} placeholder="請選擇生產結束日期" />
                        </FormGroup>
                        <FormGroup>
                          <Label for="produce-end-time">請選擇生產結束時間</Label>{'  '}
                          <TimePicker name="produce-end-time" onChange={event => handleProduceEndTimeChange(event)} value={produceEndTime || ''} placeholder="請選擇生產結束時間" />
                        </FormGroup>
                        <FormGroup>
                          <Label for="batch-number">請輸入第幾批</Label>{'  '}
                          <Input type="number" name="batch-number" onChange={event => setBatchNumber(event.target.value)} value={batchNumber || '1'} placeholder="請輸入第幾批" />
                        </FormGroup>
                        <FormGroup>
                          <h4 className="text-info">{ updatedResMessage }</h4>
                          <h5 className="text-danger">{ errorMessage }</h5>
                        </FormGroup>
                      </Form>
                    </ModalBody>
                    <ModalFooter className="modal-footer-center">
                      <Button
                        className="btn-setting"
                        data-productname={ productName }
                        data-processtype={ processType }
                        data-mesdatapage={ mesDataPage }
                        data-devicename={ deviceName }
                        data-machineconfigs={ JSON.stringify(machineConfigs) }
                        onClick={ handleUpdateMesData }>送出
                      </Button>{' '}
                      <Button className="btn-cancel" onClick={ mesDataEditorToggle }>取消</Button>
                    </ModalFooter>
                  </Modal>
                  <Modal key="delMesModal" isOpen={deleteMesDataModal} toggle={deleteMesDataToggle} className="">
                    <ModalHeader className="modal-title" toggle={deleteMesDataToggle}>刪除</ModalHeader>
                      <ModalBody>
                        <Form>
                          <FormGroup>
                            <h5 className="text-danger">確定刪除此產品之製造資料？</h5>
                          </FormGroup>
                          <FormGroup>
                            <h4 className="text-info">{ updatedResMessage }</h4>
                            <h4 className="text-danger">{ errorMessage }</h4>
                          </FormGroup>
                        </Form>
                      </ModalBody>
                      <ModalFooter className="modal-footer-center">
                        <Button className="btn-setting" data-mesdatapage={ mesDataPage } onClick={ handleDeleteMesData }>確定</Button>{' '}
                        <Button className="btn-cancel" onClick={ deleteMesDataToggle }>取消</Button>
                      </ModalFooter>
                  </Modal>
                </Table> : <Table style={{ display: (productLists.length > 0 ? '' : 'none') }}>
                  {
                    mesApiDataTable.length > 0 ? <><thead className="text-info">
                    <tr>
                      <th className="th-customize">編輯</th>
                      <th className="th-customize">刪除</th>
                      <th className="th-customize">預期回應內容</th>
                      <th className="th-customize">測試API</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      mesApiDataTable.map((item, index) => {
                        return <tr key={ item['mes_api_id'] + index }>
                          <td className="td-customize">
                          <Button
                            id={ item['mes_api_id'] + index + 'button' }
                            className="btn-setting"
                            onClick={ mesApiDataEditorToggle }
                            data-mesapiid={ item['mes_api_id'] }
                            data-urlendpoint={ item['url_endpoint'] }
                            data-token={ item['token'] }
                            data-mappedmanufacturenumber={ item['mapped_manufacture_number'] }
                            data-mappedprocessname={ item['mapped_process_name'] }
                            data-mappedproducenumber={ item['mapped_produce_number'] }
                            data-mappedproducestartdatetime={ item['mapped_produce_start_datetime'] }
                            data-mappedproduceenddatetime={ item['mapped_produce_end_datetime'] }
                            data-mappeddevicename={ item['mapped_device_name'] }
                          >
                          編輯
                          </Button>
                        </td>
                        <td className="td-customize">
                          <Button
                            id={ item['mes_api_id'] + index + 'deleted_button' }
                            className="btn-delete"
                            onClick={ deleteMesApiDataToggle }
                            data-mesapiid={ item['mes_api_id'] }
                          >
                          <FontAwesomeIcon icon={ faTrashCan } />{ ' ' }
                          刪除
                          </Button>
                        </td>
                        <td className="td-customize">
                          <Button
                            id={ item['mes_api_id'] + index + 'json_button' }
                            className="btn-setting-add"
                            onClick={ expectedResponseMesApiDataToggle }
                            data-mappedmanufacturenumber={ item['mapped_manufacture_number'] }
                            data-mappedprocessname={ item['mapped_process_name'] }
                            data-mappedproducenumber={ item['mapped_produce_number'] }
                            data-mappedproducestartdatetime={ item['mapped_produce_start_datetime'] }
                            data-mappedproduceenddatetime={ item['mapped_produce_end_datetime'] }
                          >
                          預期回應內容
                          </Button>
                        </td>
                        <td className="td-customize">
                          <Button
                            id={ item['mes_api_id'] + index + 'deleted_button' }
                            disabled={ testLoadingButtonDisabled }
                            className="btn-cancel"
                            onClick={ testMesApiDataToggle }
                            data-urlendpoint={ item['url_endpoint'] }
                            data-token={ item['token'] }
                            data-mappedmanufacturenumber={ item['mapped_manufacture_number'] }
                            data-mappedprocessname={ item['mapped_process_name'] }
                            data-mappedproducenumber={ item['mapped_produce_number'] }
                            data-mappedproducestartdatetime={ item['mapped_produce_start_datetime'] }
                            data-mappedproduceenddatetime={ item['mapped_produce_end_datetime'] }
                            data-mappeddevicename={ item['mapped_device_name'] }
                          >
                          測試API
                          </Button>
                        </td>
                        </tr>
                      })
                    }</tbody></> : <></>
                  }
                  <Modal keyboard={ false } backdrop={ "static" } key="mesApiDataEditorModal" isOpen={ mesApiDataEditorModal } toggle={ mesApiDataEditorToggle }>
                  <ModalHeader className="modal-title" toggle={mesApiDataEditorToggle}>修改製造資料API介接設定</ModalHeader>
                    <ModalBody>
                    <Form onSubmit={ (event) => { event.preventDefault(); } }>
                    <FormGroup>
                          <Label className="modal-form-label" for="api-url">請輸入API網址</Label>
                          <Input name="api-url" onChange={event => setApiUrl(event.target.value)} type="text" value={apiUrl || ''} placeholder="請輸入API網址" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="auth-token">請輸入驗證Token(Bearer Basic)</Label>
                          <Input name="auth-token" onChange={event => setAuthToken(event.target.value)} type="text" value={authToken || ''} placeholder="請輸入驗證Token" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="mapped-manufacture-number">請輸入工單編號對應的欄位名稱</Label>
                          <Input name="mapped-manufacture-number" onChange={event => setMappedManufactureNumber(event.target.value)} type="text" value={mappedManufactureNumber || ''} placeholder="請輸入工單編號對應的欄位名稱" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="mapped-product-name">請輸入產品名稱對應的欄位名稱</Label>
                          <Input name="mapped-product-name" onChange={event => setMappedProductName(event.target.value)} value={mappedProductName || ''} placeholder="請輸入產品名稱對應的欄位名稱" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="mapped-produce-number">請輸入生產數量對應的欄位名稱</Label>
                          <Input name="mapped-produce-number" onChange={event => setMappedProduceNumber(event.target.value)} value={mappedProduceNumber || ''} placeholder="請輸入生產數量對應的欄位名稱" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="mapped-device-name">請輸入生產製程對應的設定檔</Label>
                          <Input name="mapped-device-name" onChange={event => setMappedDeviceName(event.target.value)} value={mappedDeviceName || ''} placeholder="請輸入生產製程對應的設定檔" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="mapped-produce-start-date-time">請輸入生產開始時間對應的欄位名稱</Label>
                          <Input name="mapped-produce-start-date-time" onChange={event => setMappedStartProduceDateTime(event.target.value)} value={mappedStartProduceDateTime || ''} placeholder="請輸入生產開始時間對應的欄位名稱" />
                        </FormGroup>
                        <FormGroup>
                          <Label className="modal-form-label" for="mapped-produce-end-date-time">請輸入生產結束時間對應的欄位名稱</Label>
                          <Input name="mapped-produce-end-date-time" onChange={event => setMappedEndProduceDateTime(event.target.value)} value={mappedEndProduceDateTime || ''} placeholder="請輸入生產結束時間對應的欄位名稱" />
                        </FormGroup>
                        <FormGroup>
                          <h4 className="text-info">{ updatedResMessage }</h4>
                          <h5 className="text-danger">{ errorMessage }</h5>
                        </FormGroup>
                      </Form>
                    </ModalBody>
                    <ModalFooter className="modal-footer-center">
                      <Button className="btn-setting" onClick={ handleUpdateMesApiData }>送出</Button>{' '}
                      <Button className="btn-cancel" onClick={ mesApiDataEditorToggle }>取消</Button>
                    </ModalFooter>
                  </Modal>
                  <Modal key="delMesApiModal" isOpen={deleteMesApiDataModal} toggle={deleteMesApiDataToggle}>
                    <ModalHeader className="modal-title" toggle={deleteMesApiDataToggle}>刪除</ModalHeader>
                      <ModalBody>
                        <Form>
                          <FormGroup>
                            <h5 className="text-danger">確定刪除此製造API串接設定？</h5>
                          </FormGroup>
                          <FormGroup>
                            <h4 className="text-info">{ updatedResMessage }</h4>
                            <h4 className="text-danger">{ errorMessage }</h4>
                          </FormGroup>
                        </Form>
                      </ModalBody>
                      <ModalFooter className="modal-footer-center">
                        <Button className="btn-setting" onClick={ handleDeleteMesApiData }>確定</Button>{' '}
                        <Button className="btn-cancel" onClick={ deleteMesApiDataToggle }>取消</Button>
                      </ModalFooter>
                  </Modal>
                  <Modal key="expectedMesApiModal" isOpen={expectedMesApiDataModal} toggle={expectedResponseMesApiDataToggle}>
                    <ModalHeader className="modal-title" toggle={expectedResponseMesApiDataToggle}>預期回應JSON的內容</ModalHeader>
                      <ModalBody>
                        <Form>
                          <FormGroup>
                            <SyntaxHighlighter language="text" style={dark} showLineNumbers={true}>
                              {responseJsonString}
                            </SyntaxHighlighter>
                          </FormGroup>
                        </Form>
                      </ModalBody>
                      <ModalFooter></ModalFooter>
                  </Modal>
                  <Modal key="testMesApiModal" isOpen={testMesApiDataModal} toggle={testMesApiDataToggle} className="">
                    <ModalHeader className="modal-title" toggle={testMesApiDataToggle}>測試API結果</ModalHeader>
                      <ModalBody>
                        <Form>
                          <FormGroup>
                            {
                              updatedResMessage.split(' ').map((item, index) => {
                                return <p key={'message' + index } className="text-info">{ item }</p>
                              })
                            }
                            {
                              errorMessage.split(' ').map((item, index) => {
                                return <p key={'error_message' + index } className="text-danger">{ item }</p>
                              })
                            }
                          </FormGroup>
                        </Form>
                      </ModalBody>
                      <ModalFooter className="modal-footer-center">
                        <Button className="btn-setting" onClick={ testMesApiDataToggle }>確定</Button>
                      </ModalFooter>
                  </Modal>
                </Table>
                }
              </CardBody>
            </Card>
          </Col>
        </Row> : <></>
      }
      </div>
    </>
  );
}

export default MesDataSetting;
