import React, { useEffect, useState } from "react";
import {
  Redirect,
} from 'react-router';
import { Bar } from 'react-chartjs-2';
import "hw-chartjs-plugin-colorschemes";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { getProduct } from "ajax/Product";
import { verifiedLoginWithSetState } from 'ajax/LoginRequest';
import { getBatchNumberLists, getMultipleBatchCarbonCompare } from "ajax/ProductCarbon";


function MultipleProductCompare() {
  const animatedComponents = makeAnimated();
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [productLists, setProductLists] = useState([]);
  const [batchNumberLists, setBatchNumberLists] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedBatchNumberLists, setSelectedBatchNumberLists] = useState([]);
  const [disableProductLists, setDisableProductLists] = useState(false);
  const [loadingCalculatingSpinner, setLoadingCalculatingSpinner] = useState('none');

  const [chartData, setChartData] = useState({});
  const [statusNumber, setStatusNumber] = useState(0);


  const handleProductListsChange = async (selectedProductLists) => {
    setSelectedProduct(selectedProductLists);

    let requestPayload = {
      'product_lists': [selectedProductLists['value']],
    };

    setLoadingCalculatingSpinner('inline-block');
    setDisableProductLists(true);

    let theBatchNumberLists = await getBatchNumberLists(requestPayload, setBatchNumberLists);
    let firstBatchNumber = theBatchNumberLists.slice(0, 1);

    requestPayload = {
      'product_lists': [selectedProductLists['value']],
      'batch_number_lists': [firstBatchNumber[0]],
    };

    setSelectedBatchNumberLists([{
      label: firstBatchNumber[0],
      value: firstBatchNumber[0],
    }]);

    await getMultipleBatchCarbonCompare(requestPayload, setChartData);
    setStatusNumber(statusNumber + 1);

    setLoadingCalculatingSpinner('none');
    setDisableProductLists(false);
  };

  const handleBatchNumberListsChange = async (selectedValue) => {
    setSelectedBatchNumberLists(selectedValue);

    let requestPayload = {
      'product_lists': [],
      'batch_number_lists': [],
    };

    if (Array.isArray(selectedProduct) && selectedProduct.length === 0) {
      if (productLists.length > 0) {
        requestPayload['product_lists'].push(productLists[0]['value']);
      }
    } else {
      requestPayload['product_lists'].push(selectedProduct['value']);
    }

    for (let index=0; index<selectedValue.length; index++) {
      requestPayload['batch_number_lists'].push(selectedValue[index]['value']);
    }

    await getMultipleBatchCarbonCompare(requestPayload, setChartData);
    setStatusNumber(statusNumber + 1);
  };

  useEffect(() => {
    if (statusNumber > 0) {
    } else {
      async function verifiedLogin() {
        await verifiedLoginWithSetState(setRedirectToLogin);
      }

      verifiedLogin();

      async function genProductLists() {
        let responseData = await getProduct(setProductLists);
        let requestPayload = {};
        let multipleOptions = [];
        for (let index=0; index<responseData.length; index++) {
          multipleOptions.push({
            value: responseData[index]['product_name'],
            label: responseData[index]['product_name'],
          });
        }
        setProductLists(multipleOptions);

        if (Array.isArray(responseData) && responseData.length > 0) {
          setLoadingCalculatingSpinner('inline-block');
          setDisableProductLists(true);

          let theProductLists = [responseData[0]['product_name']];
          requestPayload = {
            'product_lists': theProductLists,
          };
          let theBatchNumberLists = await getBatchNumberLists(requestPayload, setBatchNumberLists);

          requestPayload = {
            'product_lists': theProductLists,
            'batch_number_lists': theBatchNumberLists.slice(0, 1),
          };
          await getMultipleBatchCarbonCompare(requestPayload, setChartData);

          setLoadingCalculatingSpinner('none');
          setDisableProductLists(false);
        }
      }

      genProductLists();
    }
  }, [statusNumber]);

  if (redirectToLogin) {
    return <Redirect to='/admin/login'/>
  }

  return (
    <>
      <div className="content">
      <Row>
          <Col md="12">
            <Card>
            <CardHeader>
                { productLists.length <= 0 ? <h4 className="text-danger">{ '查無產品，請先到『產品製程設定』進行設定！' }</h4> : <></> }
                <Form>
                {
                  productLists.length > 0 ?
                  <FormGroup>
                  <Select
                    isDisabled={ disableProductLists }
                    style={{ display: (productLists.length > 0 ? '' : 'none') }}
                    onChange={event => handleProductListsChange(event)}
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    value={selectedProduct.length === 0 ? {label: productLists[0]['label'], value: productLists[0]['value']} : selectedProduct}
                    options={productLists}
                    placeholder="請選擇產品名稱..."
                    noOptionsMessage={() => '沒有產品選項可以選擇！'}
                  /></FormGroup> : <></>
                }
                {
                  batchNumberLists.length > 0 ?
                  <FormGroup>
                  <Select
                    isDisabled={ disableProductLists }
                    style={{ display: (productLists.length > 0 ? '' : 'none') }}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    value={selectedBatchNumberLists.length === 0 ? {label: batchNumberLists[0]['options'][0]['label'], value: batchNumberLists[0]['options'][0]['value']} : selectedBatchNumberLists}
                    onChange={event => handleBatchNumberListsChange(event)}
                    options={batchNumberLists}
                    placeholder="請選擇產品批號名稱..."
                    noOptionsMessage={() => '沒有產品批號選項可以選擇！'}
                  /></FormGroup> : <></>
                }
                </Form>
                <CardTitle tag="h4">
                  <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem', display: loadingCalculatingSpinner}} children={'false'} />
                </CardTitle>
              </CardHeader>
              <CardBody>
              <div style={{display: ((Object.keys(chartData).length > 0 && chartData.datasets.length > 0) ? '' : 'none'), height: '40vh', position: 'relative', marginBottom: '1%', padding: '1%'}}>
                <Bar data={ chartData } options={
                  {
                    maintainAspectRatio: false,
                    plugins: {
                      colorschemes: {
                        scheme: 'tableau.Tableau20',
                      },
                    },
                    tooltips: {
                      callbacks: {
                        label: (tooltipItem) => {
                          return String(Number(tooltipItem.value));
                        },
                      },
                    },
                    scales: {
                      xAxes: [{
                        scaleLabel: {
                          display: true,
                          labelString: '產品製程',
                        },
                      }],
                      yAxes: [{
                        type: 'logarithmic',
                        scaleLabel: {
                          display: true,
                          labelString: '碳排放量(TCO2e)',
                        },
                      }],
                    }
                  }
                } />
              </div>
              </CardBody>
            </Card>
          </Col>
      </Row>
      </div>
    </>
  )
}

export default MultipleProductCompare;
