import { Component } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import {
  Redirect,
} from 'react-router';
import { requestLogin, verifiedLogin } from 'ajax/LoginRequest';
import '../assets/css/Login.css';


class Login extends Component {
  doLogin(thisState) {
    if (thisState.state.userName === '') {
      thisState.setState({userNameClass: 'text-danger visible'});

      return false;
    }
    if (thisState.state.userPassword === '') {
      thisState.setState({userPasswordClass: 'test-danger visible'});

      return false;
    }

    requestLogin(thisState);
  };

  handleOnKeyDown(thisState, event) {
    if (event.key !== 'Enter') {
      return false;
    }

    if (thisState.state.userName === '') {
      thisState.setState({userNameClass: 'text-danger visible'});

      return false;
    }
    if (thisState.state.userPassword === '') {
      thisState.setState({userPasswordClass: 'test-danger visible'});

      return false;
    }

    requestLogin(thisState);
  }

  constructor(props) {
    super(props);

    this.state = {
      userName: '',
      userNameError: '',
      userNameClass: 'visible',
      userPassword: '',
      userPasswordError: '',
      userPasswordClass: 'visible',
      errorMessage: 'text-danger invisible',
      redirectToDashboard: false,
    };
  };

  componentWillMount() {
    verifiedLogin(this);
  }

  render() {
    let redirectToDashboard = this.state.redirectToDashboard;
    if (redirectToDashboard) {
      if (localStorage.getItem('auth_account') !== 'rithai') {
        return <Redirect to='/admin/product-carbon'/>
      }
      return <Redirect to='/admin/produce-carbon'/>
    }

    return (
        <div className="Login">
            <h2>碳排管理工具使用者登入</h2>
            <Form className="form">
            <FormGroup>
                <Label for="user-name" className={ this.state.userNameClass }>請輸入使用者帳號</Label>
                <Input
                    type="text"
                    name="user-name"
                    id="user-name"
                    placeholder="請輸入使用者帳號"
                    value={ this.state.userName }
                    onChange={ e => this.setState({userName: e.target.value}) }
                />
            </FormGroup>
            <FormGroup>
                <Label for="user-password" className={ this.state.userPasswordClass }>請輸入使用者密碼</Label>
                <Input
                    type="password"
                    name="user-password"
                    id="user-password"
                    placeholder="請輸入使用者密碼"
                    value={ this.state.userPassword }
                    onChange={ e => this.setState({userPassword: e.target.value}) }
                    onKeyDown={ (e) => { return this.handleOnKeyDown(this, e); } }
                />
            </FormGroup>
            <FormGroup>
                <h5
                  onChange={ e => this.setState({errorMessage: e.target.value}) }
                  className={ this.state.errorMessage }>登入失敗!</h5>
            </FormGroup>
            <Button onClick={ () => { return this.doLogin(this); } }>登入</Button>
            </Form>
        </div>
    );
  }
}

export default Login;
