import Axios from 'axios';


var deviceApiPath = '/device';
var deviceContentApiPath = '/device_setting_content';

export async function getDevice(setDeviceManagementTable, setOriginalDeviceManagementTable) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };
    let requestUrl = process.env.REACT_APP_API_ADDRESS + deviceApiPath;

    let responseData = await Axios.get(requestUrl, requestConfig).then((response) => {
        let responseData = [];
        for (let index=0; index<response.data.length; index++) {
            responseData.push({
                'number': response.data[index][0],
                'name': response.data[index][1],
                'batch_number': response.data[index][2],
                'device_id': response.data[index][3],
                'product_name': response.data[index][4],
                'setting_file_contents': response.data[index][5],
            });
        }
        setDeviceManagementTable(responseData);
        setOriginalDeviceManagementTable(responseData);

        return responseData;
    }).catch((error) => {
        console.log(error);
    });

    return responseData;
};

export async function insertDevice(requestPayload, setUpdatedResMessage, setErrorMessage, setModal, modal) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };
    let requestUrl = process.env.REACT_APP_API_ADDRESS + deviceApiPath;

    await Axios.post(requestUrl, requestPayload, requestConfig).then((response) => {
        let responseData = response.data['affected_count'];
        if (typeof responseData === 'number' && responseData > 0) {
            setUpdatedResMessage('新增成功！');
            setTimeout(() => {
                setModal(!modal);
            }, 2000);
        } else {
            setErrorMessage('新增失敗，資料已經存在！');
            console.log(responseData);
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function updateDevice(requestPayload, setUpdatedResMessage, setErrorMessage, setModal, modal) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };
    let requestUrl = process.env.REACT_APP_API_ADDRESS + deviceApiPath;

    await Axios.put(requestUrl, requestPayload, requestConfig).then((response) => {
        let responseData = response.data['affected_count'];
        if (typeof responseData === 'number' && responseData > 0) {
            setUpdatedResMessage('新增成功！');
            setTimeout(() => {
                setModal(!modal);
            }, 2000);
        } else {
            setErrorMessage('新增失敗，資料已經存在！');
            console.log(responseData);
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function deleteDevice(deviceId, setUpdatedResMessage, setErrorMessage, setDeleteDeviceModal, deleteDeviceModal) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    let requestUrl = process.env.REACT_APP_API_ADDRESS + deviceApiPath + '/' + deviceId;

    await Axios.delete(requestUrl, requestConfig).then((response) => {
        let responseData = response.data['affected_count'];
        if (typeof responseData === 'number' && responseData > 0) {
            setUpdatedResMessage('刪除成功！');
            setTimeout(() => {
                setDeleteDeviceModal(!deleteDeviceModal);
            }, 2000);
        } else {
            setErrorMessage('刪除資料失敗！');
            console.log(response.data);
        }
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

export async function updateDeviceSettingFile(requestPayload, setUpdatedResMessage, setErrorMessage, setEditDeviceSettingContentModal, editDeviceSettingContentModal) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
            'X-Account': localStorage.getItem('auth_account'),
        },
    };

    let requestUrl = process.env.REACT_APP_API_ADDRESS + deviceContentApiPath;

    let responseData = await Axios.put(requestUrl, requestPayload, requestConfig).then((response) => {
        let responseData = response.data['affected_count'];
        if (typeof responseData === 'number' && responseData > 0) {
            setUpdatedResMessage('更新成功！');
            setTimeout(() => {
                setEditDeviceSettingContentModal(!editDeviceSettingContentModal);
            }, 2000);
        } else {
            setErrorMessage('更新設定檔失敗！');
            console.log(response.data);
        }
        return responseData;
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });

    return responseData;
};

const DeviceManagement = {
    getDevice,
    insertDevice,
    updateDevice,
    deleteDevice,
    updateDeviceSettingFile,
};

export default DeviceManagement;
