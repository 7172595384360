import React, { useEffect, useState, useRef } from "react";
import {
  Redirect,
} from 'react-router';
import { Line } from 'react-chartjs-2';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Label,
  Table,
  Button,
  Spinner,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  FormGroup,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { formatISO9075 } from "date-fns";
import Select from "react-select";
import { getProduct } from "ajax/Product";
import { getElectricCO2e } from 'ajax/CarbonFactor';
import { verifiedLoginWithSetState } from 'ajax/LoginRequest';
import { getProductCarbonCalculateData } from "ajax/ProductCarbon";
import { getCountryList, getCarbonCostPrice, getRealtimeCarbonPrice } from "ajax/CostCalculator";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import europeanUnionLogo from 'european-union.png';
import moneyBagLogo from 'money-bag.png';
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";


function CostCalculator() {
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [productTables, setProductTables] = useState([]);
  const [disableProductTables, setDisableProductTables] = useState(false);
  const [disableCostSettingFormButton, setDisableCostSettingFormButton] = useState(false);
  const [productName, setProductName] = useState('');
  const [loadingCalculatingSpinner, setLoadingCalculatingSpinner] = useState('none');
  const [calculatingSpinner, setCalculatingSpinner] = useState('none');
  const [electricCo2e, setElectricCo2e] = useState([]);
  const [maxElectricCO2e, setMaxElectricCO2e] = useState({});
  const [productCarbonDataTable, setProductCarbonDataTable] = useState([]);
  const [productCarbonDataTablePagination, setProductCarbonDataTablePagination] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandedRowOpen, setExpandedRowOpen] = useState(false);
  const [carbonDataPage, setCarbonDataPage] = useState(1);
  const [carbonCostSettingForm, setCarbonCostSettingForm] = useState([]);
  const [exportedCountry, setExportedCountry] = useState([]);
  const [statusNumber, setStatusNumber] = useState(0);
  const [settingMessage, setSettingMessage] = useState('');
  const [successSettingMessage, setSuccessSettingMessage] = useState('');
  const nodeRef = useRef(null);
  const carbonTotalCostNodeRef = useRef(null);
  const [cardState, setCardState] = useState(true);
  const [carbonTotalCostCardState, setCarbonTotalCostCardState] = useState(true);
  const [carbonTotalCost, setCarbonTotalCost] = useState(0);
  const [carbonTotalProducedNumber, setCarbonTotalProducedNumber] = useState(0);
  const [realtimeCarbonPrice, setRealtimeCarbonPrice] = useState(0);
  const [realtimeCarbonPriceCurrency, setRealtimeCarbonPriceCurrency] = useState('');
  const [realtimeQueryDateTime, setRealtimeQueryTime] = useState('');
  const [presentCostTrendModal, setPresentCostTrendModal] = useState(false);
  const [costTrendingChartData, setCostTrendingChartData] = useState({});
  const [costTrendingTable, setCostTrendingTable] = useState({});


  const handleInputProducedNumber = (event, itemIndex) => {
    let modifiedCarbonCostSettingForm = carbonCostSettingForm;
    let inputProducedNumber = Number(event.target.value);

    modifiedCarbonCostSettingForm[itemIndex]['invalidMessage'] = '';
    modifiedCarbonCostSettingForm[itemIndex]['invalid'] = false;
    if (isNaN(inputProducedNumber) || inputProducedNumber <= 0) {
      modifiedCarbonCostSettingForm[itemIndex]['invalid'] = true;
      modifiedCarbonCostSettingForm[itemIndex]['invalidMessage'] = '輸入出口產品數量有誤！';
    }

    modifiedCarbonCostSettingForm[itemIndex]['inputProducedNumber'] = event.target.value;

    setStatusNumber(statusNumber + 1);
    setCarbonCostSettingForm(modifiedCarbonCostSettingForm);
  };

  const handleExportedCountryChange = (selectedOption, itemIndex) => {
    let selectedLabel = selectedOption.label;
    let selectedValue = selectedOption.value;

    let modifiedCarbonCostSettingForm = carbonCostSettingForm;

    modifiedCarbonCostSettingForm[itemIndex]['exportedCountryIndex'] = String(selectedValue);
    modifiedCarbonCostSettingForm[itemIndex]['exportedCountryName'] = String(selectedLabel);

    setStatusNumber(statusNumber + 1);
    setCarbonCostSettingForm(modifiedCarbonCostSettingForm);
  };

  const handleAddCarbonCostSetting = () => {
    let modifiedCarbonCostSettingForm = carbonCostSettingForm;
    modifiedCarbonCostSettingForm.push({
      exportedCountryIndex: exportedCountry[0]['value'],
      exportedCountryName: exportedCountry[0]['label'],
      invalid: false,
      inputProducedNumber: 0,
    });

    setStatusNumber(statusNumber + 1);
    setCarbonCostSettingForm(modifiedCarbonCostSettingForm);
  };

  const handleCarbonCostCalculating = async (event) => {
    setSettingMessage('');
    setSuccessSettingMessage('');

    let productCarbonDataTableIndex = Number(event.target.dataset.productcarbondatatableindex);
    let thatTotalTCO2e = Number(event.target.dataset.totaltco2e);
    let produceNumber = Number(event.target.dataset.producenumber);

    let resultProduceNumber = 0;
    for (let index=0; index<carbonCostSettingForm.length; index++) {
      if (carbonCostSettingForm[index]['invalid'] === true) {
        return false;
      }
      if (carbonCostSettingForm[index]['inputProducedNumber'] === 0) {
        setSettingMessage('設定的數量不可以是0！');

        return false;
      }

      resultProduceNumber += Number(carbonCostSettingForm[index]['inputProducedNumber']);
    }

    if (resultProduceNumber > produceNumber) {
      setSettingMessage('設定的數量不可以超過' + produceNumber + '！');

      return false;
    }

    let totalProducedNumber = 0;
    let totalCostPrice = 0;
    let responseData = {};
    let perNumberOfCO2e = thatTotalTCO2e / produceNumber;
    let priceDataSource = 'EEX';
    let requestPayload = {
      source: priceDataSource,
      carbonContent: String(perNumberOfCO2e),
      amount: '',
      country: '',
    };

    setDisableCostSettingFormButton(true);
    setCalculatingSpinner('inline-block');

    for (let index=0; index<carbonCostSettingForm.length; index++) {
      requestPayload = {
        source: priceDataSource,
        carbonContent: String(perNumberOfCO2e),
        amount: carbonCostSettingForm[index]['inputProducedNumber'],
        country: carbonCostSettingForm[index]['exportedCountryIndex'],
      };
      responseData = await getCarbonCostPrice(requestPayload);
      if (responseData.status !== 'Success') {
        setSettingMessage(responseData.message);
        setDisableCostSettingFormButton(false);
        setCalculatingSpinner('none');

        return false;
      }

      carbonCostSettingForm[index]['FreeQuotaArr'] = JSON.stringify(responseData.FreeQuotaArr);
      totalCostPrice += Number(responseData.CostNT_15);
    }

    let modifiedProductCarbonDataTable = productCarbonDataTable;
    let modifiedCarbonCostSettingForm = [];
    modifiedProductCarbonDataTable[productCarbonDataTableIndex]['carbon_cost_price'] = Math.round(totalCostPrice);
    modifiedProductCarbonDataTable[productCarbonDataTableIndex]['carbon_cost_setting_form'] = carbonCostSettingForm;

    totalCostPrice = 0;
    for (let index=0; index<modifiedProductCarbonDataTable.length; index++) {
      if (modifiedProductCarbonDataTable[index]['carbon_cost_price'] !== undefined) {
        totalCostPrice += Number(modifiedProductCarbonDataTable[index]['carbon_cost_price']);
      }
      if (modifiedProductCarbonDataTable[index]['carbon_cost_setting_form'] !== undefined) {
        modifiedCarbonCostSettingForm = modifiedProductCarbonDataTable[index]['carbon_cost_setting_form'];
        for (let formIndex=0; formIndex<modifiedCarbonCostSettingForm.length; formIndex++) {
          totalProducedNumber += Number(modifiedCarbonCostSettingForm[formIndex]['inputProducedNumber']);
        }
      }
    }

    setCarbonCostSettingForm(carbonCostSettingForm);
    setCarbonTotalProducedNumber(totalProducedNumber);
    setCarbonTotalCost(totalCostPrice);
    setCarbonTotalCostCardState(!carbonTotalCostCardState);
    setDisableCostSettingFormButton(false);
    setCalculatingSpinner('none');
    setProductCarbonDataTable(modifiedProductCarbonDataTable);
    setSuccessSettingMessage('設定成功！');
    setStatusNumber(statusNumber + 1);
  };

  const handleDeleteCostSetting = (event) => {
    let itemIndex = event.target.dataset.itemindex;
    let modifiedCarbonCostSettingForm = carbonCostSettingForm;
    if (modifiedCarbonCostSettingForm.length === 1) {
      return false;
    }

    let newCarbonCostSettingForm = [];
    let newIndex = 0;
    for (let index=0; index<modifiedCarbonCostSettingForm.length; index++) {
      if (index === Number(itemIndex)) {
        continue;
      }
      newCarbonCostSettingForm.push(modifiedCarbonCostSettingForm[newIndex]);
      newIndex += 1;
    }

    setStatusNumber(statusNumber + 1);
    setCarbonCostSettingForm(newCarbonCostSettingForm);
  };

  const handlePresentTrending = (event) => {
    let itemIndex = event.target.dataset.itemindex;
    if (itemIndex === undefined) {
      setPresentCostTrendModal(!presentCostTrendModal);

      return false;
    }

    let resultTrendingChartData = {
      labels: [],
      datasets: [],
    };

    let data = [];
    let trendingTable = {
      'th': ['年度'],
      'td': ['台幣'],
    };
    let freeQuotaArr = JSON.parse(carbonCostSettingForm[itemIndex]['FreeQuotaArr']);

    for (let index=0; index<freeQuotaArr.length; index++) {
      resultTrendingChartData.labels.push(freeQuotaArr[index]['Year']);
      trendingTable.th.push(freeQuotaArr[index]['Year']);
      trendingTable.td.push(freeQuotaArr[index]['CostNT']);
      data.push(freeQuotaArr[index]['CostNT']);
    }

    resultTrendingChartData.datasets.push({
      label: '逐年預估成本(台幣)',
      data: data,
      fill: true,
    });

    setPresentCostTrendModal(!presentCostTrendModal);
    setCostTrendingChartData(resultTrendingChartData);
    setCostTrendingTable(trendingTable);
  };

  const handleProductListsChange = async (selectedValue) => {
    setProductName(selectedValue);

    setLoadingCalculatingSpinner('inline-block');
    setDisableProductTables(true);

    await getProductCarbonCalculateData(selectedValue, setProductCarbonDataTable, setProductCarbonDataTablePagination, null, maxElectricCO2e);

    setLoadingCalculatingSpinner('none');
    setDisableProductTables(false);
  };

  const handleCarbonCostSetting = (event) => {
    setSettingMessage('');
    setSuccessSettingMessage('');
    setLoadingCalculatingSpinner('none');

    let productNumber = event.target.dataset.productnumber;
    let productCarbonDataTableIndex = event.target.dataset.productcarbondatatableindex;
    let currentCarbonCostSettingForm = productCarbonDataTable[productCarbonDataTableIndex]['carbon_cost_setting_form'];

    if (currentCarbonCostSettingForm === undefined) {
      setCarbonCostSettingForm([{
        exportedCountryIndex: exportedCountry[0]['value'],
        exportedCountryName: exportedCountry[0]['label'],
        invalid: false,
        invalidMessage: '',
        inputProducedNumber: 0,
      }]);
    } else {
      setCarbonCostSettingForm(currentCarbonCostSettingForm);
    }

    if (expandedRows.length === 0) {
      setExpandedRows([productNumber]);
      setExpandedRowOpen(!expandedRowOpen);
      return false;
    }

    if (expandedRows[0] === productNumber) {
      setExpandedRows([]);
      setExpandedRowOpen(!expandedRowOpen);
      return false;
    }

    setExpandedRowOpen(!expandedRowOpen);
    setExpandedRows([productNumber]);
    setExpandedRowOpen(!!expandedRowOpen);
    setStatusNumber(statusNumber + 1);
  };

  const handleCalculateCarbonDataTablePagination = async (event) => {
    let text = event.target.dataset.value;
    let currentPage = carbonDataPage;

    if (text === '...') {
      text = 11;
    }
    if (text === 'Previous') {
      if ((currentPage - 1) === 0) {
        return false;
      }
      text = Number(currentPage) - 1;
    }
    if (text === 'Next') {
      if ((currentPage + 1) > productCarbonDataTablePagination.length) {
        return false;
      }
      text = Number(currentPage) + 1;
    }

    setCarbonDataPage(Number(text));

    setLoadingCalculatingSpinner('inline-block');
    setDisableProductTables(true);
    await getProductCarbonCalculateData(productName, setProductCarbonDataTable, setProductCarbonDataTablePagination, null, maxElectricCO2e, text);
    setLoadingCalculatingSpinner('none');
    setDisableProductTables(false);
  };

  useEffect(() => {
    if (statusNumber > 0) {
    } else {

      async function verifiedLogin() {
        await verifiedLoginWithSetState(setRedirectToLogin);
      }

      verifiedLogin();

      async function genRealtimeCarbonPrice() {
        let requestPayload = {
          type: 'CBAM',
        };
        let responseData = await getRealtimeCarbonPrice(requestPayload);
        if (responseData.status === 'Success') {
          setRealtimeCarbonPrice(responseData.price);
          setRealtimeCarbonPriceCurrency(responseData.currency);
          setRealtimeQueryTime(formatISO9075(new Date()));
        }

        setCardState(false);
      }

      genRealtimeCarbonPrice();

      async function genProductLists() {
        let responseData = await getProduct(setProductTables);
        if (Array.isArray(responseData) && responseData.length > 0) {
          setLoadingCalculatingSpinner('inline-block');
          let electricCO2e = await getElectricCO2e(setElectricCo2e);
          let maxElectricCO2e = undefined;
          let maxElectricCO2eYear = undefined;
          for (let index=0; index<electricCO2e.length; index++) {
            if (maxElectricCO2e === undefined) {
              maxElectricCO2eYear = electricCO2e[index]['year'];
              maxElectricCO2e = electricCO2e[index]['electric_co2e'];
            }
            if (maxElectricCO2eYear < electricCO2e[index]['year']) {
              maxElectricCO2eYear = electricCO2e[index]['year'];
              maxElectricCO2e = electricCO2e[index]['electric_co2e'];
            }
          }
          setMaxElectricCO2e({'year': maxElectricCO2eYear, 'electric_co2e': maxElectricCO2e});
          setDisableProductTables(true);

          await getProductCarbonCalculateData(responseData[0]['product_name'], setProductCarbonDataTable, setProductCarbonDataTablePagination, null, maxElectricCO2e);
          await getCountryList(setExportedCountry, setCarbonCostSettingForm);

          setLoadingCalculatingSpinner('none');
          setDisableProductTables(false);
        }
      }

      genProductLists();
    }
  }, [statusNumber]);

  if (redirectToLogin) {
    return <Redirect to='/admin/login'/>
  }

  return (
    <>
      <div className="content">
      <Row>
        <Col md="9" lg="6">
        <SwitchTransition mode={ 'out-in' }>
          <CSSTransition
            key={ cardState }
            nodeRef={ nodeRef }
            addEndListener={(done) => {
              nodeRef.current.addEventListener("transitionend", done, false);
            }}
            classNames="fade"
          >
          <div ref={ nodeRef }>
          <Card className="card-stats fade-card">
            <CardBody style={{padding: '20px 30px 0px', }}>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-success">
                    <img src={ europeanUnionLogo } alt="background" />
                  </div>
                </Col>
                <Col className="mb-1">
                  <div className="numbers text-right">
                    <p>
                        <span className="logo-title-text">{ 'EU ETS 最新價格' }</span>
                    </p>
                    <p className="c-blue2 fs-1-5">
                      { new Intl.NumberFormat('zh-TW').format(realtimeCarbonPrice) }{' '}
                      <span className="card-money-signal">{ '(' + realtimeCarbonPriceCurrency + ')' }</span>
                    </p>
                      </div>
                    </Col>
                  </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats" style={{
                fontSize: '14px',
                fontWeight: 500,
                color: '#86939e',
              }}>
                查詢時間：{ realtimeQueryDateTime }
              </div>
            </CardFooter>
          </Card>
          </div>
          </CSSTransition>
        </SwitchTransition>
        </Col>
        <Col md="9" lg="6">
        <SwitchTransition mode={ 'out-in' }>
          <CSSTransition
            key={ carbonTotalCostCardState }
            nodeRef={ carbonTotalCostNodeRef }
            addEndListener={(done) => {
              carbonTotalCostNodeRef.current.addEventListener("transitionend", done, false);
            }}
            classNames="fade"
          >
          <div ref={ carbonTotalCostNodeRef }>
          <Card className="card-stats fade-card">
            <CardBody style={{padding: '20px 30px 0px', }}>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-success">
                    <img src={ moneyBagLogo } alt="background" />
                  </div>
                </Col>
                <Col className="mb-1">
                  <div className="numbers text-right">
                    <p>
                        <span className="logo-title-text">{ '碳排總成本' }</span>
                    </p>
                    <p className="c-blue2 fs-1-5">
                      { new Intl.NumberFormat('zh-TW').format(carbonTotalCost) }{' '}
                      <span className="card-money-signal">(NTD)</span>
                    </p>
                      </div>
                    </Col>
                  </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats" style={{
                fontSize: '14px',
                fontWeight: 500,
                color: '#86939e',
              }}>
                出口總數：{ carbonTotalProducedNumber }
              </div>
            </CardFooter>
          </Card>
          </div>
          </CSSTransition>
        </SwitchTransition>
        </Col>
        <Col md="12">
          <Card>
            <CardHeader>
                { productTables.length <= 0 ? <h4 className="text-danger">{ '查無產品，請先到『產品製程設定』進行設定！' }</h4> : <></> }
                <Input style={{ display: (productTables.length > 0 ? '' : 'none') }} disabled={ disableProductTables || disableCostSettingFormButton } className="w-100" name="product-lists" onChange={event => handleProductListsChange(event.target.value)} type="select" value={ productName } placeholder="請選擇產品">
                  {
                    productTables.map((item, itemIndex) => {
                      if (productName === '' && itemIndex === 0) {
                        setProductName(item['product_name']);
                      }

                      return <option key={'product-name-' + itemIndex}>{ item['product_name'] }</option>
                    })
                  }
                </Input>
                <CardTitle tag="h4">
                  <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem', display: loadingCalculatingSpinner}} children={'false'} />
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Pagination style={{display: (loadingCalculatingSpinner === 'none' && productTables.length > 0) ? '' : 'none'}}>
                  <PaginationItem disabled={ carbonDataPage === 1 || disableCostSettingFormButton }>
                    <PaginationLink
                      aria-label="Previous"
                      data-value="Previous"
                      onClick={ handleCalculateCarbonDataTablePagination }
                    >
                      <i data-value="Previous" className="fa fa-angle-left"></i>
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                    {
                      productCarbonDataTablePagination.map((item, index) => {
                        if (item === 11) {
                          return <PaginationItem disabled={ disableCostSettingFormButton } active={ (carbonDataPage >= item && carbonDataPage < productCarbonDataTablePagination.length) ? true : false } key={item + index}>
                            <PaginationLink  data-value="..." aria-label="..." onClick={ handleCalculateCarbonDataTablePagination }>
                              { '...' }
                            </PaginationLink>
                            </PaginationItem>
                        }

                        if (item > 11 && index !== productCarbonDataTablePagination.length-1) {
                          return null
                        }

                        return <PaginationItem disabled={ disableCostSettingFormButton } active={ carbonDataPage === item ? true : false } key={item + index}>
                            <PaginationLink data-value={ item } aria-label={ String(item) } onClick={ handleCalculateCarbonDataTablePagination }>
                              { item }
                            </PaginationLink>
                            </PaginationItem>
                        })
                      }
                  <PaginationItem disabled={ carbonDataPage >= (productCarbonDataTablePagination.length) || disableCostSettingFormButton }>
                    <PaginationLink
                      aria-label="Next"
                      data-value="Next"
                      onClick={ handleCalculateCarbonDataTablePagination }
                    >
                      <i data-value="Next" className="fa fa-angle-right"></i>
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
                <Table style={{display: loadingCalculatingSpinner === 'none' ? '' : 'none'}}>
                  {
                    productCarbonDataTable.length > 0 ? <><thead className="text-info">
                    <tr>
                      <th className="th-customize">產品製造批號</th>
                      <th className="th-customize">生產數量</th>
                      <th className="th-customize">生產開始時間</th>
                      <th className="th-customize">生產結束時間</th>
                      <th className="th-customize">該批總碳排量({'tCO2e'})</th>
                      <th className="th-customize">碳排成本(NT)</th>
                      <th className="th-customize">數量設定</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      productCarbonDataTable.map((item, index) => {
                        return <React.Fragment key={ item['product_number'] + index }>
                        <tr>
                          <td className="td-customize">
                            <span style={{ fontWeight: (item['product_number'].length > 7 ? 'bold' : '') }} id={ 'product_number_data' + index } data-productnumber={ item['product_number'] } href="#" target='_blank' rel="noreferrer" >{ item['product_number'].slice(0, 7) }{ item['product_number'].length > 7 ? '...' : '' }</span>
                            {
                              item['product_number'].length > 7 ?
                                <ReactTooltip
                                  anchorSelect={ '#product_number_data' + index }
                                  place='right'
                                  variant='dark'
                                  float='false'
                                  content={ item['product_number'] }
                                /> : null
                            }
                          </td>
                          <td className="td-customize">{ item['produce_number'] }</td>
                          <td className="td-customize">{ item['produce_start_datetime'] }</td>
                          <td className="td-customize">{ item['produce_end_datetime'] }</td>
                          <td className="td-customize">
                            {
                              item['product_kw_value_info'][item['product_kw_value_info'].length-1]['final_formula'] === undefined ?
                              (
                                Number(Number(item['product_kw_value_info'][item['product_kw_value_info'].length-1]['total_kw_value']) * Number(maxElectricCO2e['electric_co2e']) / 1000) +
                                Number(Number(item['erp_total_carbon_value']) * 1) +
                                Number(Number(item['another_total_carbon_value']) * 1)
                              ).toFixed(4) : ((
                                Number(item['product_kw_value_info'][item['product_kw_value_info'].length-1]['final_formula'].split('=')[1].replace(' (TCO2e)', '')) +
                                Number((Number(Number(item['erp_total_carbon_value']) * 1) / 1000).toFixed(4)) +
                                Number(Number(item['another_total_carbon_value']) * 1)
                              ) / Number(Number(item['product_weight']).toFixed(4))).toFixed(4)
                            }
                          </td>
                          <td className="td-customize">
                            { item['carbon_cost_price'] || '0' }
                          </td>
                          <td className="td-customize">
                          <Button
                            disabled={ disableCostSettingFormButton }
                            id={ item['product_number'] + index + 'button' }
                            className="btn-setting"
                            onClick={ event => handleCarbonCostSetting(event) }
                            data-productcarbondatatableindex={ index }
                            data-productnumber={ item['product_number'] }
                          >
                          設定
                          </Button>
                          </td>
                        </tr>
                        <>
                          {
                            (expandedRowOpen && expandedRows.includes(item['product_number'])) ? (
                              <tr>
                                <td colSpan={12}>
                                  <div
                                    style={{
                                      padding: '10px',
                                    }}
                                  >
                                    <span>
                                    <h4>數量設定{' '}
                                      <Button
                                        disabled={ disableCostSettingFormButton }
                                        onClick={ handleAddCarbonCostSetting }
                                        size="sm"
                                        className="btn-setting-add">新增
                                      </Button>{' '}
                                      <Button
                                        disabled={ disableCostSettingFormButton }
                                        data-totaltco2e={
                                          item['product_kw_value_info'][item['product_kw_value_info'].length-1]['final_formula'] === undefined ?
                                          (
                                            Number(Number(item['product_kw_value_info'][item['product_kw_value_info'].length-1]['total_kw_value']) * Number(maxElectricCO2e['electric_co2e']) / 1000) +
                                            Number(Number(item['erp_total_carbon_value']) * 1) +
                                            Number(Number(item['another_total_carbon_value']) * 1)
                                          ).toFixed(4) : ((
                                            Number(item['product_kw_value_info'][item['product_kw_value_info'].length-1]['final_formula'].split('=')[1].replace(' (TCO2e)', '')) +
                                            Number((Number(Number(item['erp_total_carbon_value']) * 1) / 1000).toFixed(4)) +
                                            Number(Number(item['another_total_carbon_value']) * 1)
                                          ) / Number(Number(item['product_weight']).toFixed(4))).toFixed(4)
                                        }
                                        data-productcarbondatatableindex={ index }
                                        data-producenumber={ item['produce_number'] }
                                        onClick={ handleCarbonCostCalculating }
                                        size="sm" className="btn-setting"
                                      >確定</Button>{' '}
                                      <span className="text-danger">{ settingMessage }</span>
                                      <span className="c-blue2">{ successSettingMessage }</span>
                                      <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem', display: calculatingSpinner}} children={'false'} />
                                    </h4>
                                    </span>
                                      {
                                        carbonCostSettingForm.map((item, itemIndex) => {
                                          return <Form inline key={ 'setting-form-' + itemIndex }>
                                            <FormGroup className="mb-4 mr-sm-4 mb-sm-0">
                                              <Label className="mr-sm-2 label-text" for="export-country">請選擇出口國家</Label>
                                              <Select
                                                styles={{
                                                  container: base => ({
                                                    ...base,
                                                    flex: 1,
                                                    width: '150px',
                                                  })
                                                }}
                                                onChange={event => handleExportedCountryChange(event, itemIndex)}
                                                closeMenuOnSelect={true}
                                                value={ { label: item['exportedCountryName'], value: item['exportedCountryIndex'] } }
                                                options={exportedCountry}
                                                placeholder="請選擇出口國家名稱..."
                                                noOptionsMessage={() => '沒有出口國家可以選擇！'}
                                              />
                                            </FormGroup>
                                            <FormGroup className="mb-4 mr-sm-4 mb-sm-0">
                                              <Label className="mr-sm-2 label-text" for="export-country-number">請輸入預期出口產品數量</Label>
                                              <Input onChange={ event => handleInputProducedNumber(event, itemIndex) } value={ item['inputProducedNumber'] } invalid={ item['invalid'] } type="text" id="export-country-number"/>
                                              <FormFeedback>{ item['invalidMessage'] }</FormFeedback>
                                            </FormGroup>
                                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                              <Button
                                                disabled={ disableCostSettingFormButton }
                                                size="sm"
                                                data-itemindex={ itemIndex }
                                                onClick={ event => handleDeleteCostSetting(event) }
                                                className="btn-delete">移除
                                              </Button>
                                            </FormGroup>
                                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                              <Button
                                                disabled={ disableCostSettingFormButton || (carbonCostSettingForm[itemIndex]['FreeQuotaArr'] === undefined) }
                                                size="sm"
                                                data-itemindex={ itemIndex }
                                                onClick={ event => handlePresentTrending(event) }
                                                className="btn-setting-add">顯示趨勢
                                              </Button>
                                            </FormGroup>
                                            <Modal keyboard={ false } backdrop={ "static" } size="xl" key="presentCostTrendModal" isOpen={presentCostTrendModal} toggle={handlePresentTrending}>
                                              <ModalHeader className="modal-title" toggle={handlePresentTrending}>逐年趨勢圖</ModalHeader>
                                              <ModalBody>
                                                <Form>
                                                  <FormGroup>
                                                    <div style={{ height: '40vh', position: 'relative', marginBottom: '1%', padding: '1%'}}>
                                                      <Line data={ costTrendingChartData } options={
                                                        {
                                                          maintainAspectRatio: false,
                                                          plugins: {},
                                                          tooltips: {
                                                            callbacks: {
                                                              label: (tooltipItem) => {
                                                                return String(tooltipItem.value)
                                                              },
                                                            },
                                                          },
                                                          scales: {
                                                            yAxes: [{
                                                              scaleLabel: {
                                                                display: true,
                                                                labelString: '新台幣',
                                                              },
                                                            }],
                                                          }
                                                        }
                                                      } />
                                                    </div>
                                                  </FormGroup>
                                                  <FormGroup>
                                                    <Table>
                                                    {
                                                      costTrendingTable.th !== undefined ? <>
                                                        <thead className="text-info">
                                                          <tr>
                                                            {
                                                              costTrendingTable.th.map((item, index) => {
                                                                return <React.Fragment key={ "year-cost" + index}>
                                                                  <th className="th-customize">{ item }</th>
                                                                </React.Fragment>
                                                              })
                                                            }
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                            {
                                                              costTrendingTable.td.map((item, index) => {
                                                                return <td className="td-customize" key={ 'costNT' + index }>{ item }</td>
                                                              })
                                                            }
                                                            </tr>
                                                        </tbody>
                                                      </> : <></>
                                                    }
                                                    </Table>
                                                  </FormGroup>
                                                </Form>
                                              </ModalBody>
                                              <ModalFooter className="modal-footer-center">
                                                <Button className="btn-cancel" onClick={ handlePresentTrending }>確定</Button>
                                              </ModalFooter>
                                            </Modal>
                                          </Form>
                                        })
                                      }
                                  </div>
                                </td>
                              </tr>
                            ) : null
                          }
                        </>
                      </React.Fragment>
                      })
                    }
                  </tbody></> : <></>
                  }
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CostCalculator;
