import React, { useEffect, useState } from "react";
import {
  Redirect,
} from 'react-router';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Spinner,
  Label,
} from "reactstrap";
import { DatePicker } from "reactstrap-date-picker";
import TimePicker from "react-time-picker/dist/TimePicker";
import differenceInDays from 'date-fns/differenceInDays';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { format, formatISO } from "date-fns";
import { verifiedLoginWithSetState } from 'ajax/LoginRequest';
import { getEmsTagList, testEmsDeviceDataByTagName } from "ajax/TestDeviceData";


function TestDeviceData() {
  const [deviceTagList, setDeviceTagList] = useState([]);
  const [selectedTagName, setSelectedTagName] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [testResultMessage, setTestResultMessage] = useState('');

  const [startMeasuredDate, setStartMeasuredDate] = useState({
    value: (formatISO(new Date()).split('+')[0] + 'Z'),
    formattedValue: format(new Date(), 'yyyy/MM/dd'),
  });
  const [endMeasuredDate, setEndMeasuredDate] = useState({
    value: (formatISO(new Date()).split('+')[0] + 'Z'),
    formattedValue: format(new Date(), 'yyyy/MM/dd'),
  });

  const [startMeasuredTime, setStartMeasuredTime] = useState('00:00');
  const [endMeasuredTime, setEndMeasuredTime] = useState('00:00');

  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const [loadingDataSpinner, setLoadingDataSpinner] = useState('none');

  const animatedComponents = makeAnimated();

  const handleTagNameChange = (selected) => {
    setSelectedTagName(selected);
  };

  const handleStartMeasuredDateChange = (value, formattedValue) => {
    setStartMeasuredDate({
      value: value,
      formattedValue: formattedValue,
    });
  };
  const handleStartMeasuredTimeChange = (selected) => {
    setStartMeasuredTime(selected);
  };

  const handleEndMeasuredDateChange = (value, formattedValue) => {
    setEndMeasuredDate({
      value: value,
      formattedValue: formattedValue,
    });
  };
  const handleEndMeasuredTimeChange = (selected) => {
    setEndMeasuredTime(selected);
  };

  const handleTestDeviceData = async() => {
    setErrorMessage('');
    setTestResultMessage('');

    let startDates = startMeasuredDate.formattedValue.split('/');
    let startTime = startMeasuredTime.split(':');
    let startDateArray = [startDates[0], startDates[1], startDates[2], startTime[0], startTime[1]];

    let endDates = endMeasuredDate.formattedValue.split('/');
    let endTime = endMeasuredTime.split(':');
    let endDateArray = [endDates[0], endDates[1], endDates[2], endTime[0], endTime[1]];

    let diffDays = getDiffDaysByDates(startDateArray, endDateArray);
    if (diffDays > 0) {
      setErrorMessage('開始測量日期不可以比結束測量日期大！');

      return false;
    }
    if (Math.abs(diffDays) > 1) {
      setErrorMessage('測量日期範圍不可以超過1天！');

      return false;
    }

    let startMeasuredDateTime = startDateArray.join('') + startMeasuredTime.replace(':', '');
    let endMeasuredDateTime = endDateArray.join('') + endMeasuredTime.replace(':', '');

    let requestPayload = {
      tag_name: selectedTagName.value,
      start_measured_date_time: startMeasuredDateTime,
      end_measured_date_time: endMeasuredDateTime,
    };

    setLoadingDataSpinner('inline-block');
    let deviceData = await testEmsDeviceDataByTagName(requestPayload, setErrorMessage);
    setLoadingDataSpinner('none');

    if (deviceData !== false) {
      setTestResultMessage('該設定測量時間範圍有：' + String(deviceData.length) + '筆資料');
    }
  };

  const getDiffDaysByDates = (startDates, endDates) => {
    let diffDays = differenceInDays(
      new Date(Number(startDates[0]), Number(startDates[1])-1, Number(startDates[2], Number(startDates[3]), Number(startDates[4]))),
      new Date(Number(endDates[0]), Number(endDates[1])-1, Number(endDates[2], Number(endDates[3]), Number(endDates[4]))),
    );

    return diffDays;
  };

  useEffect(() => {
    setLoadingDataSpinner('inline-block');

    async function verifiedLogin() {
      await verifiedLoginWithSetState(setRedirectToLogin);
    }
    verifiedLogin();

    async function genDeviceTagNameList() {
      await getEmsTagList(setDeviceTagList, setErrorMessage, setSelectedTagName);

      setLoadingDataSpinner('none');
    }

    genDeviceTagNameList();
  }, []);

  if (redirectToLogin) {
    return <Redirect to='/admin/login'/>
  }

  return (
    <>
      <div className="content">
        {
          loadingDataSpinner === 'inline-block' ?
          <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem', display: 'inline-block'}} children={'false'} /> :
          <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  感測器裝置測試{' '}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <FormGroup>
                    <Label className="modal-from-label" for="select-device-number">請選擇感測器裝置編號</Label>
                    <Select
                      onChange={selected => handleTagNameChange(selected)}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      value={selectedTagName}
                      options={deviceTagList}
                      placeholder="請選擇能源管理系統TagName..."
                      noOptionsMessage={() => '沒有TagName可以選擇！'}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="modal-from-label" for="start-measured-date">請選擇開始測量日期</Label>
                    <DatePicker name="start-measured-date" onChange={(value, formattedValue) => handleStartMeasuredDateChange(value, formattedValue)} dateFormat="YYYY/MM/DD" value={startMeasuredDate.value || ''} placeholder="請選擇開始測量日期" />
                  </FormGroup>
                  <FormGroup>
                    <Label className="modal-form-label" for="start-measured-time">請選擇開始測量時間</Label>{'  '}
                    <TimePicker name="start-measured-time" onChange={event => handleStartMeasuredTimeChange(event)} value={startMeasuredTime || ''} placeholder="請選擇開始測量時間" />
                  </FormGroup>
                  <FormGroup>
                    <Label className="modal-from-label" for="end-measured-date">請選擇結束日期</Label>
                    <DatePicker name="end-measured-date" onChange={(value, formattedValue) => handleEndMeasuredDateChange(value, formattedValue)} dateFormat="YYYY/MM/DD" value={endMeasuredDate.value || ''} placeholder="請選擇結束日期" />
                  </FormGroup>
                  <FormGroup>
                    <Label className="modal-form-label" for="end-measured-time">請選擇結束測量時間</Label>{'  '}
                    <TimePicker name="end-measured-time" onChange={event => handleEndMeasuredTimeChange(event)} value={endMeasuredTime || ''} placeholder="請選擇結束測量時間" />
                  </FormGroup>
                  <FormGroup>
                    <Button
                      disabled={ errorMessage.includes('忙碌中') }
                      className="btn-setting"
                      onClick={ handleTestDeviceData }>送出
                    </Button>
                    <h5 className="text-danger">{ errorMessage }</h5>
                    <h5 className="text-info">{ testResultMessage }</h5>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
          </Row>
        }
      </div>
    </>
  );
}

export default TestDeviceData;
