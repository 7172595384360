import React, { useEffect, useRef, useState } from "react";
import {
  Redirect,
} from 'react-router';
import { Line } from 'react-chartjs-2';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Spinner,
  Label,
  Input,
} from "reactstrap";
import { DatePicker } from "reactstrap-date-picker";
import differenceInDays from 'date-fns/differenceInDays';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { format, formatISO } from "date-fns";
import { verifiedLoginWithSetState } from 'ajax/LoginRequest';
import { getEmsTagListFromSensorDatabase, getDeviceCarbon } from "ajax/RithaiDeviceCarbon";
import { getElectricCO2e } from "ajax/CarbonFactor";
import { CSVLink } from "react-csv";


function DeviceCarbon() {
  const [deviceTagList, setDeviceTagList] = useState([]);
  const [selectedTagName, setSelectedTagName] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [resultMessage, setResultMessage] = useState('');
  const [electricCarbon, setElectricCarbon] = useState(0);

  const [electricCarbonList, setElectricCarbonList] = useState([]);
  const [selectedElectricCarbon, setSelectedElectricCarbon] = useState({});
  const [deviceCarbonChartData, setDeviceCarbonChartData] = useState({});
  const [showChart, setShowChart] = useState(true);
  const [exportedCsvFileName, setExportedCsvFileName] = useState('');
  const [deviceCarbonData, setDeviceCarbonData] = useState([]);

  const csvLink = useRef();

  const [statusCounter, setStatusCounter] = useState(0);

  const [startMeasuredDate, setStartMeasuredDate] = useState({
    value: (formatISO(new Date()).split('+')[0] + 'Z'),
    formattedValue: format(new Date(), 'yyyy/MM/dd'),
  });
  const [endMeasuredDate, setEndMeasuredDate] = useState({
    value: (formatISO(new Date()).split('+')[0] + 'Z'),
    formattedValue: format(new Date(), 'yyyy/MM/dd'),
  });

  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const [loadingDataSpinner, setLoadingDataSpinner] = useState('none');

  const animatedComponents = makeAnimated();

  const handleTagNameChange = (selected) => {
    setSelectedTagName(selected);
  };

  const handleElectricCarbonChange = (selected) => {
    setSelectedElectricCarbon(selected);
    setElectricCarbon(selected.value);
  };

  const handleStartMeasuredDateChange = (value, formattedValue) => {
    setStartMeasuredDate({
      value: value,
      formattedValue: formattedValue,
    });
  };

  const handleEndMeasuredDateChange = (value, formattedValue) => {
    setEndMeasuredDate({
      value: value,
      formattedValue: formattedValue,
    });
  };

  const handleDeviceCarbon = async() => {
    setErrorMessage('');
    setResultMessage('');

    let startDates = startMeasuredDate.formattedValue.split('/');
    let startDateArray = [startDates[0], startDates[1], startDates[2], 0, 0];

    let endDates = endMeasuredDate.formattedValue.split('/');
    let endDateArray = [endDates[0], endDates[1], endDates[2], 0, 0];

    let diffDays = getDiffDaysByDates(startDateArray, endDateArray);
    if (diffDays > 0) {
      setErrorMessage('開始測量日期不可以比結束測量日期大！');

      return false;
    }

    if (isNaN(electricCarbon) || electricCarbon <= 0) {
      setErrorMessage('輸入的電力碳排係數不正確！');

      return false;
    }

    setLoadingDataSpinner('inline-block');

    console.log(startDateArray);
    console.log(endDateArray);

    let startMeasuredDateTime = startDateArray.slice(0, startDateArray.length-2).join('-');
    let endMeasuredDateTime = endDateArray.slice(0, endDateArray.length-2).join('-');

    let requestPayload = {
      tag_name: selectedTagName.value,
      started_measured_date: startMeasuredDateTime,
      end_measured_date: endMeasuredDateTime,
    };

    await getDeviceCarbon(setResultMessage, setDeviceCarbonChartData, requestPayload, electricCarbon, setShowChart, setExportedCsvFileName, setDeviceCarbonData);

    setLoadingDataSpinner('none');
    setStatusCounter(statusCounter+1);
  };

  const handleExportCsvFile = () => {
    csvLink.current.link.click();
  };

  const getDiffDaysByDates = (startDates, endDates) => {
    let diffDays = differenceInDays(
      new Date(Number(startDates[0]), Number(startDates[1])-1, Number(startDates[2], Number(startDates[3]), Number(startDates[4]))),
      new Date(Number(endDates[0]), Number(endDates[1])-1, Number(endDates[2], Number(endDates[3]), Number(endDates[4]))),
    );

    return diffDays;
  };

  useEffect(() => {
    if (statusCounter > 0) {
    } else {
      setLoadingDataSpinner('inline-block');

      async function verifiedLogin() {
        await verifiedLoginWithSetState(setRedirectToLogin);
      }
      verifiedLogin();

      async function genDeviceTagNameList() {
        await getEmsTagListFromSensorDatabase(setDeviceTagList, setErrorMessage, setSelectedTagName);
        let electricCo2eList = await getElectricCO2e();
        let electricCo2eSelectList = [];
        let theLabel = '';
        let theValue = 0;
        for (let index=0; index<electricCo2eList.length; index++) {
          theValue = electricCo2eList[index]['electric_co2e'];
          theLabel = theValue + ' kgCO2e(民國' + electricCo2eList[index]['year'] + '年)';
          electricCo2eSelectList.push({
            label: theLabel,
            value: theValue,
          });
        }

        setElectricCarbonList(electricCo2eSelectList);
        setLoadingDataSpinner('none');
      }

      genDeviceTagNameList();
    }
  }, [statusCounter]);

  if (redirectToLogin) {
    return <Redirect to='/admin/login'/>
  }

  return (
    <>
      <div className="content">
        {
          loadingDataSpinner === 'inline-block' ?
          <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem', display: 'inline-block'}} children={'false'} /> :
          <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  感測器裝置碳排計算{' '}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <FormGroup>
                  <Label className="modal-from-label" for="select-device-number">請選擇感測器裝置編號</Label>
                    <Select
                      onChange={selected => handleTagNameChange(selected)}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      value={selectedTagName}
                      options={deviceTagList}
                      placeholder="請選擇能源管理系統TagName..."
                      noOptionsMessage={() => '沒有TagName可以選擇！'}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="modal-from-label" for="start-measured-date">電力碳排係數參考</Label>
                    <Select
                      onChange={selected => handleElectricCarbonChange(selected)}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      value={selectedElectricCarbon}
                      options={electricCarbonList}
                      placeholder=""
                      noOptionsMessage={() => ''}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="modal-from-label" for="start-measured-date">請選擇開始測量日期</Label>
                    <DatePicker name="start-measured-date" onChange={(value, formattedValue) => handleStartMeasuredDateChange(value, formattedValue)} dateFormat="YYYY/MM/DD" value={startMeasuredDate.value || ''} placeholder="請選擇開始測量日期" />
                  </FormGroup>
                  <FormGroup>
                    <Label className="modal-from-label" for="end-measured-date">請選擇結束日期</Label>
                    <DatePicker name="end-measured-date" onChange={(value, formattedValue) => handleEndMeasuredDateChange(value, formattedValue)} dateFormat="YYYY/MM/DD" value={endMeasuredDate.value || ''} placeholder="請選擇結束日期" />
                  </FormGroup>
                  <FormGroup>
                    <Label className="modal-from-label" for="end-measured-date">請輸入電力碳排係數（單位：KgCO2e）</Label>
                    <Input name="electric-carbon" value={ electricCarbon || '' } onChange={ (e) => setElectricCarbon(e.target.value) } type="text"></Input>
                  </FormGroup>
                  <FormGroup>
                    <Button
                      className="btn-setting"
                      onClick={ handleDeviceCarbon }>送出
                    </Button>{' '}
                    <Button
                      hidden={ showChart }
                      className="btn-setting-add"
                      onClick={ handleExportCsvFile }>匯出CSV檔
                    </Button>
                    <CSVLink
                      data={ deviceCarbonData }
                      filename={ exportedCsvFileName }
                      className='hidden'
                      ref={ csvLink }
                      target='_blank'
                    />
                    <h5 className="text-danger">{ errorMessage }</h5>
                    <h5 className="text-info">{ resultMessage }</h5>
                  </FormGroup>
                  <FormGroup hidden={ showChart }>
                    <Line data={ deviceCarbonChartData } options={
                        {
                          maintainAspectRatio: false,
                          plugins: {},
                          tooltips: {
                            callbacks: {
                              label: (tooltipItem) => {
                                return String(tooltipItem.value)
                              },
                            },
                          },
                          scales: {
                            yAxes: [{
                              type: 'logarithmic',
                              scaleLabel: {
                                display: true,
                                labelString: '碳排放量(TCO2e)',
                              },
                            }],
                          }
                        }
                    } />
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
          </Row>
        }
      </div>
    </>
  );
}

export default DeviceCarbon;
