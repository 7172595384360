import Axios from 'axios';


var dataSourceApiPath = '/data_source';

export async function getDataSource(setDataSourceTables) {
    let requestConfig = {
        'headers': {
            'X-Token': localStorage.getItem('auth_session'),
        },
    };
    await Axios.get(process.env.REACT_APP_API_ADDRESS + dataSourceApiPath, requestConfig).then((response) => {
        let responseData = [];
        for (let index=0; index<response.data.length; index++) {
            responseData.push({
                'key': response.data[index][0],
                'sourceName': response.data[index][1],
                'sourceType': response.data[index][2],
                'script': response.data[index][3],
                'scriptVersion': response.data[index][4],
                'sourceCode': response.data[index][5],
            });
        }
        setDataSourceTables(responseData);
    }).catch((error) => {
        let errResponseJsonString = JSON.stringify(error.response, null, 2);
        console.log(errResponseJsonString);
    });
};

const DataSource = {
    getDataSource,
};

export default DataSource;
