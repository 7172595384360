import React, { useEffect, useState, useRef } from "react";
import {
  Redirect,
} from 'react-router';
import { Bar } from 'react-chartjs-2';
import "hw-chartjs-plugin-colorschemes";
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Fade,
  Row,
  Col,
  Table,
  Button,
  Spinner,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  FormGroup,
} from "reactstrap";
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import { formatISO9075 } from 'date-fns';
import makeAnimated from 'react-select/animated';
import { getProduct } from "ajax/Product";
import { getElectricCO2e } from 'ajax/CarbonFactor';
import { verifiedLoginWithSetState } from 'ajax/LoginRequest';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { getBatchNumberLists, getProductCarbonCalculateData, exportProcessCarbonFile } from "ajax/ProductCarbon";


function ProcessCarbonDashboard() {
  const animatedComponents = makeAnimated();
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [productTables, setProductTables] = useState([]);
  const [disableProductTables, setDisableProductTables] = useState(false);
  const [productName, setProductName] = useState('');
  const [loadingCalculatingSpinner, setLoadingCalculatingSpinner] = useState('none');
  const [exportingFileSpinner, setExportingFileSpinner] = useState('none');
  const [disableExportFileButton, setDisableExportFileButton] = useState(false);
  const [electricCo2e, setElectricCo2e] = useState([]);
  const [maxElectricCO2e, setMaxElectricCO2e] = useState({});
  const [productCarbonDataTable, setProductCarbonDataTable] = useState([]);
  const [productCarbonDataTablePagination, setProductCarbonDataTablePagination] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandedRowOpen, setExpandedRowOpen] = useState(false);
  const [carbonDataPage, setCarbonDataPage] = useState(1);
  const [carbonDetailedTree, setCarbonDetailedTree] = useState([]);
  const [collapsedChild, setCollapsedChild] = useState({'0': false, '1': false, '2': false});
  const [collapsedAllChild, setCollapsedAllChild] = useState(false);

  const [chartData, setChartData] = useState({});
  const [originalChartData, setOriginalChartData] = useState('');
  const [batchNumberLists, setBatchNumberLists] = useState([]);
  const [selectedBatchNumberLists, setSelectedBatchNumberLists] = useState([]);

  const [isFinalFormula, setIsFinalFormula] = useState(false);
  const [statusNumber, setStatusNumber] = useState(0);

  const [processCarbonData, setProcessCarbonData] = useState([]);
  const csvLink = useRef();
  const chartDataRef = useRef();

  const handleBatchNumberListsChange = (selectedValue) => {
    if (selectedValue.length === 0) {
      return false;
    }

    setSelectedBatchNumberLists(selectedValue);

    let resultChartData = JSON.parse(originalChartData);
    let defaultChartDataset = [];
    let dataValueArr = [];
    let labelNoZero = new Array(resultChartData.labels.length).fill(0);

    for (let index=0; index<resultChartData.datasets.length; index++) {
      for (let batchIndex=0; batchIndex<selectedValue.length; batchIndex++) {
        if (resultChartData.datasets[index]['label'] === selectedValue[batchIndex].label) {
          defaultChartDataset.push(resultChartData.datasets[index]);
        }
      }
    }

    let labels = resultChartData.labels;
    let newLabel = [];

    for (let index=0; index<defaultChartDataset.length; index++) {
      dataValueArr = defaultChartDataset[index]['data'];
      for (let dataValIndex=0; dataValIndex<dataValueArr.length; dataValIndex++) {
        if (dataValueArr[dataValIndex] !== null) {
          labelNoZero[dataValIndex] += 1;
        }
      }
    }

    for (let index=0; index<labelNoZero.length; index++) {
      if (labelNoZero[index] === 0) {
        continue;
      }
      newLabel.push(labels[index]);
    }

    let newData = [];
    for (let index=0; index<defaultChartDataset.length; index++) {
      newData = defaultChartDataset[index].data;
      for (let labelIndex=0; labelIndex<labelNoZero.length; labelIndex++) {
        if (labelNoZero[labelIndex] === 0) {
          newData[labelIndex] = undefined;
        }
      }

      defaultChartDataset[index].data = newData.filter((value) => { return value !== undefined; });
    }

    resultChartData.labels = newLabel;
    resultChartData.datasets = defaultChartDataset;

    setChartData(resultChartData);
    chartDataRef.current.chartInstance.data = resultChartData;
  };

  const handleProductListsChange = async (selectedValue) => {
    setProductName(selectedValue);

    setLoadingCalculatingSpinner('inline-block');
    setDisableProductTables(true);

    let resultChartData = await getProductCarbonCalculateData(selectedValue, setProductCarbonDataTable, setProductCarbonDataTablePagination, setOriginalChartData, maxElectricCO2e);

    let requestPayload = {
      'product_lists': [selectedValue],
    };

    let theBatchNumberLists = await getBatchNumberLists(requestPayload, setBatchNumberLists);
    let firstBatchNumber = theBatchNumberLists.slice(0, 1);
    let defaultChartDataset = [];
    let dataValueArr = [];
    let labelNoZero = new Array(resultChartData.labels.length).fill(0);
    for (let index=0; index<resultChartData.datasets.length; index++) {
      dataValueArr = resultChartData.datasets[index]['data'];
      if (resultChartData.datasets[index]['label'] === firstBatchNumber[0]) {
        resultChartData.datasets[index]['data'] = dataValueArr.filter((value) => { return value !== null; });
        defaultChartDataset.push(resultChartData.datasets[index]);
      }
    }

    for (let index=0; index<defaultChartDataset.length; index++) {
      dataValueArr = defaultChartDataset[index]['data'];
      for (let dataValIndex=0; dataValIndex<dataValueArr.length; dataValIndex++) {
        if (dataValueArr[dataValIndex] !== null) {
          labelNoZero[dataValIndex] += 1;
        }
      }
    }

    let labels = resultChartData.labels;
    let newLabel = [];
    for (let index=0; index<labelNoZero.length; index++) {
      if (labelNoZero[index] === 0) {
        labels[index] = null;
      }
    }

    for (let index=0; index<labelNoZero.length; index++) {
      if (labelNoZero[index] === 0) {
        continue;
      }
      newLabel.push(labels[index]);
    }

    let newData = [];
    for (let index=0; index<defaultChartDataset.length; index++) {
      newData = defaultChartDataset[index].data;
      for (let labelIndex=0; labelIndex<labelNoZero.length; labelIndex++) {
        if (labelNoZero[labelIndex] === 0) {
          newData[labelIndex] = undefined;
        }
      }

      defaultChartDataset[index].data = newData.filter((value) => { return value !== undefined; });
    }

    resultChartData.labels = newLabel;
    resultChartData.datasets = defaultChartDataset;

    setChartData(resultChartData);

    setSelectedBatchNumberLists([{
      label: firstBatchNumber[0],
      value: firstBatchNumber[0],
    }]);

    setLoadingCalculatingSpinner('none');
    setDisableProductTables(false);

    setExpandedRowOpen(!expandedRowOpen);
    setExpandedRows([]);
  };

  const handleCollapseChild = (event) => {
    let modifiedCollapsedChild = JSON.parse(event.target.dataset.collapsedchild);
    for (let index=0; index<Object.keys(modifiedCollapsedChild).length; index++) {
      if (String(event.target.id) === String(index)) {
        modifiedCollapsedChild[String(index)] = !modifiedCollapsedChild[String(index)];
      }
    }

    setCollapsedChild(modifiedCollapsedChild);
  };

  const handleCollapseAllChild = (event) => {
    let collapsedAllChild = event.target.dataset.collapsedallchild;
    if (collapsedAllChild === 'false') {
      collapsedAllChild = false;
    } else {
      collapsedAllChild = true;
    }

    let modifiedCollapsedChild = JSON.parse(event.target.dataset.collapsedchild);
    for (let index=0; index<Object.keys(modifiedCollapsedChild).length; index++) {
      modifiedCollapsedChild[String(index)] = !collapsedAllChild;
    }

    setCollapsedChild(modifiedCollapsedChild);
    setCollapsedAllChild(!collapsedAllChild);
  };

  const handleExportProcessFile = async (event) => {
    setExportingFileSpinner('inline-block');
    setDisableExportFileButton(true);

    let productName = event.target.dataset.productname;
    let expectedProductMesFilePath = event.target.dataset.expectedproductmesfile;

    if (process.env.REACT_APP_TEST_MES_FILE_PATH) {
      expectedProductMesFilePath = process.env.REACT_APP_TEST_MES_FILE_PATH;
    }

    let requestPayload = {
      'product_name': productName,
      'expected_product_mes_file_path': expectedProductMesFilePath,
    }

    let csvContents = await exportProcessCarbonFile(requestPayload);

    setProcessCarbonData(csvContents);
    csvLink.current.link.click();

    setExportingFileSpinner('none');
    setDisableExportFileButton(false);
  };

  const handleDetailedCarbonValue = (event) => {
    let carbonDetailedTree = [
      {
        text: '產品製程碳排放量',
        nodes: [],
      },
      {
        text: '原料碳排放量(ERP)',
        nodes: [],
      },
      {
        text: '其他碳排放量',
        nodes: [],
      },
    ];

    let productNumber = event.target.dataset.productnumber;

    let productKiloWattValueInfo = JSON.parse(event.target.dataset.productkwvalueinfo);
    let finalFormulaInfo = productKiloWattValueInfo[productKiloWattValueInfo.length-1];
    productKiloWattValueInfo = productKiloWattValueInfo.slice(0, -1);

    let produceNumber = event.target.dataset.producenumber;
    let maxElectricCO2e = JSON.parse(event.target.dataset.maxelectricco2e);
    let erpProductCarbonValues = JSON.parse(event.target.dataset.erpproductcarbonvalues);
    let anotherCarbonValues = JSON.parse(event.target.dataset.anothercarbonvalues);
    let precision = 7;
    let finalPrecision = 4;

    let mesKiloWattValue = 0.0;
    let sensorIsAbnormal = false;
    let mesFormulaStr = 'mes_kilowatt_value (kw/h) * (electric_co2e (year年度電力碳排係數(kgCO2e)) / 1000) = carbon_result (tCO2e)';
    let carbonResult = 0.0;
    let processType = '';

    for (let index=0; index<productKiloWattValueInfo.length; index++) {
      mesKiloWattValue = productKiloWattValueInfo[index]['kwatt_value'];
      sensorIsAbnormal = false;
      mesFormulaStr = 'mes_kilowatt_value (kw/h) * (electric_co2e (year年度電力碳排係數(kgCO2e)) / 1000) = carbon_result (tCO2e)';

      processType = productKiloWattValueInfo[index]['process_type'];
      carbonResult = Number(mesKiloWattValue) * maxElectricCO2e['electric_co2e'] / 1000;
      carbonResult = carbonResult.toFixed(precision);

      mesFormulaStr = mesFormulaStr.replace('mes_kilowatt_value', mesKiloWattValue);
      mesFormulaStr = mesFormulaStr.replace('electric_co2e', maxElectricCO2e['electric_co2e']);
      mesFormulaStr = mesFormulaStr.replace('year', maxElectricCO2e['year']);
      mesFormulaStr = mesFormulaStr.replace('carbon_result', carbonResult);

      if (produceNumber > 0 && mesKiloWattValue <= 0) {
        sensorIsAbnormal = true;
      }

      if (productKiloWattValueInfo[index]['expected_formula']) {
        mesFormulaStr = productKiloWattValueInfo[index]['expected_formula'];
        let sumOfDeviceCarbon = 0;
        for (let formulaIndex=0; formulaIndex<mesFormulaStr.length; formulaIndex++) {
          sumOfDeviceCarbon += Number(mesFormulaStr[formulaIndex].split('=')[1].replace(' (kgCO2e)', ''));
        }
        processType += '：' + String(sumOfDeviceCarbon.toFixed(finalPrecision)) + ' (kgCO2e)';
      }

      carbonDetailedTree[0].nodes.push({
        text: processType,
        formula: mesFormulaStr,
        sensorIsAbnormal: sensorIsAbnormal,
      });
    }

    if (finalFormulaInfo['final_formula']) {
      carbonDetailedTree[0].nodes.push({
        text: '總計',
        formula: finalFormulaInfo['final_formula'],
        sensorIsAbnormal: false,
      });
      setIsFinalFormula(true);
    }

    let erpFormulaStr = 'erp_carbon_value (用料零件數) * carbon_factor (零件碳排係數) (係數來源：source_factor) * produce_number (生產數量) = carbon_result (kgCO2e)';
    let erpAmount = 0;
    let carbonFactor = 0;
    let erpName = '';
    let sourceFactor = '';
    let erpCarbonResults = '';
    let erpCarbonResultSum = 0;

    for (let index=0; index<erpProductCarbonValues.length; index++) {
      erpFormulaStr = 'erp_carbon_value (用料零件數) * carbon_factor (零件碳排係數) (係數來源：source_factor) = carbon_result (kgCO2e)';
      erpAmount = erpProductCarbonValues[index]['erp_amount'];
      carbonFactor = erpProductCarbonValues[index]['erp_co2e'];
      sourceFactor = erpProductCarbonValues[index]['source_factor'];
      carbonResult = Number(erpAmount) * Number(carbonFactor) * 1;
      carbonResult = carbonResult.toFixed(precision);
      erpName = erpProductCarbonValues[index]['erp_name'];

      erpFormulaStr = erpFormulaStr.replace('erp_carbon_value', erpAmount);
      erpFormulaStr = erpFormulaStr.replace('carbon_factor', carbonFactor);
      erpFormulaStr = erpFormulaStr.replace('produce_number', produceNumber);
      erpFormulaStr = erpFormulaStr.replace('carbon_result', carbonResult);
      erpFormulaStr = erpFormulaStr.replace('source_factor', sourceFactor);

      carbonDetailedTree[1].nodes.push({
        text: erpName,
        formula: erpFormulaStr,
      });

      erpCarbonResults += String(carbonResult) + ' + ';
      erpCarbonResultSum += Number(carbonResult);
    }

    erpCarbonResults = '(' + erpCarbonResults.slice(0, erpCarbonResults.length-2) + ') / 1000';
    erpCarbonResults += '= ' + String((erpCarbonResultSum / 1000).toFixed(finalPrecision));

    carbonDetailedTree[1].nodes.push({
      text: '總計',
      formula: erpCarbonResults + ' (TCO2e)',
    });

    let anotherFormulaStr = 'per_carbon_value (每單元碳排量) * produce_number (生產數量) = carbon_result (per_carbon_value_unit)';
    let perCarbonValue = 0;
    let perCarbonValueUnit = '';
    let nonProcessType = '';
    let categorySource = ''
    let sourceFactorArray = [];

    for (let index=0; index<anotherCarbonValues.length; index++) {
      anotherFormulaStr = 'per_carbon_value (每單元碳排量) * produce_number (生產數量) = carbon_result (per_carbon_value_unit)';
      perCarbonValue = anotherCarbonValues[index]['co2e_per_number'];
      perCarbonValueUnit = anotherCarbonValues[index]['co2e_per_number_unit'];
      categorySource = anotherCarbonValues[index]['category_source'];
      nonProcessType = anotherCarbonValues[index]['non_process_type'];
      sourceFactor = anotherCarbonValues[index]['source_factor'];
      sourceFactorArray = sourceFactor.split(';');

      if (nonProcessType === '總量') {
        produceNumber = 1;
        anotherFormulaStr = 'per_carbon_value (碳排量) = carbon_result (per_carbon_value_unit)';
      }

      if (sourceFactorArray.length === 4) {
        anotherFormulaStr = sourceFactorArray;
      } else {
        carbonResult = Number(perCarbonValue) * Number(produceNumber);
        carbonResult = carbonResult.toFixed(precision);

        anotherFormulaStr = anotherFormulaStr.replace('per_carbon_value_unit', perCarbonValueUnit);
        anotherFormulaStr = anotherFormulaStr.replace('per_carbon_value', perCarbonValue);
        anotherFormulaStr = anotherFormulaStr.replace('produce_number', produceNumber);
        anotherFormulaStr = anotherFormulaStr.replace('carbon_result', carbonResult);
      }

      carbonDetailedTree[2].nodes.push({
        text: categorySource,
        formula: anotherFormulaStr,
      });
    }

    setCarbonDetailedTree(carbonDetailedTree);

    setCollapsedChild({'0': false, '1': false, '2': false});

    if (expandedRows.length === 0) {
      setExpandedRows([productNumber]);
      setExpandedRowOpen(!expandedRowOpen);
      return false;
    }

    if (expandedRows[0] === productNumber) {
      setExpandedRows([]);
      setExpandedRowOpen(!expandedRowOpen);
      return false;
    }

    setExpandedRowOpen(!expandedRowOpen);
    setExpandedRows([productNumber]);
    setExpandedRowOpen(!!expandedRowOpen);
  };

  const handleCalculateCarbonDataTablePagination = async (event) => {
    let text = event.target.dataset.value;
    let currentPage = carbonDataPage;

    if (text === '...') {
      text = 11;
    }
    if (text === 'Previous') {
      if ((currentPage - 1) === 0) {
        return false;
      }
      text = Number(currentPage) - 1;
    }
    if (text === 'Next') {
      if ((currentPage + 1) > productCarbonDataTablePagination.length) {
        return false;
      }
      text = Number(currentPage) + 1;
    }

    setCarbonDataPage(Number(text));

    setLoadingCalculatingSpinner('inline-block');
    setDisableProductTables(true);

    let resultChartData = await getProductCarbonCalculateData(productName, setProductCarbonDataTable, setProductCarbonDataTablePagination, setOriginalChartData, maxElectricCO2e, text);

    let requestPayload = {
      'product_lists': [productName],
    };
    let theBatchNumberLists = await getBatchNumberLists(requestPayload, setBatchNumberLists);
    let firstBatchNumber = theBatchNumberLists.slice(0, 1);

    let defaultChartDataset = [];
    let dataValueArr = [];
    let labelNoZero = new Array(resultChartData.labels.length).fill(0);
    for (let index=0; index<resultChartData.datasets.length; index++) {
      dataValueArr = resultChartData.datasets[index]['data'];
      if (resultChartData.datasets[index]['label'] === firstBatchNumber[0]) {
        resultChartData.datasets[index]['data'] = dataValueArr.filter((value) => { return value !== null; });
        defaultChartDataset.push(resultChartData.datasets[index]);
      }
    }

    for (let index=0; index<defaultChartDataset.length; index++) {
      dataValueArr = defaultChartDataset[index]['data'];
      for (let dataValIndex=0; dataValIndex<dataValueArr.length; dataValIndex++) {
        if (dataValueArr[dataValIndex] !== null) {
          labelNoZero[dataValIndex] += 1;
        }
      }
    }

    let labels = resultChartData.labels;
    let newLabel = [];
    for (let index=0; index<labelNoZero.length; index++) {
      if (labelNoZero[index] === 0) {
        labels[index] = null;
      }
    }

    for (let index=0; index<labelNoZero.length; index++) {
      if (labelNoZero[index] === 0) {
        continue;
      }
      newLabel.push(labels[index]);
    }

    let newData = [];
    for (let index=0; index<defaultChartDataset.length; index++) {
      newData = defaultChartDataset[index].data;
      for (let labelIndex=0; labelIndex<labelNoZero.length; labelIndex++) {
        if (labelNoZero[labelIndex] === 0) {
          newData[labelIndex] = undefined;
        }
      }

      defaultChartDataset[index].data = newData.filter((value) => { return value !== undefined; });
    }

    resultChartData.labels = newLabel;
    resultChartData.datasets = defaultChartDataset;

    setChartData(resultChartData);

    setSelectedBatchNumberLists([{
      label: firstBatchNumber[0],
      value: firstBatchNumber[0],
    }]);

    setLoadingCalculatingSpinner('none');
    setDisableProductTables(false);
  };

  useEffect(() => {
    if (statusNumber > 0) {
    } else {
      async function verifiedLogin() {
        await verifiedLoginWithSetState(setRedirectToLogin);
      }

      verifiedLogin();

      async function genProductLists() {
        let responseData = await getProduct(setProductTables);
        if (Array.isArray(responseData) && responseData.length > 0) {
          setLoadingCalculatingSpinner('inline-block');
          let electricCO2e = await getElectricCO2e(setElectricCo2e);
          let maxElectricCO2e = undefined;
          let maxElectricCO2eYear = undefined;
          for (let index=0; index<electricCO2e.length; index++) {
            if (maxElectricCO2e === undefined) {
              maxElectricCO2eYear = electricCO2e[index]['year'];
              maxElectricCO2e = electricCO2e[index]['electric_co2e'];
            }
            if (maxElectricCO2eYear < electricCO2e[index]['year']) {
              maxElectricCO2eYear = electricCO2e[index]['year'];
              maxElectricCO2e = electricCO2e[index]['electric_co2e'];
            }
          }
          setMaxElectricCO2e({'year': maxElectricCO2eYear, 'electric_co2e': maxElectricCO2e});
          setDisableProductTables(true);

          let resultChartData = await getProductCarbonCalculateData(responseData[0]['product_name'], setProductCarbonDataTable, setProductCarbonDataTablePagination, setOriginalChartData, maxElectricCO2e);

          let requestPayload = {
            'product_lists': [responseData[0]['product_name']],
          };

          let theBatchNumberLists = await getBatchNumberLists(requestPayload, setBatchNumberLists);
          let firstBatchNumber = theBatchNumberLists.slice(0, 1);
          let defaultChartDataset = [];
          let dataValueArr = [];
          let labelNoZero = new Array(resultChartData.labels.length).fill(0);
          for (let index=0; index<resultChartData.datasets.length; index++) {
            dataValueArr = resultChartData.datasets[index]['data'];
            if (resultChartData.datasets[index]['label'] === firstBatchNumber[0]) {
              resultChartData.datasets[index]['data'] = dataValueArr.filter((value) => { return value !== null; });
              defaultChartDataset.push(resultChartData.datasets[index]);
            }
          }

          for (let index=0; index<defaultChartDataset.length; index++) {
            dataValueArr = defaultChartDataset[index]['data'];
            for (let dataValIndex=0; dataValIndex<dataValueArr.length; dataValIndex++) {
              if (dataValueArr[dataValIndex] !== null) {
                labelNoZero[dataValIndex] += 1;
              }
            }
          }

          let labels = resultChartData.labels;
          let newLabel = [];
          for (let index=0; index<labelNoZero.length; index++) {
            if (labelNoZero[index] === 0) {
              labels[index] = null;
            }
          }

          for (let index=0; index<labelNoZero.length; index++) {
            if (labelNoZero[index] === 0) {
              continue;
            }
            newLabel.push(labels[index]);
          }

          let newData = [];
          for (let index=0; index<defaultChartDataset.length; index++) {
            newData = defaultChartDataset[index].data;
            for (let labelIndex=0; labelIndex<labelNoZero.length; labelIndex++) {
              if (labelNoZero[labelIndex] === 0) {
                newData[labelIndex] = undefined;
              }
            }

            defaultChartDataset[index].data = newData.filter((value) => { return value !== undefined; });
          }

          resultChartData.labels = newLabel;
          resultChartData.datasets = defaultChartDataset;

          setChartData(resultChartData);

          setSelectedBatchNumberLists([{
            label: firstBatchNumber[0],
            value: firstBatchNumber[0],
          }]);

          setLoadingCalculatingSpinner('none');
          setDisableProductTables(false);
        }
      }

      genProductLists();
    }
  }, [statusNumber]);

  if (redirectToLogin) {
    return <Redirect to='/admin/login'/>
  }

  return (
    <>
      <div className="content">
      <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                { productTables.length <= 0 ? <h4 className="text-danger">{ '查無產品，請先到『產品製程設定』進行設定！' }</h4> : <></> }
                <p>
                <Input style={{ display: (productTables.length > 0 ? '' : 'none') }} disabled={ disableProductTables } className="w-100" name="product-lists" onChange={event => handleProductListsChange(event.target.value)} type="select" value={ productName } placeholder="請選擇產品">
                  {
                    productTables.map((item, itemIndex) => {
                      if (productName === '' && itemIndex === 0) {
                        setProductName(item['product_name']);
                      }

                      return <option key={'product-name-' + itemIndex}>{ item['product_name'] }</option>
                    })
                  }
                </Input>
                </p>
                {
                  batchNumberLists.length > 0 ?
                  <FormGroup>
                  <Select
                    style={{ display: (productTables.length > 0 ? '' : 'none') }}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    value={selectedBatchNumberLists.length === 0 ? {label: batchNumberLists[0]['options'][0]['label'], value: batchNumberLists[0]['options'][0]['value']} : selectedBatchNumberLists}
                    onChange={(event) => handleBatchNumberListsChange(event)}
                    options={batchNumberLists}
                    placeholder="請選擇產品批號名稱..."
                    noOptionsMessage={() => '沒有產品批號選項可以選擇！'}
                  /></FormGroup> : <></>
                }
                <CardTitle tag="h4">
                  <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem', display: loadingCalculatingSpinner}} children={'false'} />
                </CardTitle>
              </CardHeader>
              <CardBody>
              <div style={{display: ((Object.keys(chartData).length > 0 && chartData.datasets.length > 0) ? '' : 'none'), height: '40vh', position: 'relative', marginBottom: '1%', padding: '1%'}}>
                <Bar ref={ chartDataRef } data={ chartData } options={
                  {
                    maintainAspectRatio: false,
                    plugins: {
                      colorschemes: {
                        scheme: 'tableau.Tableau20',
                      },
                    },
                    tooltips: {
                      callbacks: {
                        label: (tooltipItem) => {
                          return String(Number(tooltipItem.value).toFixed(4));
                        },
                      },
                    },
                    scales: {
                      yAxes: [{
                        type: 'logarithmic',
                        scaleLabel: {
                          display: true,
                          labelString: '碳排放量(TCO2e)',
                        },
                        suggestedMin: 0,
                      }],
                    },
                  }
                } />
              </div>
              </CardBody>
              <CardBody>
              <FormGroup>
              <Pagination style={{display: (loadingCalculatingSpinner === 'none' && productTables.length > 0) ? '' : 'none'}}>
                  <PaginationItem disabled={ carbonDataPage === 1 ? true : false }>
                    <PaginationLink
                      aria-label="Previous"
                      data-value="Previous"
                      onClick={ handleCalculateCarbonDataTablePagination }
                    >
                      <i data-value="Previous" className="fa fa-angle-left"></i>
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                    {
                      productCarbonDataTablePagination.map((item, index) => {
                        if (item === 11) {
                          return <PaginationItem active={ (carbonDataPage >= item && carbonDataPage < productCarbonDataTablePagination.length) ? true : false } key={item + index}>
                            <PaginationLink  data-value="..." aria-label="..." onClick={ handleCalculateCarbonDataTablePagination }>
                              { '...' }
                            </PaginationLink>
                            </PaginationItem>
                        }

                        if (item > 11 && index !== productCarbonDataTablePagination.length-1) {
                          return null
                        }

                        return <PaginationItem active={ carbonDataPage === item ? true : false } key={item + index}>
                            <PaginationLink data-value={ item } aria-label={ String(item) } onClick={ handleCalculateCarbonDataTablePagination }>
                              { item }
                            </PaginationLink>
                            </PaginationItem>
                        })
                      }
                  <PaginationItem disabled={ carbonDataPage >= (productCarbonDataTablePagination.length) ? true : false }>
                    <PaginationLink
                      aria-label="Next"
                      data-value="Next"
                      onClick={ handleCalculateCarbonDataTablePagination }
                    >
                      <i data-value="Next" className="fa fa-angle-right"></i>
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
                </FormGroup>
                <Table style={{display: loadingCalculatingSpinner === 'none' ? '' : 'none'}}>
                  {
                    productCarbonDataTable.length > 0 ? <><thead className="text-info">
                    <tr>
                      <th className="th-customize">產品製造批號</th>
                      <th className="th-customize">生產數量</th>
                      <th className="th-customize">產品數量(T)</th>
                      <th className="th-customize">生產開始時間</th>
                      <th className="th-customize">生產結束時間</th>
                      <th className="th-customize">該批總碳排量({'tCO2e'})</th>
                      <th className="th-customize">詳細資訊</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      productCarbonDataTable.map((item, index) => {
                        return <React.Fragment key={ item['product_number'] + index }>
                        <tr>
                          <td className="td-customize">{ item['product_number'] }</td>
                          <td className="td-customize">{ item['produce_number'] }</td>
                          <td className="td-customize">{ item['product_weight'] }</td>
                          <td className="td-customize">{ item['produce_start_datetime'] }</td>
                          <td className="td-customize">{ item['produce_end_datetime'] }</td>
                          <td className="td-customize">
                            {
                              item['product_kw_value_info'][item['product_kw_value_info'].length-1]['final_formula'] === undefined ?
                              (
                                (
                                  Number(Number(item['product_kw_value_info'][item['product_kw_value_info'].length-1]['total_kw_value']) * Number(maxElectricCO2e['electric_co2e']) / 1000) +
                                  Number(Number(item['erp_total_carbon_value']) * 1) +
                                  Number(Number(item['another_total_carbon_value']) * 1)
                                ) / Number(item['product_weight'])
                              ).toFixed(4) : (
                                (
                                  Number(item['product_kw_value_info'][item['product_kw_value_info'].length-1]['final_formula'].split('=')[1].replace(' (TCO2e)', '')) +
                                  Number((Number(Number(item['erp_total_carbon_value']) * 1) / 1000).toFixed(4)) +
                                  Number(Number(item['another_total_carbon_value']) * 1)
                                ) / Number(Number(item['product_weight']).toFixed(4))
                              ).toFixed(4)
                            }
                          </td>
                          <td className="td-customize">
                          <Button
                            id={ item['product_number'] + index + 'button' }
                            className="btn-setting"
                            onClick={ handleDetailedCarbonValue }
                            data-productname={ item['product_name'] }
                            data-producenumber={ item['produce_number'] }
                            data-productnumber={ item['product_number'] }
                            data-productkwvalueinfo={ JSON.stringify(item['product_kw_value_info']) }
                            data-maxelectricco2e={ JSON.stringify(maxElectricCO2e) }
                            data-anothercarbonvalues={ JSON.stringify(item['another_carbon_values']) }
                            data-erpproductcarbonvalues={ JSON.stringify(item['erp_product_carbon_values']) }
                          >
                          查看
                          </Button>
                          </td>
                        </tr>
                        <>
                          {
                            (expandedRowOpen && expandedRows.includes(item['product_number'])) ? (
                              <tr>
                                <td colSpan={12}>
                                  <div
                                    style={{
                                      padding: '10px',
                                    }}
                                  >
                                    <span>
                                    <h4>詳細資訊{' '}
                                      <small>
                                        <Button
                                          className="btn-setting"
                                          size="sm"
                                          onClick={ handleCollapseAllChild }
                                          data-collapsedallchild= { collapsedAllChild }
                                          data-collapsedchild={ JSON.stringify(collapsedChild) }
                                        >
                                          全部展開
                                        </Button>{' '}
                                        {
                                          (item['product_kw_value_info'][item['product_kw_value_info'].length-1]['expected_product_mes_file']) ?
                                          <><Button
                                            className="btn-setting"
                                            size="sm"
                                            disabled={ disableExportFileButton }
                                            onClick={ handleExportProcessFile }
                                            data-productname={ item['product_name'] }
                                            data-expectedproductmesfile={ item['product_kw_value_info'][item['product_kw_value_info'].length-1]['expected_product_mes_file'] }
                                          >
                                            <FontAwesomeIcon size="sm" icon={ faDownload } />{' '}
                                            匯出碳排資訊
                                          </Button>
                                            <CSVLink
                                              data={ processCarbonData }
                                              filename={ 'process_carbon_data_' + formatISO9075(new Date()).replace(/(-|:| )/g, '') + '.csv' }
                                              className='hidden'
                                              ref={ csvLink }
                                              target='_blank'
                                            />
                                          </> : null
                                        }{' '}
                                        <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem', display: exportingFileSpinner}} children={'false'} />
                                      </small>
                                    </h4>
                                    <h5 className="c-blue2">
                                      計算結果為四捨五入到小數點第4位
                                    </h5>
                                    </span>
                                    <ListGroup
                                      flush
                                      style={{
                                        padding: '10px',
                                      }}
                                    >
                                      {
                                        carbonDetailedTree.map((item, index) => {
                                          return <React.Fragment key={ 'detailed-tree-' + index }>
                                            {

                                              <ListGroupItem style={{ zIndex: 0 }} className="text-secondary">
                                                <ListGroupItemHeading>
                                                  <Button
                                                    className={ "nc-icon " + ((collapsedChild[String(index)]) ? 'nc-simple-delete' : 'nc-simple-add')}
                                                    color="link"
                                                    id={ index }
                                                    data-collapsedchild={ JSON.stringify(collapsedChild) }
                                                    onClick={ handleCollapseChild }
                                                  >
                                                  </Button>
                                                  { item.text + ' ' }
                                                  <Badge pill>{ (isFinalFormula && item.nodes.length > 0) ? (item.nodes.length - 1) : item.nodes.length }</Badge>
                                                </ListGroupItemHeading>
                                                <ListGroup>
                                                {
                                                  (collapsedChild[String(index)] === true) ?
                                                    <Fade in={ true }>
                                                        {
                                                          item.nodes.map((childItem, childItemIndex) => {
                                                            return <ListGroupItem key={ 'child-node-' + childItemIndex} color={ childItem.text !== '總計' ? ((childItem.sensorIsAbnormal && index === 0) ? 'danger' : 'info') : 'success' }>
                                                                <ListGroupItemHeading>{ childItem.text + ((childItem.sensorIsAbnormal && index === 0) ? '(該產品無做此製程)' : '') }</ListGroupItemHeading>
                                                                {
                                                                  Array.isArray(childItem.formula) ? childItem.formula.map((formulaItem, formulaIndex) => {
                                                                    return <ListGroupItemText>{ formulaItem }</ListGroupItemText>
                                                                  }) : <ListGroupItemText>{ childItem.formula }</ListGroupItemText>
                                                                }
                                                              </ListGroupItem>
                                                          })
                                                        }
                                                    </Fade> : null
                                                }
                                                </ListGroup>
                                              </ListGroupItem>
                                            }
                                          </React.Fragment>
                                        })
                                      }
                                    </ListGroup>
                                  </div>
                                </td>
                              </tr>
                            ) : null
                          }
                        </>
                      </React.Fragment>
                      })
                    }
                  </tbody></> : <></>
                  }
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ProcessCarbonDashboard;
