import React, { useEffect, useState } from "react";
import {
  Redirect,
} from 'react-router';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Spinner,
} from "reactstrap";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { verifiedLoginWithSetState } from 'ajax/LoginRequest';
import { getDataSource } from "ajax/DataSource";


function DataSourceDashboard() {
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [dataSourceTables, setDataSourceTables] = useState([]);
  const [sourceCodeModal, setSourceCodeModal] = useState(false);
  const [codeString, setCodeString] = useState('');
  const [codename, setPythonFileName] = useState('');
  const [loadingDataSpinner, setLoadingDataSpinner] = useState('none');

  const sourceCodeToggle = (e) => {
    setSourceCodeModal(!sourceCodeModal);
    setCodeString(e.target.dataset.code);
    setPythonFileName(e.target.dataset.codename);
  };

  useEffect(() => {
    setLoadingDataSpinner('inline-block');

    async function verifiedLogin() {
      await verifiedLoginWithSetState(setRedirectToLogin);
    }
    verifiedLogin();

    async function genDataSourceTable() {
      await getDataSource(setDataSourceTables);

      setLoadingDataSpinner('none');
    }

    genDataSourceTable();
  }, []);

  if (redirectToLogin) {
    return <Redirect to='/admin/login'/>
  }

  return (
    <>
      <div className="content">
        {
          loadingDataSpinner === 'inline-block' ?
          <Spinner className="spinner-color" style={{ width: '2rem', height: '2rem', display: 'inline-block'}} children={'false'} /> :
          <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">資料來源管理</CardTitle>
              </CardHeader>
              <CardBody>
                <Table>
                  <thead className="text-info">
                    <tr>
                      <th className="th-customize">資料源名稱</th>
                      <th className="th-customize">類型</th>
                      <th className="th-customize">引用腳本名稱</th>
                      <th className="th-customize">腳本版本</th>
                      <th className="th-customize">查看程式碼</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      dataSourceTables.map((item) => {
                        return <tr key={ Number(item['key']) }>
                          <td className="td-customize">{ item['sourceName'] }</td>
                          <td className="td-customize">{ item['sourceType'] }</td>
                          <td className="td-customize">{ item['script'] }</td>
                          <td className="td-customize">{ item['scriptVersion'] }</td>
                          <td className="td-customize">
                          <Button
                            id={ item['scriptVersion'] }
                            className="btn-setting"
                            onClick={ sourceCodeToggle }
                            data-id={ item['scriptedVersion'] }
                            data-code={ item['sourceCode'] }
                            data-codename={ item['script'] }
                          >
                          查看
                          </Button>
                      </td>
                        </tr>
                      })
                    }
                  </tbody>
                  <Modal key="sourceCodeModal" isOpen={sourceCodeModal} toggle={sourceCodeToggle} className="">
                    <ModalHeader toggle={sourceCodeToggle}>{codename}<span className="text-primary">{ '' }</span></ModalHeader>
                      <ModalBody>
                        <Form>
                          <FormGroup>
                          <SyntaxHighlighter language="python" style={dark} showLineNumbers={true}>
                            {codeString}
                          </SyntaxHighlighter>
                          </FormGroup>
                        </Form>
                      </ModalBody>
                      <ModalFooter></ModalFooter>
                  </Modal>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
       }
      </div>
    </>
  );
}

export default DataSourceDashboard;
